import { FileAttachmentDetail } from '@ideascale/commons/dist/models/attachment/FileAttachmentDetail';
import {ClassificationAttachmentAttribute} from '@ideascale/commons/dist/models/classification/ClassificationAttachmentAttribute';

export class FileAttachmentsHolder {
    static readonly EMPTY = new FileAttachmentsHolder([], false);

    constructor(
        public attachments: FileAttachmentDetail[],
        public fileAttachmentAllowed: boolean,
        public attributes?: ClassificationAttachmentAttribute[]
    ) {
    }
}