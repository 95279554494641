import {PageTheme} from '@ideascale/commons/dist/models/enums/PageTheme';
import {Row} from 'models/landing-page/Row';
import {LandingPagePermissions} from 'models/landing-page/LandingPagePermissions';
import {LandingPageVersion} from 'models/types/LandingPageVersion';

export class LandingPageData {
    public static EMPTY = new LandingPageData(0, '', -1, PageTheme.CURIE, [], LandingPageVersion.EMPTY, LandingPageVersion.EMPTY, LandingPagePermissions.EMPTY, 0, false);

    constructor(
        public id: number,
        public name: string,
        public languageId: number,
        public theme: PageTheme,
        public rows: Row[],
        public currentVersion: LandingPageVersion,
        public publishedVersion: LandingPageVersion,
        public permissions: LandingPagePermissions,
        public translatedLanguageId: number,
        public contentModified: boolean,
    ) {
    }
}
