import React from 'react';
import {Button, Icon} from '@ideascale/ui';
import {redirectPostUrl} from '@ideascale/commons/dist/utils/CommonUtil';

type DropdownMenuItemProps = {
    item: {
        name: string;
        url: string;
        icon?: string;
        sudo: boolean;
        externalLink: boolean;
        methodType?: string;
    },
    topBarSvgIcons: string;
    onMenuItemClick?: () => void;
    highlightIcon?: boolean;
    elementId?: string;
}

export const DropdownMenuItem = (props: DropdownMenuItemProps) => {
    const {item, topBarSvgIcons, onMenuItemClick, highlightIcon = false, elementId = ''} = props;

    return (
        (
            item.methodType && item.methodType === 'POST')
            ? (
                <Button color="default" className={`fw-normal dropdown-item ${item.sudo ? 'highlighted' : ''}`}
                        data-test-element-id={elementId}
                        key={item.name.replace(/\s/g, '-')} onClick={() => redirectPostUrl(item.url)}>
                    {item.icon ? <Icon name={item.icon} iconSpritePath={topBarSvgIcons} width={20}
                                       className={highlightIcon ? 'active' : ''}
                                       height={20}/> : null} {item.name}
                </Button>
            )
            : (
                <a className={`fw-normal text-default dropdown-item ${item.sudo ? 'highlighted' : ''}`}
                   href={item.url}
                        data-test-element-id={elementId}
                        key={item.name.replace(/\s/g, '-')}
                        target={item.externalLink ? item.name : '_self'}
                        onClick={onMenuItemClick}>
                    {
                        item.icon
                            ?
                            <Icon name={item.icon} iconSpritePath={topBarSvgIcons} className={highlightIcon ? 'active' : ''}
                                  width={20}
                                  height={20}/>
                            :
                            null
                    }
                    {item.name}
                </a>
            )
    );
};
