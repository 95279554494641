import React from 'react';
import range from 'lodash/range';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaSkeleton} from '../IdeaSkeleton';

type IdeaListSkeletonProps = {
    currentViewMode: IdeaListViewMode;
    items?: number;
}

export const IdeaListSkeleton = (props: IdeaListSkeletonProps) => {
    const {currentViewMode, items = currentViewMode === IdeaListViewMode.LIST_VIEW ? 3 : 10} = props;

    return (
        <div className={`ideas ${currentViewMode.replace('_', '-').toLowerCase()} loading clearfix`}>
            {
                range(0, items).map(index => {
                    if (currentViewMode === IdeaListViewMode.GRID_VIEW && index % 3 === 0) {
                        return <IdeaSkeleton className="ms-0" key={`${index}-skeleton`} viewMode={currentViewMode}/>;
                    } else {
                        return <IdeaSkeleton key={`${index}-skeleton`} viewMode={currentViewMode}/>;
                    }
                })
            }
        </div>
    );
};