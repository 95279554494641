import React, {useCallback, useEffect, useState} from 'react';
import {flushSync} from 'react-dom';
import {AppThemeUtil, CommonUtil, StorageUtil, Theme} from '@ideascale/ui';
import ideascaleLogo from '@ideascale/ui/dist/assets/ideascale-logo-blue.svg';
import ideascaleLogoWhite from '@ideascale/ui/dist/assets/ideascale-logo-white.svg';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {TopbarClassification} from '@ideascale/commons/dist/models/classification/TopbarClassification';
import {ClassificationTopBar} from '@ideascale/commons/dist/components/classification-topbar/ClassificationTopbar';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {CampaignsHolder} from '@ideascale/commons/dist/models/CampaignsHolder';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {MessageData} from '@ideascale/commons/dist/models/MessageData';
import svgIcons from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useAppContext} from 'contexts/AppContext';
import {useAppService, useCommunityService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {useMessageService} from 'hooks/useMessageService';
import {useClassificationService} from 'hooks/useClassificationService';
import {useDigestService} from 'hooks/useDigestService';
import {TopBar} from 'components/topbar/TopBar';
import {TopBarData} from 'models/TopBarData';
import {PageParameters} from 'models/types/PageParameters';
import {CustomPageData} from 'models/CustomPageData';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {NotificationResponse} from 'models/NotificationResponse';
import {PagedResponseContent} from 'models/PagedResponseContent';
import './TopBarContainer.scss';

const CURRENT_MENU_PATH = /\/c\b/gi;

export const TopBarContainer = () => {
    const {
        authentication,
        setLocalizationMode,
        darkMode,
        editModeEnabled,
        communityConfig: {classificationEnabled},
    } = useAppContext();
    const appService = useAppService();
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [topBarData, setTopBarData] = useState<TopBarData>(TopBarData.EMPTY);
    const [topbarClassification, setTopbarClassificationData] = useState(TopbarClassification.EMPTY);
    const {
        fetchMonthlyModeratorDigest,
        fetchWeeklyModeratorDigest,
        fetchCampaignPerformanceWeeklyModeratorDigest,
        fetchCampaignPerformanceMonthlyModeratorDigest
    } = useDigestService();
    const {fetchRecipients} = useMessageService();
    const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
    const {fetchClassifications} = useClassificationService();

    const fetchTopBarCampaigns = async (pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> => {
        if (communityService !== null) {
            return await communityService.fetchTopBarCampaigns(pageParameters);
        }
        return PagedResponseContent.nullObject<CampaignsHolder<Campaign>>();
    };

    const fetchTopBarCustomPages = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<CustomPageData>> => {
        if (communityService !== null) {
            const data = await communityService.fetchTopBarCustomPages(pageParameters);
            setTopBarData(prev => ({...prev, unvisitedPageCount: 0}));
            return data;
        }
        return PagedResponseContent.nullObject<CustomPageData>();
    }, [communityService]);

    const fetchTopBarLabels = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>> => {
        if (communityService !== null) {
            return await communityService.fetchTopBarLabels(pageParameters);
        }
        return PagedResponseContent.nullObject<LabelData>();
    }, [communityService]);

    const fetchTopbarNotifications = useCallback(async (groupId: number): Promise<NotificationResponse> => {
        if (communityService !== null) {
            const topbarNotifications = await communityService.fetchTopbarNotifications(groupId);
            setTopBarData(prevData => ({...prevData, unseenNotificationCount: 0}));
            return topbarNotifications;
        }
        return NotificationResponse.EMPTY;
    }, [communityService]);

    const fetchTopBarMessages = useCallback(async (): Promise<MessageData> => {
        if (communityService !== null) {
            return await communityService.fetchTopBarMessages();
        }
        return MessageData.EMPTY;
    }, [communityService]);

    const fetchTopbarActionItems = useCallback(async (): Promise<GroupedActionItem[]> => {
        if (communityService !== null) {
            return await communityService.fetchTopbarActionItems();
        }
        return [];
    }, [communityService]);

    const sudoAdmin = useCallback(async () => {
        if (communityService !== null) {
            try {
                await communityService.sudoAdmin();
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    }, [communityService, handleErrorResponse]);

    const deSudoAdmin = useCallback(async () => {
        if (communityService !== null) {
            try {
                await communityService.deSudoAdmin();
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    }, [communityService, handleErrorResponse]);

    const updateThemePreference = useCallback(async (theme: Theme) => {
        if (communityService !== null) {
            try {
                await communityService.changeThemePreference(theme);
                StorageUtil.setItem(AppThemeUtil.APP_THEME_KEY, theme);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
    }, [communityService, handleErrorResponse]);

    useEffect(() => {
        if (appService != null && !authentication.isCommunityTosAcceptanceRequired()) {
            CommonUtil.wait().then(() => {
                flushSync(() => setIsFetchingData(true));
                appService.fetchTopBarData()
                    .then(topBarData => {
                        setTopBarData(topBarData);
                        setLocalizationMode(topBarData.debugLocalizerMode);
                        setIsFetchingData(false);
                    })
                    .catch(() => {
                        flushSync(() => setIsFetchingData(false));
                    });
            });
        }
    }, [appService, authentication, setLocalizationMode]);

    useEffect(() => {
        if (communityService !== null && classificationEnabled) {
            communityService.fetchTopbarClassification().then(response => setTopbarClassificationData(response));
        }
    }, [classificationEnabled, communityService]);

    useEffect(() => {
        if (appService != null && authentication.isCommunityTosAcceptanceRequired()) {
            CommonUtil.wait().then(() => {
                flushSync(() => setIsFetchingData(true));
                appService.fetchTosTopBarData()
                    .then((tosTopbarData => {
                        setTopBarData(prev => ({
                            ...prev,
                            communityLogoUrl: tosTopbarData.communityLogoUrl,
                            altText: tosTopbarData.communityLogoAltText
                        }));
                    }))
                    .catch(error => {
                        handleErrorResponse(error);
                    })
                    .finally(() => {
                        flushSync(() => setIsFetchingData(false));
                    });
            });
        }
    }, [appService, authentication, handleErrorResponse]);

    return (
        <div className="fixed-top">
            {
                classificationEnabled &&
                <ClassificationTopBar localizer={localizer} text={topbarClassification.classificationAsText}/>
            }
            {
                !editModeEnabled &&
                <TopBar
                    logo={topBarData.communityLogoUrl || (darkMode ? ideascaleLogoWhite : ideascaleLogo)}
                    topBarData={topBarData}
                    topBarSvgIcons={svgIcons}
                    currentMenuPath={CURRENT_MENU_PATH}
                    actor={authentication.actor}
                    localizer={localizer}
                    fetchTopBarCampaigns={fetchTopBarCampaigns}
                    fetchTopBarCustomPages={fetchTopBarCustomPages}
                    fetchTopBarLabels={fetchTopBarLabels}
                    fetchTopBarMessages={fetchTopBarMessages}
                    fetchTopbarActionItems={fetchTopbarActionItems}
                    fetchTopbarNotifications={fetchTopbarNotifications}
                    fetchRecipients={fetchRecipients}
                    sudoAdmin={sudoAdmin}
                    deSudoAdmin={deSudoAdmin}
                    fetchMonthlyModeratorDigest={fetchMonthlyModeratorDigest}
                    fetchWeeklyModeratorDigest={fetchWeeklyModeratorDigest}
                    fetchCampaignPerformanceMonthlyModeratorDigest={fetchCampaignPerformanceMonthlyModeratorDigest}
                    fetchCampaignPerformanceWeeklyModeratorDigest={fetchCampaignPerformanceWeeklyModeratorDigest}
                    updateThemePreference={updateThemePreference}
                    isFetchingData={isFetchingData}
                    fetchClassifications={fetchClassifications}
                />
            }
        </div>
    );
};
