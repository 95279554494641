import React, {Fragment, lazy, Suspense, useEffect} from 'react';
import {useIdeaSubmissionService} from 'hooks/useIdeaSubmissionService';
import {useAppContext} from 'contexts/AppContext';
import {CommonUtil} from 'utils/CommonUtil';
import {useToggleIdeaSubmissionModal} from 'hooks/useToggleIdeaSubmissionModal';
import {FeatureTourStep} from '@ideascale/commons/dist/models/enums/FeatureTourStep';
import {FEATURE_TOUR_EVENTS} from '@ideascale/commons/dist/shared/AppConstants';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {PageLoadingOverlay} from 'components/shared/PageLoadingOverlay';
import {FEATURE_TOUR_DELAY} from 'constants/AppConstants';

const IdeaFormModal = lazy(() => import('components/idea-submission/IdeaFormModal').then(module => ({default: module.IdeaFormModal})));

export const IdeaFormContainer = () => {
    const [ideaFormModalOpen, toggleByRouteParam, draftId] = useToggleIdeaSubmissionModal();
    const {communityConfig: {aiTextAssistEnabled}} = useAppContext();
    const methods = useIdeaSubmissionService();

    useEffect(() => {
        if (ideaFormModalOpen && aiTextAssistEnabled) {
            CommonUtil.wait(FEATURE_TOUR_DELAY + 100)
                .then(() => eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.SET_TOUR_STEP_INDEX, FeatureTourStep.IDEA_SUBMISSION_ASSIST_ASK_HELP));
        }
    }, [aiTextAssistEnabled, ideaFormModalOpen]);

    return (
        <Fragment>
            {
                ideaFormModalOpen &&
                <Suspense fallback={<PageLoadingOverlay/>}>
                    <IdeaFormModal
                        {...methods}
                        initialDraftIdeaId={draftId}
                        open={ideaFormModalOpen}
                        toggle={toggleByRouteParam}
                        aiAssistanceEnabled={aiTextAssistEnabled}
                        defaultMode={draftId ? 'EDIT_DRAFT' : 'CREATE_IDEA'}
                    />
                </Suspense>
            }
        </Fragment>
    );
};