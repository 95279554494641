import {useCallback} from 'react';
import {AiContentResponse} from '@ideascale/commons/dist/models/AiContentResponse';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {CampaignsHolder} from '@ideascale/commons/dist/models/CampaignsHolder';
import {MemberSearchRequest} from '@ideascale/commons/dist/models/MemberSearchRequest';
import {TagSearchParameters} from '@ideascale/commons/dist/models/types/TagSearchParameters';
import {AiAssistanceFieldNames} from '@ideascale/commons/dist/models/enums/AiAssistanceFieldNames';
import {useCommunityService} from './useService';
import {useLinkIdeas} from './useLinkIdeas';
import {useFileUploadService} from './useFileUploadService';
import {IdeaFormFields} from 'models/IdeaFormFields';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {IdeaDetail} from 'models/IdeaDetail';

export const useIdeaSubmissionService = () => {
    const communityService = useCommunityService();
    const {uploadFile, tempImageUpload} = useFileUploadService();

    const {fetchLinkableIdeas, fetchLinkableIdeasByKeywords} = useLinkIdeas();

    const fetchDrafts = useCallback(async () => {
        if (communityService !== null) {
            return await communityService.fetchDrafts();
        }
        return [];
    }, [communityService]);

    const deleteDraft = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return await communityService.deleteDraft(ideaId);
        }
    }, [communityService]);

    const fetchFieldsByCampaignId = useCallback(async (campaignId: number) => {
        if (communityService !== null) {
            return await communityService.fetchIdeaFormFieldsByCampaignId(campaignId);
        }
        return IdeaFormFields.EMPTY;
    }, [communityService]);

    const fetchFieldsByIdeaId = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return await communityService.fetchIdeaFormFieldsByIdeaId(ideaId);
        }
        return IdeaFormFields.EMPTY;
    }, [communityService]);

    const fetchCopyIdeaFormFieldsByIdeaId = useCallback(async (ideaId: number) => {
        if (communityService !== null) {
            return await communityService.fetchCopyIdeaFormFieldsByIdeaId(ideaId);
        }
        return IdeaFormFields.EMPTY;
    }, [communityService]);

    const fetchCampaigns = useCallback(async (params: PageParameters) => {
        if (communityService !== null) {
            return await communityService.fetchAllowedGroupedCampaigns(params);
        }
        return PagedResponseContent.nullObject<CampaignsHolder<Campaign>>();
    }, [communityService]);

    const fetchIdeaLabelsToAdd = useCallback(async (ideaId?: number) => {
        if (communityService !== null) {
            return await communityService.fetchIdeaFormLabels(ideaId);
        }
        return [];
    }, [communityService]);

    const fetchAtMentionMembers = useCallback(async (parameters: MemberSearchRequest) => {
        if (communityService !== null) {
            return await communityService.fetchAtMentionMembers(parameters);
        }
        return [];
    }, [communityService]);

    const fetchContributors = useCallback(async (parameters: MemberSearchRequest) => {
        if (communityService != null) {
            return await communityService.fetchContributors(parameters);
        }
        return new PagedResponseContent([], false, 0, 10, 0);
    }, [communityService]);

    const fetchOnBehalfMembers = useCallback(async (parameters: MemberSearchRequest) => {
        if (communityService != null) {
            return await communityService.fetchOnBehalfMembers(parameters);
        }
        return new PagedResponseContent([], false, 0, 10, 0);
    }, [communityService]);

    const detectLanguage = useCallback(async (text: string) => {
        if (communityService !== null) {
            return await communityService.detectLanguage(text);
        }
        return {id: 0, name: '', code: ''};
    }, [communityService]);

    const fetchAtMentionsIdeaCustomField = useCallback(async (parameters: MemberSearchRequest) => {
        if (communityService !== null) {
            return await communityService.fetchAtMentionsIdeaCustomField(parameters);
        }
        return [];
    }, [communityService]);

    const fetchAtMentionsRefineCustomField = useCallback(async (parameters: MemberSearchRequest) => {
        if (communityService !== null) {
            return await communityService.fetchAtMentionsRefineCustomField(parameters);
        }
        return [];
    }, [communityService]);

    const fetchInputTags = useCallback(async (pageParams: TagSearchParameters): Promise<PagedResponseContent<string>> => {
        if (communityService !== null) {
            return communityService.fetchTagsByTerm(pageParams);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchSuggestedTags = useCallback(async (ideaTitle: string, ideaDescription: string, currentTags: string[]): Promise<string[]> => {
        if (communityService !== null) {
            return communityService.fetchSuggestedTags({query: ideaTitle + ' ' + ideaDescription, tags: currentTags});
        }
        return [];
    }, [communityService]);

    const createIdea = useCallback(async (submissionRequest: IdeaSubmissionRequest) => {
        if (communityService !== null) {
            return communityService.createIdea(submissionRequest);
        }
        return IdeaDetail.EMPTY;
    }, [communityService]);

    const fetchAiAssistedDescription = useCallback(async (prompt: string, fieldName: AiAssistanceFieldNames, chatId?: number, campaignId?: number, chatContinue?: boolean) => {
        if (communityService !== null) {
            return communityService.fetchAiIdeaDescription(prompt, fieldName, chatId, campaignId, chatContinue);
        }
        return AiContentResponse.EMPTY;
    }, [communityService]);

    return {
        fetchDrafts,
        uploadFile,
        tempImageUpload,
        deleteDraft,
        fetchFieldsByCampaignId,
        fetchFieldsByIdeaId,
        fetchCopyIdeaFormFieldsByIdeaId,
        fetchCampaigns,
        fetchLinkableIdeas,
        fetchLinkableIdeasByKeywords,
        fetchIdeaLabelsToAdd,
        fetchContributors,
        fetchOnBehalfMembers,
        fetchAtMentionMembers,
        detectLanguage,
        fetchInputTags,
        fetchSuggestedTags,
        createIdea,
        fetchAiAssistedDescription,
        fetchAtMentionsIdeaCustomField,
        fetchAtMentionsRefineCustomField
    };
};