import {useCallback} from 'react';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {Actor} from '@ideascale/commons/dist/models/Actor';
import {useAppContext} from '../contexts/AppContext';
import {useToggleIdeaSubmissionModal} from './useToggleIdeaSubmissionModal';
import {SHOW_JOIN_COMMUNITY_PROMPT, SHOW_LOGIN_PROMPT} from 'constants/AppConstants';

export const useIdeaSubmissionActions = () => {
    const {authentication, currentCampaign, communityConfig: {submissionAllowed}} = useAppContext();
    const [ideaFormOpen, toggleUseFormModal] = useToggleIdeaSubmissionModal();

    const onSubmitIdeaButtonClick = useCallback(() => {
        if (authentication.isAuthenticated() && authentication.isJoinCommunityAvailable()) {
            eventDispatcher.dispatch(SHOW_JOIN_COMMUNITY_PROMPT);
        } else if (authentication.isAuthenticated()) {
            toggleUseFormModal();
        } else {
            eventDispatcher.dispatch(SHOW_LOGIN_PROMPT);
        }
    }, [authentication, toggleUseFormModal]);

    const isIdeaSubmissionAllowed = useCallback(() => {
        if (authentication.isCommunityTosAcceptanceRequired() ||
            authentication.actor.hasPendingMembership() ||
            (authentication.isAuthenticated() && !authentication.isJoinCommunityAvailable() && authentication.actor?.id === Actor.EMPTY.id)
        ) {
            return false;
        } else if (authentication.isJoinCommunityAvailable()) {
            return true;
        } else if (currentCampaign) {
            return currentCampaign.ideaSubmissionAllowed;
        } else {
            return submissionAllowed;
        }
    }, [authentication, currentCampaign, submissionAllowed]);

    const isNotIdeaSubmissionAllowed = () => !isIdeaSubmissionAllowed();

    return {
        ideaFormOpen,
        toggleUseFormModal,
        onSubmitIdeaButtonClick,
        isNotIdeaSubmissionAllowed,
        isIdeaSubmissionAllowed
    };
};