import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {FormSectionData} from '@ideascale/commons/dist/models/FormSectionData';
import {FormFieldConfig} from '@ideascale/commons/dist/models/classification/FormFieldConfig';
import {Language} from './types/Language';

export class IdeaFormFields {
    static readonly EMPTY = new IdeaFormFields(false, false, '', false, 0, []);

    labelApplicable: boolean;
    campaignTosNotAccepted: boolean;
    instruction: string;
    moveToRecycleBinAllowed: boolean;
    formSections: FormSectionData[];
    copiedIdeaAuthorId?: number;
    language?: Language;
    customLabelIds?: number[];
    campaign?: Campaign;
    additionalConfig?: FormFieldConfig;

    constructor(
        labelApplicable: boolean,
        campaignTosNotAccepted: boolean,
        instruction: string,
        moveToRecycleBinAllowed: boolean,
        copiedIdeaAuthorId: number,
        formSections: FormSectionData[],
        campaign?: Campaign,
        additionalConfig?: FormFieldConfig
    ) {
        this.labelApplicable = labelApplicable;
        this.campaignTosNotAccepted = campaignTosNotAccepted;
        this.instruction = instruction;
        this.moveToRecycleBinAllowed = moveToRecycleBinAllowed;
        this.copiedIdeaAuthorId = copiedIdeaAuthorId;
        this.formSections = formSections;
        this.campaign = campaign;
        this.additionalConfig = additionalConfig
    }
}
