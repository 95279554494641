import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Actor} from '@ideascale/commons/dist/models/Actor';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {FEATURE_TOUR_EVENTS} from '@ideascale/commons/dist/shared/AppConstants';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {useAppContext} from 'contexts/AppContext';
import {ROUTES} from 'shared/Routes';

export const useToggleIdeaSubmissionModal = () => {
    const query = useUrlQuery();
    const navigate = useNavigate();
    const {authentication} = useAppContext();
    const isOpen = query.get(ROUTES.QUERY_PARAMS.SUBMISSION_FORM) === 'open' && authentication.isAuthenticated() && authentication.actor.id !== Actor.EMPTY.id && !authentication.isJoinCommunityAvailable();
    const draftId = query.get(ROUTES.QUERY_PARAMS.DRAFT_IDEA) !== null ? Number(query.get(ROUTES.QUERY_PARAMS.DRAFT_IDEA)) : null;
    const submitterId = query.get(ROUTES.QUERY_PARAMS.SUBMITTER_ID) !== null ? Number(query.get(ROUTES.QUERY_PARAMS.SUBMITTER_ID)) : null;

    const toggle = useCallback((draftId?: number, submitterId?: number) => {
        if (isOpen) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.RESET_TOUR_STEP_INDEX);
            query.delete(ROUTES.QUERY_PARAMS.SUBMISSION_FORM);
            query.delete(ROUTES.QUERY_PARAMS.DRAFT_IDEA);
            query.delete(ROUTES.QUERY_PARAMS.SUBMITTER_ID);
            navigate({
                search: query.toString()
            });
        } else {
            query.append(ROUTES.QUERY_PARAMS.SUBMISSION_FORM, 'open');
            if (draftId) {
                query.append(ROUTES.QUERY_PARAMS.DRAFT_IDEA, String(draftId));
            }
            if (submitterId) {
                query.append(ROUTES.QUERY_PARAMS.SUBMITTER_ID, String(submitterId));
            }
            navigate({
                search: query.toString()
            });
        }
    }, [navigate, isOpen, query]);

    return [isOpen, toggle, draftId, submitterId] as [boolean, (draftId?: number, submitterId?: number) => void, number, number | null];
};