import {Member} from '@ideascale/commons/dist/models/Member';
import {LocalizationMode} from '@ideascale/commons/dist/shared/localization/LocalizationMode';

type CommunityMenu = {
    name: string;
    url: string;
    icon: string;
    sudo: boolean;
    externalLink: boolean;
}

type PrivilegeStateMenuItem = {
    name: string;
    url: string;
    icon: string;
    sudo: boolean;
    methodType: string;
    externalLink: boolean;
}

type ProfileMenuItem = {
    name: string;
    url: string;
    icon: string;
    sudo: boolean;
    externalLink: boolean;
}

type SudoMenuItem = {
    name: string;
    url: string;
    sudo: boolean;
    externalLink: boolean;
}

export class TopBarData {
    static readonly EMPTY = new TopBarData('', '', '', '', [], [], [], [], false, false, false, false, false, 0, 0, 0, '', Member.EMPTY, false, false, false, false, LocalizationMode.DEFAULT, '', '', false, true, false);

    constructor(
        public communityLogoUrl: string,
        public altText: string,
        public messagesUrl: string,
        public communityName: string,
        public communityMenuItems: CommunityMenu[],
        public privilegeStateMenuItems: PrivilegeStateMenuItem[],
        public profileMenuItems: ProfileMenuItem[],
        public sudoMenuItems: SudoMenuItem[],
        public joinCommunityAvailable: boolean,
        public actionItemsAccessible: boolean,
        public customPagesAccessible: boolean,
        public labelsAccessible: boolean,
        public notificationsAccessible: boolean,
        public unseenMessageCount: number,
        public unseenNotificationCount: number,
        public unvisitedPageCount: number,
        public node: string,
        public sudoer: Member,
        public campaignsAccessible: boolean,
        public searchAccessible: boolean,
        public newFeaturesAvailable: boolean,
        public debugLocalizerMenuVisible: boolean,
        public debugLocalizerMode: LocalizationMode,
        public classificationAsText: string,
        public topbarLogoUrl: string,
        public actionItemsAvailable: boolean,
        public whiteboardAccessible: boolean,
        public createCustomPageEnabled: boolean
    ) {
    }
}