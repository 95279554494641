import {Author} from '@ideascale/commons/dist/models/Author';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {ClassificationFieldAttribute} from '@ideascale/commons/dist/models/classification/ClassificationFieldAttribute';
import {FileAttachment} from '@ideascale/commons/dist/models/FileAttachment';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {Member} from '@ideascale/commons/dist/models/Member';
import {RenderFormat} from '@ideascale/commons/dist/models/enums/RenderFormat';

import {IdeaSummary} from './IdeaSummary';
import {QualifierLinkedIdeas} from './QualifierLinkedIdeas';
import {IdeateStageSummary} from './IdeateStageSummary';
import {BuildTeamStageSummary} from './BuildTeamStageSummary';
import {RefineStageSummary} from './RefineStageSummary';
import {EstimateStageSummary} from './EstimateStageSummary';
import {ReviewScaleStageSummary} from './ReviewScaleStageSummary';
import {FundStageSummary} from './FundStageSummary';
import {AssessmentStageSummary} from './AssessmentStageSummary';
import {ReserveStageSummary} from './ReserveStageSummary';
import {PendingStageSummary} from './PendingStageSummary';
import {BaseIdeaStageSummary} from './BaseIdeaStageSummary';
import {StageFunctionName} from './enums/StageFunctionName';
import {StageGroup} from './enums/StageGroup';
import {TranslationSummary} from './types/TranslationSummary';
import {IdeaFieldSection} from './IdeaFieldSection';

export class ModifiedIdeaDetail extends IdeaSummary {
    constructor(id: number,
                ideaNumber: number,
                title: string,
                description: string,
                summary: string,
                image: string,
                altText: string,
                campaign: Campaign,
                tags: string[],
                submitter: Member,
                coSubmitters: Member[],
                subscribed: boolean,
                moderate: boolean,
                commentCount: number,
                kudoCount: number,
                kudoGiven: boolean,
                labels: LabelData[],
                createdAt: string,
                publicIdea: boolean,
                ownerCount: number,
                followingIdeaAuthor: boolean,
                commentingEnabled: boolean,
                commentingAllowed: boolean,
                assignmentText: string,
                pinned: boolean,
                funnelId: number,
                labellingAllowed: boolean,
                followingEnabled: boolean,
                attachments: FileAttachment[],
                attributes: ClassificationFieldAttribute[],
                customAttachments: FileAttachment[],
                commentAttachments: FileAttachment[],
                translation: TranslationSummary,
                stageSummary: IdeateStageSummary | BuildTeamStageSummary | RefineStageSummary | EstimateStageSummary | ReviewScaleStageSummary | FundStageSummary | AssessmentStageSummary | ReserveStageSummary | PendingStageSummary | BaseIdeaStageSummary,
                renderFormat: RenderFormat,
                owners: Author[],
                fieldSections: IdeaFieldSection[],
                linkedIdeas: QualifierLinkedIdeas[],
                draft: boolean,
                memberActionAllowed: boolean,
                customDescriptionLabel: string,
                sharable: boolean) {
        super(id, ideaNumber, title, description, summary, image, altText, campaign, tags, submitter, coSubmitters, subscribed, moderate, commentCount, kudoCount, kudoGiven, labels, createdAt, publicIdea, ownerCount, followingIdeaAuthor, commentingEnabled, commentingAllowed, assignmentText, pinned, funnelId, labellingAllowed, followingEnabled, attachments, attributes, customAttachments, commentAttachments, stageSummary, renderFormat, translation, draft, memberActionAllowed, sharable);
        this.owners = owners;
        this.fieldSections = fieldSections;
        this.linkedIdeas = linkedIdeas;
    }

    static readonly EMPTY = new ModifiedIdeaDetail(0, 0, '', '', '', '', '', Campaign.EMPTY, [], Member.EMPTY, [], false, false, 0, 0, false, [], '', false, 0, false, false, false, '', false, 0, false, false, [], [], [],[],
        {translated: false, translationStatus: 0},
        new IdeateStageSummary({
            id: 0,
            key: '',
            name: StageFunctionName.IDEATE,
            label: '',
            functionName: StageFunctionName.IDEATE,
            stageGroup: StageGroup.ACTIVE,
            description: ''
        }, true, true, 0, '', 0, true, true, true, 0, 0, 0), 1, [], [], [], false, false, '', false);

    owners: Author[];
    fieldSections: IdeaFieldSection[];
    linkedIdeas: QualifierLinkedIdeas[];
}