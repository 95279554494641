import {Member} from '@ideascale/commons/dist/models/Member';

export class LandingPageVersion {
    public static EMPTY = new LandingPageVersion('', 0, '', '', '', Member.EMPTY, Member.EMPTY);

    constructor(
        public title: string,
        public version: number,
        public createdAt: string,
        public modifiedAt: string,
        public note?: string,
        public createdBy?: Member,
        public modifiedBy?: Member,
    ) {
    }

}