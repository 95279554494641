import React, {Fragment, lazy, Suspense, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {Alert, AppLoading} from '@ideascale/ui';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {useRoutesMatch} from '@ideascale/commons/dist/hooks/useRoutesMatch';
import {useAppContext} from 'contexts/AppContext';
import {IdeasContextProvider} from 'contexts/IdeasContext';
import {SearchContextProvider} from 'contexts/SearchContext';
import {SidebarContextProvider} from 'contexts/SidebarContext';
import {IdeaListContextProvider} from 'contexts/IdeaListContext';
import {LandingPageContextProvider} from 'contexts/LandingPageContext';
import {IdeaDetailsContextProvider} from 'contexts/IdeaDetailsContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useRouteUtils} from 'hooks/useRouteUtils';
import {useImagePreloader} from 'hooks/useImagePreloader';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {appLinks} from 'services/AppLinks';
import {IdeaListPage} from 'pages/IdeaListPage';
import {TopBarContainer} from 'containers/topbar/TopBarContainer';
import {GoogleAnalyticsContainer} from 'containers/GoogleAnalyticsContainer';
import {ScrollToTopButton} from 'components/ScrollToTopButton';
import {RedirectionAlertMessage} from 'components/RedirectionAlertMessage';
import {PageLoadingOverlay} from 'components/shared/PageLoadingOverlay';
import {IdeaFormContainer} from './IdeaFormContainer';
import {MembershipTosContainer} from './MembershipTosContainer';
import {ROUTES} from 'shared/Routes';

const MyIdeasListWithContextContainer = lazy(() => import('containers/MyIdeasListWithContextContainer').then(module => ({default: module.MyIdeasListWithContextContainer})));

const IdeaDetailsPage = lazy(() => import('pages/IdeaDetailsPage').then(module => ({default: module.IdeaDetailsPage})));
const TagsPage = lazy(() => import('pages/TagsPage').then(module => ({default: module.TagsPage})));
const ProfilePage = lazy(() => import('pages/ProfilePage').then(module => ({default: module.ProfilePage})));
const LandingPage = lazy(() => import('pages/LandingPage').then(module => ({default: module.LandingPage})));
const LeaderboardPage = lazy(() => import('pages/LeaderboardPage').then(module => ({default: module.LeaderboardPage})));
const CampaignTagsPage = lazy(() => import('pages/CampaignTagsPage').then(module => ({default: module.CampaignTagsPage})));
const InviteFriendsPage = lazy(() => import('pages/InviteFriendsPage').then(module => ({default: module.InviteFriendsPage})));
const SearchPage = lazy(() => import('pages/SearchPage').then(module => ({default: module.SearchPage})));
const DigestPage = lazy(() => import('pages/DigestPage').then(module => ({default: module.DigestPage})));
const CampaignPage = lazy(() => import('pages/CampaignPage').then(module => ({default: module.CampaignPage})));
const CampaignTosPage = lazy(() => import('pages/CampaignTosPage').then(module => ({default: module.CampaignTosPage})));
const NewFeaturesPage = lazy(() => import('pages/NewFeaturesPage').then(module => ({default: module.NewFeaturesPage})));
const MembershipTosViewPage = lazy(() => import('pages/MembershipTosViewPage').then(module => ({default: module.MembershipTosViewPage})));
const MembershipTosAcceptPage = lazy(() => import('pages/MembershipTosAcceptPage').then(module => ({default: module.MembershipTosAcceptPage})));
const PrivateCommunityJoinPage = lazy(() => import('components/join-community/PrivateCommunityJoinPage').then(module => ({default: module.PrivateCommunityJoinPage})));

const FeatureTourContainer = lazy(() => import('containers/FeatureTourContainer').then(module => ({default: module.FeatureTourContainer})));
const NotificationContainer = lazy(() => import('containers/NotificationContainer').then(module => ({default: module.NotificationContainer})));
const MessageContainer = lazy(() => import('containers/MessageContainer').then(module => ({default: module.MessageContainer})));
const ActivityContainer = lazy(() => import('containers/ActivityContainer').then(module => ({default: module.ActivityContainer})));
const JoinCommunityPrompt = lazy(() => import('components/join-community/JoinCommunityPrompt').then(module => ({default: module.JoinCommunityPrompt})));
const LoginPrompt = lazy(() => import('components/LoginPrompt').then(module => ({default: module.LoginPrompt})));
const ChatContainer = lazy(() => import('containers/ChatContainer').then(module => ({default: module.ChatContainer})));

export const AppContainer = () => {
    const {authentication, communityConfig} = useAppContext();
    const localizer = useLocalizer();
    const query = useUrlQuery();
    const currentProfileId = useProfilePageParams();
    const {profileRoutesMatch} = useRouteUtils();

    const navigate = useNavigate();
    const rootPath = useRoutesMatch('/');

    useEffect(() => {
        if (((rootPath?.pathname === '/') || window?.location?.pathname?.includes(ROUTES.LANDING)) &&
            (authentication.community.isNotPublicCommunity() &&
                authentication.isAuthenticated() &&
                authentication.community.landingPagePublished &&
                authentication.community.publicLandingPageEnabled
            )) {
            navigate(appLinks.landing(query));
        } else if ((authentication.community.isNotPublicCommunity() && authentication.isJoinCommunityAvailable()) || authentication.actor.hasPendingMembership()) {
            navigate(ROUTES.JOIN_COMMUNITY);
        } else if ((rootPath?.pathname === '/' && rootPath) && !window?.location?.pathname?.includes('ideas') && authentication.community?.landingPagePublished) {
            navigate(appLinks.landing(query));
        }
    }, [authentication, navigate, query, rootPath]);

    useImagePreloader([loadingSvg]);

    return (
        (authentication.community.isAccessLocked() || authentication.community.isAccessSandBoxed())
            ? (
                <Fragment>
                    <TopBarContainer/>
                    <Alert className="alert-popup" svgIconsPath={svgIconsPath} variant="warning" open={true}
                           message={localizer.msg(`access-state.${authentication.community.accessState.toLowerCase()}`)}/>
                </Fragment>)
            : (
                <Fragment>
                    <TopBarContainer/>
                    <RedirectionAlertMessage/>
                    {authentication.isJoinCommunityAvailable() && <Suspense><JoinCommunityPrompt/></Suspense>}

                    {authentication.actor.isAnonymous() && <Suspense><LoginPrompt/></Suspense>}

                    <IdeaFormContainer/>

                    <SidebarContextProvider>
                        <IdeasContextProvider>
                            <SearchContextProvider>
                                <Routes>
                                    <Route path={'/'} element={
                                        (authentication.community.isNotPublicCommunity() && authentication.isAuthenticated() && authentication.community.landingPagePublished && authentication.community.publicLandingPageEnabled)
                                            ?
                                            <Navigate to={appLinks.landing(query)}/>
                                            :
                                            (authentication.community.isNotPublicCommunity() && authentication.isJoinCommunityAvailable()) || authentication.actor.hasPendingMembership()
                                                ?
                                                <Navigate to={ROUTES.JOIN_COMMUNITY}/>
                                                :
                                                authentication.community?.landingPagePublished
                                                    ?
                                                    <Navigate to={appLinks.landing(query)}/>
                                                    :
                                                    <IdeaListPage/>
                                    }/>
                                    <Route path={ROUTES.JOIN_COMMUNITY}
                                           element={<Suspense><PrivateCommunityJoinPage/></Suspense>}/>
                                    {
                                        [ROUTES.HOME, ROUTES.ALL_ACTIVE_IDEAS, ROUTES.ALL_EXPIRED_IDEAS].map((path) =>
                                            <Route path={path} key={path} element={<IdeaListPage/>}/>
                                        )
                                    }
                                    {
                                        [ROUTES.LANDING_PAGE_ACTION, ROUTES.LANDING].map((path) =>
                                            <Route path={path} key={path} element={<LandingPageContextProvider>
                                                <Suspense fallback={<AppLoading/>}>
                                                    <LandingPage/>
                                                </Suspense>
                                            </LandingPageContextProvider>}/>
                                        )
                                    }
                                    <Route path={ROUTES.CAMPAIGN_TOS}
                                           element={<Suspense fallback={<AppLoading/>}><CampaignTosPage/></Suspense>}/>
                                    <Route path={ROUTES.MEMBERSHIP_TOS}
                                           element={<Suspense
                                               fallback={<AppLoading/>}><MembershipTosAcceptPage/></Suspense>}/>
                                    <Route path={ROUTES.MEMBERSHIP_TOS_VIEW}
                                           element={<Suspense
                                               fallback={<AppLoading/>}><MembershipTosViewPage/></Suspense>}/>
                                    <Route path={ROUTES.CAMPAIGN.BASE_WITH_WILDCARD}
                                           element={<Suspense fallback={<AppLoading/>}><CampaignPage/></Suspense>}/>
                                    <Route path={ROUTES.PROFILE.BASE_WITH_WILDCARD}
                                           element={<Suspense fallback={<AppLoading/>}><ProfilePage/></Suspense>}>
                                        <Route path={ROUTES.PROFILE.ACTIVITY}
                                               element={<Suspense
                                                   fallback={<PageLoadingOverlay/>}><ActivityContainer/></Suspense>}/>
                                        <Route path={ROUTES.PROFILE.NOTIFICATIONS}
                                               element={Number(profileRoutesMatch?.params.memberId) === authentication.actor.id &&
                                                   <Suspense fallback={
                                                       <PageLoadingOverlay/>}><NotificationContainer/></Suspense>}/>
                                        <Route path={ROUTES.PROFILE.MY_IDEAS}
                                               element={Number(profileRoutesMatch?.params.memberId) === authentication.actor.id &&
                                                   <MyIdeasListWithContextContainer/>}/>
                                        <Route path={ROUTES.PROFILE.MESSAGES}
                                               element={Number(profileRoutesMatch?.params.memberId) === authentication.actor.id &&
                                                   <Suspense
                                                       fallback={<PageLoadingOverlay/>}><MessageContainer/></Suspense>}/>
                                        <Route path={ROUTES.PROFILE.MEMBER_ID} element={
                                            <Navigate
                                                to={profileRoutesMatch?.params.memberId ? appLinks.activity(profileRoutesMatch?.params.memberId) : ROUTES.HOME}
                                                replace/>
                                        }/>
                                        <Route path="*" element={<Navigate
                                            to={currentProfileId > 0 ? appLinks.activity(currentProfileId) : ROUTES.HOME}
                                            replace/>}/>
                                    </Route>
                                    <Route path={ROUTES.IDEA_DETAILS.BASE_WITH_WILDCARD} element={
                                        <IdeaDetailsContextProvider>
                                            <Suspense fallback={<AppLoading/>}><IdeaDetailsPage/></Suspense>
                                        </IdeaDetailsContextProvider>
                                    }/>
                                    <Route path={ROUTES.LEADERBOARD.BASE_ROUTE}
                                           element={<Suspense fallback={<AppLoading/>}><LeaderboardPage/></Suspense>}/>
                                    <Route path={ROUTES.TAGS}
                                           element={<Suspense fallback={<AppLoading/>}><TagsPage/></Suspense>}/>
                                    <Route path={ROUTES.CAMPAIGN_TAGS}
                                           element={<Suspense fallback={<AppLoading/>}><CampaignTagsPage/></Suspense>}/>
                                    <Route path={ROUTES.SEARCH} element={
                                        <IdeaListContextProvider>
                                            <Suspense fallback={<AppLoading/>}><SearchPage/></Suspense>
                                        </IdeaListContextProvider>
                                    }/>
                                    <Route path={ROUTES.NEW_FEATURES}
                                           element={<Suspense fallback={<AppLoading/>}><NewFeaturesPage/></Suspense>}/>
                                    {
                                        [ROUTES.DIGEST_SHARE, ROUTES.CAMPAIGN_PERFORMANCE].map((path) =>
                                            <Route path={path} key={path}
                                                   element={<Suspense fallback={<AppLoading/>}><DigestPage/></Suspense>}/>
                                        )
                                    }
                                    <Route path={ROUTES.INVITE_FRIENDS}
                                           element={<Suspense fallback={<AppLoading/>}><InviteFriendsPage/></Suspense>}/>
                                    <Route path="*" element={<Navigate to={ROUTES.HOME} replace/>}/>
                                </Routes>
                            </SearchContextProvider>
                        </IdeasContextProvider>
                    </SidebarContextProvider>
                    {
                        authentication.isAuthenticated() &&
                        <Suspense><FeatureTourContainer/></Suspense>
                    }
                    {
                        authentication.isAuthenticated() && communityConfig.supportChatEnabled &&
                        <Suspense><ChatContainer/></Suspense>
                    }
                    <GoogleAnalyticsContainer/>
                    <MembershipTosContainer/>
                    <ScrollToTopButton/>
                </Fragment>
            )
    );
};