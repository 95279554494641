import {useCallback, useEffect, useState} from 'react';
import ReactGA from 'react-ga4';
import {useLocation} from 'react-router-dom';
import {isProductionEnv} from '@ideascale/commons/dist/utils/CommonUtil';
import {useGAEventTracker} from '@ideascale/commons/dist/hooks/useGAEventTracker';
import {useIsMounted} from '@ideascale/commons/dist/hooks/useIsMounted';
import {useAppContext} from 'contexts/AppContext';
import {useCommunityService} from 'hooks/useService';
import {GoogleAnalyticsDimensions} from 'models/GoogleAnalyticsDimensions';

type GA4Config = {
    trackingId: string,
    gaOptions?: any,
    gtagOptions?: any
}

export const GoogleAnalyticsContainer = () => {
    const location = useLocation();
    const communityService = useCommunityService();
    const {
        communityConfig: {
            communityTrackingCode,
            ideascaleTrackingCode
        }
    } = useAppContext();
    const [dimensions, setDimensions] = useState<GoogleAnalyticsDimensions>(GoogleAnalyticsDimensions.EMPTY);
    const loginCategory = useGAEventTracker('login');
    const componentIsMounted = useIsMounted();

    const fetchGoogleAnalyticsDimensions = useCallback(async () => {
        if (communityService !== null) {
            return await communityService.fetchGoogleAnalyticsDimensions();
        }
        return GoogleAnalyticsDimensions.EMPTY;
    }, [communityService]);

    useEffect(() => {
        const products: GA4Config[] = [];
        if (isProductionEnv() && (communityTrackingCode || ideascaleTrackingCode)) {
            fetchGoogleAnalyticsDimensions().then(response => {
                if (componentIsMounted() && response) {
                    setDimensions(response);
                    if (communityTrackingCode) {
                        products.push({
                            trackingId: communityTrackingCode
                        });
                    }

                    if (ideascaleTrackingCode) {
                        products.push({
                            trackingId: ideascaleTrackingCode
                        });
                    }

                    if (products.length > 0) {
                        ReactGA.initialize(products);
                        ReactGA.send({hitType: 'pageview', page: location.pathname + location.search});

                        if (communityTrackingCode) {
                            ReactGA.gtag('event', 'user_activity', {
                                'send_to': communityTrackingCode,
                                'user_role': response.userRole,
                                'total_ideas': response.totalIdeas,
                            });
                        }

                        if (ideascaleTrackingCode) {
                            ReactGA.gtag('event', 'user_activity', {
                                'send_to': ideascaleTrackingCode,
                                'total_logins': response.totalLogins,
                                'user_role': response.userRole + '(' + response.accountType + ')',
                                'total_ideas': response.totalIdeas,
                                'layout': response.layout,
                                'login_option': response.loginOption
                            });
                        }
                    }
                }
            }).catch(error => console.log('analytics error', error));
        }
    }, [communityTrackingCode, componentIsMounted, fetchGoogleAnalyticsDimensions, ideascaleTrackingCode, location.pathname, location.search]);

    if (dimensions?.trackingAction) {
        loginCategory('login', dimensions.trackingAction);
    }
    return null;
};