import React, {Fragment, lazy, Suspense, useEffect} from 'react';
import isNil from 'lodash/isNil';
import {PageTitle} from '@ideascale/commons/dist/components/PageTitle';
import {StringUtil} from '@ideascale/commons/dist/utils/StringUtil';
import {Member} from '@ideascale/commons/dist/models/Member';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {emptyFunction} from '@ideascale/commons/dist/utils/Functions';
import {BodyTagAttributes} from '@ideascale/commons/dist/components/BodyTagAttributes';
import {useAppContext} from 'contexts/AppContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeasPageRouteMatch} from 'hooks/useIdeasPageRouteMatch';
import {PAGE_IDS, SHOW_LOGIN_PROMPT, STAGE_KEYS} from 'constants/AppConstants';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {PageLayoutContainer} from 'containers/PageLayoutContainer';
import {IdeaListWithContextProviderContainer} from 'containers/IdeaListWithContextProviderContainer';
import {IdeaSubmissionEntry} from 'components/IdeaSubmissionEntry';
import {CommunitySidebarContainer} from 'containers/sidebar/CommunitySidebarContainer';
import {CampaignSliderContainer} from 'containers/CampaignSliderContainer';
import {IdeaSubmissionPreviewContainer} from 'containers/IdeaSubmissionPreviewContainer';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import {ROUTES} from 'shared/Routes';

const CommunitySidebarEdit = lazy(() => import('components/edit-mode/CommunitySidebarEdit').then(module => ({default: module.CommunitySidebarEdit})));
const FooterContainer = lazy(() => import('containers/FooterContainer').then(module => ({default: module.FooterContainer})));

export const IdeaListPage = () => {
    const {
        authentication,
        lastSubmittedIdea,
        communityConfig,
        currentCampaign,
        setCurrentCampaign,
        editModeEnabled
    } = useAppContext();
    const {homeConfig} = useEditModeContext();
    const {actor} = authentication;
    const {onSubmitIdeaButtonClick} = useIdeaSubmissionActions();

    const localizer = useLocalizer();
    const {
        routeParams,
        urlTag,
        urlModeratorTag,
        urlLabel,
        urlOrder
    } = useIdeasPageRouteMatch([ROUTES.CAMPAIGN.BASE, ROUTES.CAMPAIGN.IDEAS_PATH]);

    useEffect(() => {
        if (!isNil(currentCampaign)) {
            setCurrentCampaign(null);
        }
    }, [currentCampaign, setCurrentCampaign]);

    return (
        <Fragment>
            <BodyTagAttributes data-test-element-id={PAGE_IDS.COMMUNITY_HOME} data-even={'2'}/>
            <PageTitle
                title={`${communityConfig.name} Home - by IdeaScale | ${routeParams?.mode ? StringUtil.titleCase(routeParams.mode) : StringUtil.titleCase(localizer.msg(`ideas.mode.${communityConfig.defaultIdeaListMode}`))}`}/>
            <IdeaSubmissionPreviewContainer displayAsModal={false}/>
            <PageLayoutContainer
                mainContent={
                    <Fragment>
                        <h1 className="sr-only">{communityConfig.name}</h1>
                        <CampaignSliderContainer hidden={lastSubmittedIdea !== null}/>
                        <IdeaSubmissionEntry
                            localizer={localizer}
                            actionMember={new Member(actor.id, actor.name, actor.username, actor.avatar, '')}
                            onClickAvatar={authentication.actor.isAnonymous() ? () => eventDispatcher.dispatch(SHOW_LOGIN_PROMPT) : emptyFunction}
                            onClick={onSubmitIdeaButtonClick}
                        />

                        <IdeaListWithContextProviderContainer
                            campaignId={routeParams?.campaignId || 'active'}
                            stageKey={routeParams?.stageKey || STAGE_KEYS.UNSPECIFIED}
                            urlLabel={urlLabel}
                            urlModeratorTag={urlModeratorTag}
                            urlTag={urlTag}
                            urlOrder={urlOrder}/>

                        <div className="spacer mb-5"/>
                    </Fragment>
                }
                sidebar={<section
                    className={`idea-sidebar ${homeConfig.isOperationAllowed(CommunityEditableFieldType.SIDEBAR) ? 'edit-mode-element' : ''}`}
                    aria-label={localizer.msg('sidebar.community-sidebar')}>
                    {
                        homeConfig.isOperationAllowed(CommunityEditableFieldType.SIDEBAR)
                            ? <Suspense><CommunitySidebarEdit/></Suspense>
                            : <CommunitySidebarContainer/>
                    }
                </section>}
            />
            {
                !authentication.isCommunityTosAcceptanceRequired() && !editModeEnabled &&
                <Suspense><FooterContainer/></Suspense>
            }
        </Fragment>
    );
};
