import {useCallback} from 'react';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';

export const useTopBarService = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {throwApiErrorResponse} = useApiErrorResponseHandler({localizer});

    const enablePrivilege = useCallback((memberId: number) => {
        if (communityService !== null) {
            try {
                return communityService.enablePrivilege(memberId);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return null;
    }, [communityService, throwApiErrorResponse]);

    const disablePrivilege = useCallback((memberId: number) => {
        if (communityService !== null) {
            try {
                return communityService.disablePrivilege(memberId);
            } catch (e: any) {
                throwApiErrorResponse(e);
            }
        }
        return null;
    }, [communityService, throwApiErrorResponse]);

    return {enablePrivilege, disablePrivilege};
};