import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {buildServiceError, ServiceResponse} from '@ideascale/commons/dist/services/ServiceResponse';
import {plainToClass} from 'class-transformer';

export declare type ClassType<T> = {
    new(...args: any[]): T;
};

export abstract class AbstractService {
    constructor(protected axios: AxiosInstance) {
    }

    protected async getAndUnwrap<T>(url: string, config?: AxiosRequestConfig, model?: ClassType<T>) {
        try {
            const axiosResponse = await this.axios.get<ServiceResponse<T>>(url, config);
            return model ? this.unwrapToModel(model, axiosResponse) : this.unwrap(axiosResponse);
        } catch(error: any) {
            throw buildServiceError(error);
        }
    }

    protected async postAndUnwrap<T, D>(url: string, data?: D, config?: AxiosRequestConfig, model?: ClassType<T>) {
        try {
            const axiosResponse = await this.axios.post<ServiceResponse<T>>(url, data, config);
            return model ? this.unwrapToModel(model, axiosResponse) : this.unwrap(axiosResponse);
        } catch(error: any) {
            throw buildServiceError(error);
        }
    }

    protected async getAsPostAndUnwrap<T, D>(url: string, data?: D, config?: AxiosRequestConfig, model?: ClassType<T>) {
        try {
            const newHeader = {...config?.headers, ...{'Get-As-Post': true}};
            const newConfig: AxiosRequestConfig = {...config, ...{headers: newHeader}};
            const axiosResponse = await this.axios.post<ServiceResponse<T>>(url, data, newConfig);
            return model ? this.unwrapToModel(model, axiosResponse) : this.unwrap(axiosResponse);
        } catch (error: any) {
            throw buildServiceError(error);
        }
    }

    protected async deleteAndUnwrap<T, D>(url: string, data?: D, config?: AxiosRequestConfig, model?: ClassType<T>) {
        try {
            const axiosResponse = await this.axios.request<ServiceResponse<T>>({
                method: 'DELETE',
                url,
                data,
                ...config
            });
            return model ? this.unwrapToModel(model, axiosResponse) : this.unwrap(axiosResponse);
        } catch(error: any) {
            throw buildServiceError(error);
        }
    }

    protected async patchAndUnwrap<T, D>(url: string, data?: D, config?: AxiosRequestConfig, model?: ClassType<T>) {
        try {
            const axiosResponse = await this.axios.patch<ServiceResponse<T>>(url, data, config);
            return model ? this.unwrapToModel(model, axiosResponse) : this.unwrap(axiosResponse);
        } catch(error: any) {
            throw buildServiceError(error);
        }
    }

    protected unwrap<T>(axiosResponse: AxiosResponse<ServiceResponse<T>>) {
        const serviceResponse = axiosResponse.data;
        return ((serviceResponse && 'data' in serviceResponse) ? serviceResponse.data : serviceResponse) as T;
    }

    protected unwrapToModel<T>(model: ClassType<T>, axiosResponse: AxiosResponse<ServiceResponse<T>>): T {
        const data = this.unwrap(axiosResponse);
        return plainToClass(model, data);
    }
}
