import {useCallback} from 'react';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {Member} from '@ideascale/commons/dist/models/Member';
import {useCommunityService} from 'hooks/useService';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {CampaignModuleContent} from 'models/CampaignModuleContent';
import {CampaignGroup} from 'models/CampaignGroup';
import {SidebarCampaign} from 'models/SidebarCampaign';
import {CampaignSummary} from 'models/CampaignSummary';
import {CampaignDetailsData} from 'models/CampaignDetailsData';
import {TosData} from 'models/TosData';
import {GenericResponse} from 'models/GenericResponse';

export const useCampaignModuleService = () => {
    const communityService = useCommunityService();

    const fetchSidebarActiveCampaignModule = useCallback(async (pageParameters: PageParameters): Promise<CampaignModuleContent> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarActiveCampaignModule(pageParameters);
        }
        return CampaignModuleContent.EMPTY;
    }, [communityService]);

    const fetchActiveCampaignGroups = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<CampaignGroup>> => {
        if (communityService !== null) {
            return await communityService.fetchActiveCampaignGroups(pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchActiveCampaignsByGroup = useCallback(async (groupId: number, pageParameters: PageParameters): Promise<PagedResponseContent<SidebarCampaign>> => {
        if (communityService !== null) {
            return await communityService.fetchActiveCampaignsByGroup(groupId, pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchSidebarArchivedCampaignModule = useCallback(async (pageParameters: PageParameters): Promise<CampaignModuleContent> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarArchivedCampaignModule(pageParameters);
        }
        return CampaignModuleContent.EMPTY;
    }, [communityService]);

    const fetchArchivedCampaignGroups = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<CampaignGroup>> => {
        if (communityService !== null) {
            return await communityService.fetchArchivedCampaignGroups(pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchArchivedCampaignsByGroup = useCallback(async (groupId: number, pageParameters: PageParameters): Promise<PagedResponseContent<SidebarCampaign>> => {
        if (communityService !== null) {
            return await communityService.fetchArchivedCampaignsByGroup(groupId, pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchSidebarCampaignSummary = useCallback(async (id: number): Promise<CampaignSummary> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarCampaignSummary(id);
        }
        return CampaignSummary.EMPTY;
    }, [communityService]);

    const fetchCampaignSummary = useCallback(async (id: number) => {
        if (communityService !== null) {
            return await communityService.fetchCampaign(id);
        }
        return Campaign.EMPTY;
    }, [communityService]);

    const fetchCampaignDetails = useCallback(async (campaignId: number): Promise<CampaignDetailsData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignDetails(campaignId);
        }
        return CampaignDetailsData.EMPTY;
    }, [communityService]);

    const fetchCampaignTosContent = useCallback(async (campaignId: number): Promise<TosData> => {
        if (communityService !== null) {
            return await communityService.fetchCampaignTosContent(campaignId);
        }
        return TosData.EMPTY;
    }, [communityService]);

    const campaignTosAccept = useCallback(async (campaignId: number): Promise<GenericResponse> => {
        if (communityService !== null) {
            return await communityService.campaignTosAccept(campaignId);
        }
        return GenericResponse.EMPTY;
    }, [communityService]);

    const fetchRecipientsByCampaign = useCallback(async (parameters: PageParameters, campaignId: number): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            return await communityService.fetchRecipientsByCampaign(parameters, campaignId);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    return {
        fetchSidebarActiveCampaignModule,
        fetchActiveCampaignGroups,
        fetchActiveCampaignsByGroup,
        fetchSidebarArchivedCampaignModule,
        fetchArchivedCampaignGroups,
        fetchArchivedCampaignsByGroup,
        fetchSidebarCampaignSummary,
        fetchCampaignSummary,
        fetchCampaignDetails,
        fetchCampaignTosContent,
        campaignTosAccept,
        fetchRecipientsByCampaign
    };
};