import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation, useQueryClient} from 'react-query';
import cloneDeep from 'lodash/cloneDeep';
import {AlertEvent, AlertType, buildAlertEventData} from '@ideascale/commons/dist/utils/AlertEvent';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {IdeaLabelsHolder} from '@ideascale/commons/dist/models/IdeaLabelsHolder';
import {PagedIdeas, useIdeasContext} from 'contexts/IdeasContext';
import {useLocalizer} from './useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {QUERY_KEYS} from 'constants/AppConstants';
import {appLinks} from 'services/AppLinks';
import {LabelActionParams} from 'models/types/LabelActionParams';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaReportingResponse} from 'models/types/IdeaReportingResponse';
import {ResponseStatusType} from 'models/enums/ResponseStatusType';
import {IdeaDetail} from 'models/IdeaDetail';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';

type ReportingMutationType = {
    ideaId: number;
    isAbuseReporting: boolean;
}

export const useIdeaLabels = (ideaListQueryKey: any = '') => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const localizer = useLocalizer();
    const {updateLabels, reportAbuse, reportDuplicate} = useIdeaService();
    const {ideaListFilterQueryKey} = useIdeasContext();
    const {authentication: {actor}} = useAppContext();
    const {ideaDetailsQueryKey, removeIdeaFromIdeaList} = useIdeaUpdater();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const onSettleAddOrRemoveLabelMutation = async (labelsHolder: IdeaLabelsHolder | undefined, error: Error | null, labelActionParams: LabelActionParams) => {
        if (!error && labelsHolder && labelActionParams.ideaId) {
            if (labelsHolder?.rejectedLabels && labelsHolder.rejectedLabels.length > 0) {
                const invalidLabels = labelsHolder.rejectedLabels.map((label) => `"${label.name}"`).join(', ');
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn,
                    localizer.msg('idea.actions.label-invalid', {labels: invalidLabels})));
            }
            queryClient.setQueryData([QUERY_KEYS.IDEA_LABELS, labelActionParams.ideaId], oldData => {
                const updatedData = oldData as IdeaLabelActionResponse;
                updatedData.labels.forEach(label => {
                    label.applied = labelsHolder.labels?.map(item => item.id).includes(label.id) ?? label.applied;
                });
                return updatedData;
            });
        }
    };

    const ideaListUpdateLabelsMutation = useMutation<IdeaLabelsHolder, Error, LabelActionParams, unknown>((labelActionParams: LabelActionParams) => updateLabels(labelActionParams), {
        onSuccess: async (labelsHolder: IdeaLabelsHolder, {ideaId}: LabelActionParams) => {
            await queryClient.cancelQueries(ideaListQueryKey);
            queryClient.setQueryData(ideaListQueryKey, (oldIdeaList: any) => {
                if (oldIdeaList) {
                    const newIdeas = cloneDeep(oldIdeaList) as { pages: PagedIdeas[], pageParams: any };
                    newIdeas.pages.forEach((page: PagedIdeas) => {
                        page.data.forEach((idea: IdeaSummary) => {
                            if (idea.id === ideaId) {
                                idea.labels.length = 0;
                                idea.labels.push(...labelsHolder.labels);
                            }
                        });
                    });
                    return newIdeas;
                }
                return [];
            });
        },
        onSettled: onSettleAddOrRemoveLabelMutation
    });

    const ideaDetailsUpdateLabelsMutation = useMutation<IdeaLabelsHolder, Error, LabelActionParams, unknown>((labelActionParams: LabelActionParams) => updateLabels(labelActionParams), {
        onSuccess: async (labelsHolder: IdeaLabelsHolder, {ideaId}: LabelActionParams) => {
            await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
            queryClient.setQueryData(ideaDetailsQueryKey(ideaId), oldIdeaDetails => {
                const modifyIdeaDetails = cloneDeep(oldIdeaDetails) as IdeaDetail;
                modifyIdeaDetails.labels.length = 0;
                modifyIdeaDetails.labels.push(...labelsHolder.labels);
                return modifyIdeaDetails;
            });
        },
        onSettled: onSettleAddOrRemoveLabelMutation
    });

    const ideaListReportingMutation = useMutation(
        ({
             ideaId,
             isAbuseReporting
         }: ReportingMutationType) => isAbuseReporting ? reportAbuse(ideaId) : reportDuplicate(ideaId), {
            onSuccess: async (ideaReportingResponse: IdeaReportingResponse, {ideaId}: ReportingMutationType) => {
                eventDispatcher.dispatch(
                    AlertEvent.ALERT,
                    buildAlertEventData(ideaReportingResponse?.status === ResponseStatusType.success ? AlertType.success : AlertType.error, ideaReportingResponse.message)
                );

                if (ideaReportingResponse.thresholdReached) {
                    await queryClient.cancelQueries(ideaListQueryKey);
                    queryClient.setQueryData(ideaListQueryKey, (oldIdeaList: any) => {
                        if (oldIdeaList) {
                            const newIdeas = cloneDeep(oldIdeaList) as { pages: PagedIdeas[], pageParams: any };
                            newIdeas.pages.forEach((page: PagedIdeas) => {
                                const findIndex = page.data.findIndex(idea => idea.id === ideaId);
                                if (findIndex >= 0) {
                                    page.data.splice(findIndex, 1);
                                }
                            });
                            return newIdeas;
                        }
                        return [];
                    });
                }
            },
            onError: (error: Error) => {
                handleErrorResponse(error);
            },
            onSettled: async (labelData, error, {ideaId}) => {
                if (!error && ideaId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.MORE_ACTIONS, ideaId]);
                    await queryClient.invalidateQueries(ideaListQueryKey);
                }
            }
        });

    const ideaDetailsReportingMutation = useMutation(
        ({
             ideaId,
             isAbuseReporting
         }: ReportingMutationType) => isAbuseReporting ? reportAbuse(ideaId) : reportDuplicate(ideaId), {
            onSuccess: async (ideaReportingResponse: IdeaReportingResponse, {ideaId}: ReportingMutationType) => {
                eventDispatcher.dispatch(
                    AlertEvent.ALERT,
                    buildAlertEventData(ideaReportingResponse?.status === ResponseStatusType.success ? AlertType.success : AlertType.error, ideaReportingResponse.message)
                );

                if (ideaReportingResponse.thresholdReached) {
                    await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
                    removeIdeaFromIdeaList(ideaListFilterQueryKey, ideaId);
                    queryClient.removeQueries(ideaDetailsQueryKey(ideaId));
                }
            },
            onError: (error: Error) => {
                handleErrorResponse(error);
            },
            onSettled: async (labelData, error, {ideaId}) => {
                if (!error && ideaId) {
                    await queryClient.invalidateQueries([QUERY_KEYS.MORE_ACTIONS, ideaId]);
                    await queryClient.invalidateQueries(ideaListFilterQueryKey);
                }
            }
        });

    const ideaLabelRouteChange = useCallback((labelData: LabelData) => {
        if (labelData.searchable) {
            navigate(appLinks.label(labelData, String(actor.id)));
        }
    }, [actor.id, navigate]);

    return {
        ideaListUpdateLabelsMutation,
        ideaDetailsUpdateLabelsMutation,
        ideaListReportingMutation,
        ideaDetailsReportingMutation,
        ideaLabelRouteChange
    };
};