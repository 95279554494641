import {Suspense, useCallback, useEffect, lazy} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {scroller} from 'react-scroll';
import {ButtonDropdown, DropdownMenu, DropdownToggle, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {AlertEvent, AlertType, buildAlertEventData} from '@ideascale/commons/dist/utils/AlertEvent';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {ClassificationAttachmentAttribute} from '@ideascale/commons/dist/models/classification/ClassificationAttachmentAttribute';
import {ClassificationFieldAttribute} from '@ideascale/commons/dist/models/classification/ClassificationFieldAttribute';
import {EmailGroupType} from '@ideascale/commons/dist/models/enums/EmailGroupType';
import {EmailIdeaContent} from '@ideascale/commons/dist/models/EmailIdeaContent';
import {EmailRequest} from '@ideascale/commons/dist/models/EmailRequest';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {Member} from '@ideascale/commons/dist/models/Member';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useToggle} from '@ideascale/commons/dist/hooks/useToggle';
import {Backdrop} from '@ideascale/commons/dist/components/shared/Backdrop';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaListContext} from 'contexts/IdeaListContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useIdeaActions} from 'hooks/useIdeaActions';
import {useToggleIdeaSubmissionModal} from 'hooks/useToggleIdeaSubmissionModal';
import {appLinks} from 'services/AppLinks';
import {SCROLL_EVENTS, STAGE_KEYS} from 'constants/AppConstants';
import {CommonUtil} from 'utils/CommonUtil';
import {useIdeaLabelAction} from 'hooks/useIdeaLabelAction';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useClassificationService} from 'hooks/useClassificationService';
import {useCampaignChange} from 'hooks/useCampaignChange';
import {IdeaHeader} from 'components/idea/IdeaHeader';
import {StageSummary} from 'components/idea/StageSummary';
import {IdeaMoreActions} from 'components/idea/IdeaMoreActions';
import {IdeaContent} from 'components/idea/IdeaContent';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaModerateActionResponse} from 'models/IdeaModerateActionResponse';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {GenericResponse} from 'models/GenericResponse';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaLabelActionResponse} from 'models/IdeaLabelActionResponse';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';
import './Idea.scss';

const EditIdeaFormContainer = lazy(() => import('containers/EditIdeaFormContainer').then(module => ({default: module.EditIdeaFormContainer})));
const ConfirmationModal = lazy(() => import('@ideascale/commons/dist/components/confirmation-modal/ConfirmationModal').then(module => ({default: module.ConfirmationModal})));
const GroupEmailIdeaModal = lazy(() => import('@ideascale/commons/dist/components/email-modal/GroupEmailIdeaModal').then(module => ({default: module.GroupEmailIdeaModal})));
const ChangeCampaignModal = lazy(() => import('@ideascale/commons/dist/components/change-campaign/ChangeCampaignModal').then(module => ({default: module.ChangeCampaignModal})));

const AssignOwnersModal = lazy(() => import('components/action-modals/AssignOwnersModal').then(module => ({default: module.AssignOwnersModal})));
const IdeaLabelItems = lazy(() => import('components/IdeaLabelItems').then(module => ({default: module.IdeaLabelItems})));
const StageAction = lazy(() => import('components/idea/StageAction').then(module => ({default: module.StageAction})));
const IdeaModerateActions = lazy(() => import('components/idea/IdeaModerateActions').then(module => ({default: module.IdeaModerateActions})));
const DraftIdeaAction = lazy(() => import('components/idea/DraftIdeaAction').then(module => ({default: module.DraftIdeaAction})));

export type IdeaProps = {
    containerId?: string,
    localizer: Localizer;
    content: IdeaSummary;
    isSearchResult?: boolean;
    fetchModerateActions: (ideaId: number) => Promise<IdeaModerateActionResponse>;
    fetchMoreActions: (ideaId: number) => Promise<IdeaMoreActionResponse>;
    fetchIdeaLabels: (ideaId: number) => Promise<IdeaLabelActionResponse>;
    toggleIdeaFollowed: (ideaId: number, followed: boolean) => Promise<GenericResponse>;
    toggleAuthorFollowed: (memberId: number, followed: boolean) => Promise<GenericResponse>;
    toggleCommentingEnabled: (ideaId: number, enabled: boolean) => Promise<void>;
    fetchMembers: (data: PageParameters, ideaId: number) => Promise<PagedResponseContent<Member>>;
    fetchAssignedOwners: (ideaId: number) => Promise<Member[]>;
    fetchRecipients: (pageParameters: PageParameters) => Promise<PagedResponseContent<Member>>;
    emailIdeaAuthor: (data: EmailRequest, ideaId: number, groupId: number) => Promise<void>;
    fetchAuthorEmails: (ideaId: number) => Promise<string[]>;
    banMember: (memberId: number) => Promise<GenericResponse>;
    togglePinIdeas: (ideaId: number, pinned: boolean) => Promise<void>;
    getAuthorEmailIdeaContent: (ideaId: number) => Promise<EmailIdeaContent>;
    listViewMode?: IdeaListViewMode;
}

export const Idea = (props: IdeaProps) => {
    const {
        containerId,
        localizer,
        content,
        isSearchResult = false,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchAssignedOwners,
        fetchMembers,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        togglePinIdeas,
        getAuthorEmailIdeaContent,
        listViewMode = IdeaListViewMode.GRID_VIEW,
    } = props;
    const {
        currentCampaign,
        communityConfig: {
            ideaListViewMode,
            maxFileSizeLimit,
            offensiveEmojis,
            classificationEnabled
        },
        authentication
    } = useAppContext();
    const {
        onToggleVote,
        giveKudo,
        reportAbuse,
        reportDuplicate,
        assignOwners,
        changeIdeaStage,
        deleteIdea,
        changeCampaign,
        editIdea,
    } = useIdeaListContext();
    const {
        onAddIdeaLabel,
        onRemoveIdeaLabel,
        updateIdeaListLabels,
    } = useIdeaLabelAction(content.labels);
    const {tempImageUpload} = useFileUploadService();
    const {fetchClassifications} = useClassificationService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {
        fetchDropdownCampaigns,
        fetchChangeCampaignStages,
        isChangeToDifferentFunnel
    } = useCampaignChange(undefined, content);
    const location = useLocation();
    const {communityConfig} = useAppContext();
    const [, toggleUseFormModal] = useToggleIdeaSubmissionModal();
    const [showCommentConfirmation, toggleShowCommentConfirmation] = useToggle(false);
    const [showBanConfirmation, toggleShowBanConfirmation] = useToggle(false);
    const [showAssignOwnerModal, toggleAssignOwnerModal] = useToggle(false);
    const [showEditIdeaModal, toggleEditIdeaModal] = useToggle(false);
    const [showChangeCampaignModal, toggleChangeCampaignModal] = useToggle(false);
    const [showEmailAuthorModal, toggleEmailAuthorModal] = useToggle(false);
    const [showReportAbuseConfirmation, toggleReportAbuseConfirmation] = useToggle(false);
    const [showReportDuplicateConfirmation, toggleReportDuplicateConfirmation] = useToggle(false);
    const [labelDropdownOpen, toggleLabelDropdown] = useToggle(false);

    const {
        onStageActionClick,
        onToggleAuthorFollowAction,
        onToggleFollowAction,
        onToggleCommentEnabled,
        onBanMemberAction,
        onTogglePinToLandingPageAction,
    } = useIdeaActions();
    const {homeConfig, communityHomeEditor} = useEditModeContext();

    const onFollowToggleClicked = useCallback(async (followed: boolean) => {
        await onToggleFollowAction(toggleIdeaFollowed, content.id, followed, false);
    }, [content.id, onToggleFollowAction, toggleIdeaFollowed]);

    const followAuthorAction = useCallback((followed: boolean) => {
        onToggleAuthorFollowAction(toggleAuthorFollowed, content.id, content.submitter.id, followed);
    }, [content.id, content.submitter.id, onToggleAuthorFollowAction, toggleAuthorFollowed]);

    const confirmToggleCommentEnabled = useCallback(async () => {
        await onToggleCommentEnabled(toggleCommentingEnabled, content.id, content.title, content.commentingEnabled, false);
    }, [content.commentingEnabled, content.id, content.title, onToggleCommentEnabled, toggleCommentingEnabled]);

    const confirmBanMember = useCallback(async () => {
        await onBanMemberAction(banMember, content.id, content.submitter.id, false);
    }, [banMember, content.id, content.submitter.id, onBanMemberAction]);

    const togglePinToLandingPage = useCallback(async (pinned: boolean) => {
        await onTogglePinToLandingPageAction(togglePinIdeas, content.id, pinned, false);
    }, [onTogglePinToLandingPageAction, togglePinIdeas, content.id]);

    const onCampaignChange = useCallback(async (selectedCampaign: Campaign, stageId?: number, tosAccepted?: boolean,) => {
        try {
            await changeCampaign({
                ideaId: content.id,
                campaignId: selectedCampaign.id,
                stageId: stageId,
                isDifferentStage: content.campaign?.funnelId !== selectedCampaign.funnelId,
                tosAccepted: tosAccepted
            });
            eventDispatcher.dispatch(
                AlertEvent.ALERT,
                buildAlertEventData(
                    AlertType.success,
                    localizer.msg('idea.actions.move.campaign-changed-from',
                        {
                            from: content.campaign?.name || '',
                            to: selectedCampaign.name
                        }
                    )
                )
            );
        } catch (error: any) {
            handleErrorResponse(error);
        }
    }, [changeCampaign, content.campaign?.funnelId, content.campaign?.name, content.id, handleErrorResponse, localizer]);


    const onLabelDropDownToggled = useCallback(async () => {
        toggleLabelDropdown();
        await updateIdeaListLabels(content.id);
    }, [content.id, toggleLabelDropdown, updateIdeaListLabels]);


    useEffect(() => {
        if (location.hash) {
            const scrollTargetIdea = location.hash.replace('#', '').toString();
            const ideaId = (scrollTargetIdea.split('-') || []).length === 2 ? scrollTargetIdea.split('-')[1] : -1;
            if (!!scrollTargetIdea && +ideaId === content.id) {
                CommonUtil.wait(500).then(() => {
                    scroller.scrollTo(scrollTargetIdea, {
                        duration: 500,
                        delay: 0,
                        smooth: true,
                        offset: -60,
                        ...(containerId && {containerId})
                    });
                });
            }
        }
    }, [containerId, content.id, location.hash]);

    return (
        <article className={`idea ${content?.draft ? 'draft-idea' : ''}`} id={`idea-${content.id}`}
                 data-idea-id={content.id} aria-label={content.title}>
            <IdeaHeader
                isSearchResult={isSearchResult}
                ideaNumber={content.ideaNumber}
                idPrefix={'idea'}
                campaign={content.campaign}
                ideaId={content.id}
                ideaTitle={content.title}
                ideaCreatedAt={content.createdAt}
                linkableIdeaTitle={true}
                submitter={content.submitter}
                coSubmitters={content.coSubmitters}
                kudosCount={content.kudoCount}
                kudoGiven={content.kudoGiven}
                giveKudo={giveKudo}
                labels={content.labels}
                draft={content.draft}
                showLabelsStackView={
                    homeConfig.isOperationAllowed(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE)
                        ? communityHomeEditor.ideaListViewModeConfig.value !== IdeaListViewMode.LIST_VIEW
                        : ideaListViewMode !== IdeaListViewMode.LIST_VIEW
                }
                classification={content.attributes as ClassificationFieldAttribute[]}
            />
            <div className="idea-main-details mb-3">
                <IdeaContent localizer={localizer} ideaId={content.id} description={content.description}
                             attachments={content.attachments}
                             classification={content.attributes as ClassificationAttachmentAttribute[]}/>
                <ul className="idea-tags list-unstyled d-flex flex-wrap" aria-label={localizer.msg('common.idea-tag')}>
                    {
                        content.tags?.map(tag =>
                            <li key={tag}>
                                <Link className="tag" data-test-element-id="idea-tag"
                                      onClick={() => eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP)}
                                      to={appLinks.tag(tag, currentCampaign?.id.toString(), currentCampaign ? STAGE_KEYS.ALL_STAGES : STAGE_KEYS.UNSPECIFIED)}><span
                                    className="tag-label">{tag}</span></Link>
                            </li>
                        )
                    }
                </ul>
            </div>

            {
                content.stageSummary &&
                <StageSummary ideaStageSummary={content.stageSummary} ideaId={content.id}/>
            }
            {
                content.draft ?
                    <Suspense>
                        <DraftIdeaAction localizer={localizer} onDeleteClick={() => deleteIdea(content.id)}
                                         onEditClick={() => toggleUseFormModal(content.id, content.submitter.id)}
                                         ideaSubmitter={content.submitter}/>
                    </Suspense>
                    :
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            content.stageSummary &&
                            <Suspense>
                                <StageAction ideaStageSummary={content.stageSummary}
                                             onClickAction={() => onStageActionClick(content.stageSummary, content.id)}
                                             onVote={(voteParameters, voteType) => onToggleVote(content.id, voteType, voteParameters)}/>
                            </Suspense>
                        }

                        <div className="idea-action-items d-flex ms-lg-n2 align-items-center">
                            {
                                content.commentingAllowed && content.commentingEnabled && (
                                    <Link
                                        className={`ms-2 border-radius-0 ${content.commentCount > 99 && listViewMode === IdeaListViewMode.GRID_VIEW ? 'me-n1' : ''}`}
                                        to={appLinks.ideaDetailsComment(content.id)}
                                        data-test-element-id="btn-comments"
                                        title={content.commentCount === 0 ?
                                            localizer.msg('common.leave-a-comment') :
                                            localizer.msg('common.go-to-comment')}><Icon
                                        name="message-solid-right" fill="#666"
                                        width={17}
                                        height={17}
                                        iconSpritePath={svgIconsPath}/>
                                        {
                                            content?.commentCount !== 0 &&
                                            <span
                                                className={`count ms-1 ${content.commentCount > 99 && listViewMode === IdeaListViewMode.GRID_VIEW ? 'font-size-sm' : ''}`}>
                                                {content.commentCount > 99 ? '99+' : content.commentCount}
                                                <span className="sr-only">
                                                    {
                                                        localizer.msg('common.comment', {count: content.commentCount})
                                                    }
                                                </span>
                                            </span>
                                        }
                                    </Link>
                                )
                            }

                            {
                                content.followingEnabled &&
                                <button className="ms-2 btn btn-link p-0 border-radius-0"
                                        data-test-element-id={content.subscribed ? 'btn-unfollow' : 'btn-follow'}
                                        onClick={() => onFollowToggleClicked(content.subscribed)}
                                        title={content.subscribed ? localizer.msg('idea.actions.unfollow-title') : localizer.msg('idea.actions.follow-title')}>
                                    <Icon
                                        className={`position-relative pos-top-n1 ${content.subscribed ? 'active' : ''}`}
                                        name="star-solid" width={19}
                                        height={19}
                                        iconSpritePath={svgIconsPath}/>
                                </button>
                            }

                            {
                                authentication.isAuthenticated() && !!authentication.actor.id && content.labellingAllowed &&
                                <ButtonDropdown
                                    className="drawer-able moderation-action-menu modify-label-container ms-2"
                                    drop="down"
                                    isOpen={labelDropdownOpen}
                                    toggle={onLabelDropDownToggled}>
                                    <Backdrop/>
                                    <DropdownToggle tag="button" className="btn btn-idea-moderator p-0"
                                                    data-test-element-id="btn-set-label"
                                                    title={localizer.msg('idea.actions.label-title')}>
                                        <Icon className="pointer-events-none" name="flag-waving" width={17}
                                              height={17} fill="#666" iconSpritePath={svgIconsPath}/>
                                    </DropdownToggle>
                                    <div className="right-panel">
                                        <DropdownMenu drawerAble={false} id={`idea-${content.id}-labels-menu`}
                                                      role="navigation"
                                                      className="dropdown-menu px-3 py-2 modify-label"
                                                      end>
                                            {
                                                labelDropdownOpen &&
                                                <Suspense>
                                                    <IdeaLabelItems localizer={localizer} ideaId={content.id}
                                                                    focusId={`idea-${content.id}-labels-menu`}
                                                                    fetchIdeaLabels={fetchIdeaLabels}
                                                                    onAddIdeaLabel={onAddIdeaLabel}
                                                                    onRemoveIdeaLabel={onRemoveIdeaLabel}
                                                                    toggleDropdown={onLabelDropDownToggled}
                                                                    toggleReportAbuseConfirmation={toggleReportAbuseConfirmation}
                                                                    toggleReportDuplicateConfirmation={toggleReportDuplicateConfirmation}
                                                                    onChangeIdeaStage={changeIdeaStage}
                                                                    stageSummary={content.stageSummary}
                                                                    isDetailsPage={false}/>
                                                </Suspense>
                                            }
                                        </DropdownMenu>
                                    </div>
                                </ButtonDropdown>
                            }

                            {
                                content.moderate &&
                                <Suspense>
                                    <IdeaModerateActions
                                        localizer={localizer}
                                        selectedIdea={content}
                                        fetchModerateActions={fetchModerateActions}
                                        toggleChangeCampaignModal={toggleChangeCampaignModal}
                                        toggleEditIdeaModal={toggleEditIdeaModal}
                                        toggleCommentEnabled={toggleShowCommentConfirmation}
                                        togglePinToLandingPage={togglePinToLandingPage}
                                        onIdeaStageChange={changeIdeaStage}
                                        onIdeaDelete={deleteIdea}
                                    />
                                </Suspense>
                            }

                            {
                                content.memberActionAllowed &&
                                <Suspense>
                                    <IdeaMoreActions
                                        selectedIdea={content} localizer={localizer}
                                        fetchMoreActions={fetchMoreActions}
                                        toggleAssignOwnerModal={toggleAssignOwnerModal}
                                        toggleEmailAuthorModal={toggleEmailAuthorModal}
                                        toggleBanMemberModal={toggleShowBanConfirmation}
                                        followAuthorAction={followAuthorAction}
                                        stageId={content.stageSummary.stage.id}
                                    />
                                </Suspense>
                            }
                        </div>
                    </div>
            }

            {
                showCommentConfirmation &&
                <Suspense>
                    <ConfirmationModal localizer={localizer}
                                       message={content.commentingEnabled ? localizer.msg('idea.actions.comment-disable-confirmation') : localizer.msg('idea.actions.comment-enable-confirmation')}
                                       open={showCommentConfirmation} toggle={toggleShowCommentConfirmation}
                                       onConfirm={confirmToggleCommentEnabled}/>
                </Suspense>
            }
            {
                showBanConfirmation &&
                <Suspense>
                    <ConfirmationModal localizer={localizer}
                                       message={localizer.msg('idea.actions.ban-member-confirmation')}
                                       open={showBanConfirmation} toggle={toggleShowBanConfirmation}
                                       onConfirm={confirmBanMember}/>
                </Suspense>
            }
            {
                showReportAbuseConfirmation && (
                    <Suspense>
                        <ConfirmationModal localizer={localizer} message={localizer.msg('idea.actions.abused-confirm')}
                                           open={showReportAbuseConfirmation} toggle={toggleReportAbuseConfirmation}
                                           onConfirm={() => reportAbuse(content.id)}/>
                    </Suspense>
                )
            }

            {
                showReportDuplicateConfirmation && (
                    <Suspense>
                        <ConfirmationModal
                            localizer={localizer} message={localizer.msg('idea.actions.duplicate-confirm')}
                            open={showReportDuplicateConfirmation} toggle={toggleReportDuplicateConfirmation}
                            onConfirm={() => reportDuplicate(content.id)}/>
                    </Suspense>
                )
            }

            {
                showAssignOwnerModal && (
                    <Suspense>
                        <AssignOwnersModal localizer={localizer} open={showAssignOwnerModal} toggle={toggleAssignOwnerModal}
                                           selectedIdea={content}
                                           fetchOwnersByName={fetchMembers}
                                           assignReassignOwners={assignOwners}
                                           fetchAssignedOwners={fetchAssignedOwners}/>
                    </Suspense>
                )
            }

            {
                showChangeCampaignModal && (
                    <Suspense>
                        <ChangeCampaignModal
                            open={showChangeCampaignModal}
                            toggle={toggleChangeCampaignModal}
                            localizer={localizer}
                            svgIconsPath={svgIconsPath}
                            heading={localizer.msg('idea.actions.move.heading')}
                            classificationEnabled={classificationEnabled}
                            defaultCampaign={content.campaign}
                            message={localizer.msg('idea.actions.move.campaign-warning')}
                            isDifferentFunnel={isChangeToDifferentFunnel}
                            fetchCampaignStages={fetchChangeCampaignStages}
                            fetchDropdownCampaigns={fetchDropdownCampaigns}
                            onSaveCampaignChange={onCampaignChange}
                            communityUrl={communityConfig.url}
                        />
                    </Suspense>
                )
            }

            {
                showEmailAuthorModal && (
                    <Suspense>
                        <GroupEmailIdeaModal open={showEmailAuthorModal}
                                             toggle={toggleEmailAuthorModal}
                                             localizer={localizer}
                                             svgIconsPath={svgIconsPath}
                                             maxFileSizeLimit={maxFileSizeLimit}
                                             groupType={EmailGroupType.IDEA_OWNERS}
                                             communityName={communityConfig.name}
                                             emailContentId={content.id}
                                             emailContentTitle={content.title}
                                             emailGroupMembers={emailIdeaAuthor}
                                             fetchGroupRecipients={fetchAuthorEmails}
                                             modalTitle={localizer.msg('idea.actions.email-idea-title')}
                                             getEmailContent={() => getAuthorEmailIdeaContent(content.id)}
                                             communityUrl={communityConfig.url}
                                             tempImageUpload={tempImageUpload}
                                             offensiveEmojis={offensiveEmojis}
                                             classificationEnabled={classificationEnabled}
                                             fetchClassifications={() => fetchClassifications(content.campaign?.id)}
                        />
                    </Suspense>
                )
            }

            {
                showEditIdeaModal && (
                    <Suspense>
                        <EditIdeaFormContainer ideaId={content.id} open={showEditIdeaModal} toggle={toggleEditIdeaModal}
                                               editIdea={editIdea}/>
                    </Suspense>
                )
            }
        </article>
    );
};