import React, {FormEvent, Fragment, useCallback, useEffect, useRef, useState, lazy, Suspense} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Button, Icon, ParagraphSkeleton, Theme} from '@ideascale/ui';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData
} from '@ideascale/commons/dist/utils/AlertEvent';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {useRoutesMatch} from '@ideascale/commons/dist/hooks/useRoutesMatch';
import {useToggle} from '@ideascale/commons/dist/hooks/useToggle';
import {truncateTo99} from '@ideascale/commons/dist/utils/CommonUtil';
import {postLoginRequest} from '@ideascale/commons/dist/utils/CommonUtil';
import {TobBarLogo} from '@ideascale/commons/dist/components/topbar/topbar-logo/TobBarLogo';
import {SimpleDropdown} from '@ideascale/commons/dist/components/simple-dropdown/SimpleDropdown';
import {Actor} from '@ideascale/commons/dist/models/Actor';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {CampaignsHolder} from '@ideascale/commons/dist/models/CampaignsHolder';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {Member} from '@ideascale/commons/dist/models/Member';
import {MessageData} from '@ideascale/commons/dist/models/MessageData';
import {ClassificationsHolder} from '@ideascale/commons/dist/models/classification/ClassificationsHolder';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {useIsMounted} from '@ideascale/commons/dist/hooks/useIsMounted';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {useProfileFieldsService} from 'hooks/useProfileFieldsService';
import {useIdeaSubmissionActions} from 'hooks/useIdeaSubmissionActions';
import {useProfilePageParams} from 'hooks/useProfilePageParams';
import {useHtmlOrJavascriptInjectionAlert} from 'hooks/useHtmlOrJavascriptInjectionAlert';
import {useCookieConsentConfiguration} from 'hooks/useCookieConsentConfiguration';
import {useMessageService} from 'hooks/useMessageService';
import {useFileUploadService} from 'hooks/useFileUploadService';
import {useFeatureTourStore} from 'stores/useFeatureTourStore';
import {ROUTES} from 'shared/Routes';
import {LINKS, QUERY_KEYS, SCROLL_EVENTS} from 'constants/AppConstants';
import {ProfileMenu} from './profile-menu/ProfileMenu';
import {PageNavigationDropdown} from './PageNavigationDropdown';
import {DigestModal} from 'components/DigestModal';
import {TopBarData} from 'models/TopBarData';
import {PageParameters} from 'models/types/PageParameters';
import {CustomPageData} from 'models/CustomPageData';
import {GroupedActionItem} from 'models/topbar-action-item/GroupedActionItem';
import {NotificationResponse} from 'models/NotificationResponse';
import {NotificationType} from 'models/enums/NotificationType';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {DigestShareData} from 'models/DigestShareData';
import {DigestResponseContent} from 'models/DigestResponseContent';
import {ProfileFieldEditType} from 'models/enums/ProfileFieldEditType';
import {ProfileFieldModalStatus} from 'models/ProfileFieldModalStatus';

const ActionItems = lazy(() => import('./action-items/ActionItems').then(module => ({default: module.ActionItems})));
const OnboardingProfileQuestions = lazy(() => import('../join-community/OnboardingProfileQuestions').then(module => ({default: module.OnboardingProfileQuestions})));
const EditProfileQuestions = lazy(() => import('../profile/sidebar/EditProfileQuestions').then(module => ({default: module.EditProfileQuestions})));
const TopbarNotifications = lazy(() => import('../notification/TopbarNotifications').then(module => ({default: module.TopbarNotifications})));
const CustomPageMenu = lazy(() => import('./CustomPageMenu').then(module => ({default: module.CustomPageMenu})));
const LabelsMenu = lazy(() => import('./LabelsMenu').then(module => ({default: module.LabelsMenu})));
const Messages = lazy(() => import('@ideascale/commons/dist/components/messages/Messages').then(module => ({default: module.Messages})));
const MessageModal = lazy(() => import('@ideascale/commons/dist/components/messages/MessageModal').then(module => ({default: module.MessageModal})));

type TopBarProps = {
    logo: string;
    topBarData: TopBarData;
    topBarSvgIcons: string;
    actor: Actor;
    localizer: Localizer;
    currentMenuPath: string | RegExp;
    fetchTopBarCampaigns: (pageParameters: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
    fetchTopBarCustomPages: (pageParameters: PageParameters) => Promise<PagedResponseContent<CustomPageData>>;
    fetchTopBarLabels: (pageParameters: PageParameters) => Promise<PagedResponseContent<LabelData>>;
    fetchMonthlyModeratorDigest: (pageParameters: PageParameters, year: string, month: string) => Promise<DigestResponseContent>;
    fetchWeeklyModeratorDigest: (pageParameters: PageParameters, year: string, weekOfYear: string) => Promise<DigestResponseContent>;
    fetchCampaignPerformanceMonthlyModeratorDigest: (campaignId: number, memberId: number, year: string, month: string) => Promise<DigestShareData>;
    fetchCampaignPerformanceWeeklyModeratorDigest: (campaignId: number, memberId: number, year: string, weekOfYear: string) => Promise<DigestShareData>;
    fetchTopBarMessages: () => Promise<MessageData>;
    fetchTopbarActionItems: () => Promise<GroupedActionItem[]>;
    fetchTopbarNotifications: (groupId: number) => Promise<NotificationResponse>;
    updateThemePreference: (theme: Theme) => Promise<void>;
    fetchRecipients(pageParameters: PageParameters): Promise<PagedResponseContent<Member>>;
    sudoAdmin: () => Promise<void>;
    deSudoAdmin: () => Promise<void>;
    isFetchingData: boolean;
    fetchClassifications: () => Promise<ClassificationsHolder>;
};

export const TopBar = (props: TopBarProps) => {
    const {
        logo,
        topBarData,
        topBarSvgIcons,
        actor,
        localizer,
        currentMenuPath,
        fetchTopBarCampaigns,
        fetchTopBarCustomPages,
        fetchTopBarLabels,
        fetchTopBarMessages,
        fetchTopbarActionItems,
        fetchTopbarNotifications,
        fetchRecipients,
        sudoAdmin,
        deSudoAdmin,
        updateThemePreference,
        fetchClassifications
    } = props;

    const {
        authentication,
        communityConfig: {
            id,
            customLandingPageEnabled,
            classificationEnabled,
            name: communityName,
            maxFileSizeLimit,
            offensiveEmojis,
            privateMessageMaxRecipients,
            privateMessageMaxSubjectLength,
            privateMessageEnabled
        }
    } = useAppContext();
    const navigate = useNavigate();
    const {
        fetchProfileFields,
        fetchIncompleteVerifiableProfileFields,
        fetchPendingVerifiableFields,
        updateProfileFields,
        fetchProfileFieldModalStatus
    } = useProfileFieldsService();
    const cookieConsentConfig = useCookieConsentConfiguration();
    const {onMemberSendMessages} = useMessageService();
    const {tempImageUpload} = useFileUploadService();
    const urlQuery = useUrlQuery();
    const isMounted = useIsMounted();
    const {isHtmlOrJavascriptInjection} = useHtmlOrJavascriptInjectionAlert();
    const [showComposeMessage, toggleComposeMessage] = useToggle(false);
    const [showDigestNotificationModal, toggleDigestNotificationModal] = useToggle(false);
    const [profileFieldsOpen, toggleProfileFieldsOpen] = useToggle(false);
    const [verifiableFieldsOpen, toggleVerifiableFieldsOpen] = useToggle(false);
    const [incompleteRequiredFieldsOpen, toggleIncompleteRequiredFieldsOpen] = useToggle(false);
    const [pendingVerifiableFieldsOpen, togglePendingVerifiableFieldsOpen] = useToggle(false);
    const [joinCommunityProfileFieldModalOpen, toggleJoinCommunityProfileFieldModal] = useToggle(false);
    const [showNotifications, toggleNotifications] = useToggle(false);
    const [showCustomPages, toggleCustomPages] = useToggle(false);
    const [showLabels, toggleLabels] = useToggle(false);
    const [showAlerts, toggleAlerts] = useToggle(false);
    const [showMessages, toggleMessages] = useToggle(false);

    const {setOnboardInProgress} = useFeatureTourStore();

    const {
        onSubmitIdeaButtonClick,
        isNotIdeaSubmissionAllowed
    } = useIdeaSubmissionActions();
    const currentProfileId = useProfilePageParams();
    const joinCommunityRouteMatch = useRoutesMatch(ROUTES.JOIN_COMMUNITY);
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const isFirstRenderedRef = useRef(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [digestNotificationData, setDigestNotificationData] = useState({
        year: '',
        notificationType: NotificationType.MONTHLY_MODERATOR_DIGEST,
        month: '',
        week: '',
        campaignId: 0,
        memberId: 0
    });

    const {
        data: profileFieldStatus,
        refetch: reFetchProfileFieldStatus,
        isSuccess,
    } = useQuery<ProfileFieldModalStatus>([QUERY_KEYS.PROFILE_FIELD_MODAL_STATUS, id], () => fetchProfileFieldModalStatus(), {
        enabled: (
            authentication.isAuthenticated() &&
            authentication.community.isNotAccessLocked() &&
            authentication.community.isNotAccessSandBoxed() &&
            !authentication.isJoinCommunityAvailable() &&
            !authentication.isCommunityTosAcceptanceRequired()
        )
    });

    const toggleDigestModal = (year: string, notificationType: NotificationType, month?: string, week?: string, campaignId?: number, memberId?: number) => {
        setDigestNotificationData({
            year: year,
            notificationType: notificationType,
            month: month || '',
            week: week || '',
            campaignId: campaignId || 0,
            memberId: memberId || 0
        });
        toggleDigestNotificationModal();
    };

    const navigateToSearchPage = useCallback((search: string) => {
        if (!isHtmlOrJavascriptInjection(search)) {
            const newUrlQuery = new URLSearchParams(urlQuery);

            if (search) {
                newUrlQuery.set(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY, search);
            } else {
                newUrlQuery.delete(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY);
            }

            eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP);
            navigate({
                pathname: appLinks.search(),
                search: newUrlQuery.toString()
            }, {
                state: {searchApply: true}
            });
        }
    }, [urlQuery, isHtmlOrJavascriptInjection, navigate]);

    const onTopBarLogoClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (authentication.isCommunityTosAcceptanceRequired()) {
            event.preventDefault();
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.community_tos_not_accepted')));
        }
        return true;
    };

    const renderTopBarLogo = () => {
        if (topBarData.topbarLogoUrl) {
            return <a className="navbar-brand logo d-none d-lg-flex text-lowercase ms-3 me-4"
                      href={topBarData.topbarLogoUrl} onClick={onTopBarLogoClick}>
                <TobBarLogo
                    logo={logo}
                    alt={topBarData.altText}/>
            </a>;
        } else if (authentication.community?.landingPagePublished) {
            return <Link className="navbar-brand logo d-none d-lg-flex text-lowercase ms-3 me-4"
                         to={ROUTES.LANDING} onClick={onTopBarLogoClick}>
                <TobBarLogo
                    logo={logo}
                    alt={topBarData.altText}/>
            </Link>;
        } else {
            return <a className="navbar-brand logo d-none d-lg-flex text-lowercase ms-3 me-4"
                      href={customLandingPageEnabled ? LINKS.INDEX : process.env.PUBLIC_URL}
                      onClick={onTopBarLogoClick}>
                <TobBarLogo
                    logo={logo}
                    alt={topBarData.altText}/>
            </a>;
        }
    };

    useEffect(() => {
        if (isMounted() && !isFirstRenderedRef.current && urlQuery.has(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY)) {
            const inputValue = urlQuery.get(ROUTES.QUERY_PARAMS.ADVANCED_SEARCH_QUERY) || '';
            isFirstRenderedRef.current = true;
            setSearchInputValue(inputValue);
            navigateToSearchPage(inputValue);
        }
    }, [isMounted, navigateToSearchPage, urlQuery]);

    const goToSearchPage = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigateToSearchPage(searchInputValue);
    };

    useEffect(() => {
        if (isSuccess) {
            const hasEditProfileFieldParam = urlQuery?.get(ROUTES.QUERY_PARAMS.PROFILE_FIELD_EDIT) === ProfileFieldEditType.PROFILE_FIELDS;
            const hasVerifiableEditProfileFieldParam = urlQuery?.get(ROUTES.QUERY_PARAMS.PROFILE_FIELD_EDIT) === ProfileFieldEditType.VERIFIABLE_PROFILE_FIELDS;

            if (profileFieldStatus?.nameAndProfileFields) {
                if (actor.hasPendingMembership()) {
                    if (authentication.community.isNotPublicCommunity()) {
                        navigate(appLinks.joinCommunity());
                    } else if (authentication.community.isPubicCommunity() && joinCommunityRouteMatch?.pathname) {
                        navigate(appLinks.home(), {replace: true});
                    }
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.member_awaiting_approval')));
                } else {
                    if (joinCommunityRouteMatch?.pathname) {
                        navigate(appLinks.home(), {replace: true});
                    }
                    setOnboardInProgress(true);
                    toggleJoinCommunityProfileFieldModal(true);
                }
            } else if (hasEditProfileFieldParam) {
                toggleProfileFieldsOpen(true);
            } else if (profileFieldStatus?.incompleteRequiredFields) {
                toggleIncompleteRequiredFieldsOpen(true);
            } else if (profileFieldStatus?.pendingVerifiableFields || hasVerifiableEditProfileFieldParam) {
                togglePendingVerifiableFieldsOpen(true);
            }
        }
    }, [actor, authentication.community, isSuccess, joinCommunityRouteMatch?.pathname, localizer, navigate, profileFieldStatus?.incompleteRequiredFields, profileFieldStatus?.nameAndProfileFields, profileFieldStatus?.pendingVerifiableFields, setOnboardInProgress, toggleIncompleteRequiredFieldsOpen, toggleJoinCommunityProfileFieldModal, togglePendingVerifiableFieldsOpen, toggleProfileFieldsOpen, urlQuery]);

    return (
        <Fragment>
            <nav className="navbar navbar-expand navbar-top" aria-label={localizer.msg('topbar.community-nav-label')}>
                <div className="collapse navbar-collapse">
                    {renderTopBarLogo()}

                    <div className="w-100 ms-2 me-3 d-flex justify-content-between align-items-center">
                        {
                            topBarData.campaignsAccessible
                                ? (
                                    <PageNavigationDropdown localizer={localizer}
                                                            fetchTopBarCampaigns={fetchTopBarCampaigns}/>
                                )
                                : null
                        }
                        {
                            topBarData.searchAccessible
                                ? (
                                    <form className={`d-none d-lg-flex search-input-container`}
                                          role="search"
                                          onSubmit={goToSearchPage}
                                          aria-label={localizer.msg('common.search')}>
                                        <div className="input-group">
                                            <label htmlFor="search" className="sr-only">
                                                {localizer.msg('common.search')}
                                            </label>
                                            <input className="form-control search-input rounded-start"
                                                   ref={searchInputRef}
                                                   type="text"
                                                   id="search"
                                                   value={searchInputValue}
                                                   onChange={event => {
                                                       event.preventDefault();
                                                       setSearchInputValue(event.target.value);
                                                   }}
                                                   name="search"
                                                   placeholder={localizer.msg('search.placeholder.search')}/>
                                            <div className="input-group-append">
                                                <Button color="primary"
                                                        className="px-1 btn-search-with-icon"
                                                        title={localizer.msg('common.search')}>
                                                    <Icon name="magnifying-glass-left"
                                                          iconSpritePath={topBarSvgIcons} width={18} height={18}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )
                                : null
                        }
                    </div>
                    {
                        authentication.actor.isAnonymous()
                            ?
                            <ul className="navbar-nav ms-auto anonymous-nav">
                                <li className="nav-item border-start-0 d-lg-none">
                                    <Button color="default"
                                            className="search-icon"
                                            title={localizer.msg('common.search')}
                                            onClick={goToSearchPage}>
                                        <Icon name="magnifying-glass-left" iconSpritePath={topBarSvgIcons} width={18}
                                              height={18}/>
                                    </Button>
                                </li>
                                {
                                    authentication.community.signupUrl
                                        ? (
                                            <li className="nav-item">
                                                <a className="nav-link community-register-link px-3 fw-bold text-truncate"
                                                   data-test-element-id="community-signup-link"
                                                   href={authentication.community.signupUrl}>{localizer.msg('topbar.register')}</a>
                                            </li>
                                        )
                                        : null
                                }
                                <li className="nav-item">
                                    <Button color="link"
                                            className="nav-link community-login-link px-3 fw-bold text-truncate"
                                            data-test-element-id="community-login-link"
                                            title={localizer.msg('topbar.login')}
                                            onClick={() => postLoginRequest({
                                                url: authentication.community.loginUrl,
                                                explicitLogin: true
                                            })}>{localizer.msg('topbar.login')}</Button>
                                </li>
                            </ul>
                            :
                            <ul className="navbar-nav ms-auto"
                                aria-label={localizer.msg('topbar.topbar-navigation-items')}>
                                {
                                    topBarData.customPagesAccessible && (
                                        <li className={`nav-item d-none d-lg-inline-block tour-lucido_explore`}>
                                            <SimpleDropdown open={showCustomPages}
                                                            dropdownClassName="badge-icon-container"
                                                            toggle={toggleCustomPages}
                                                            toggleClassName="btn-dropdown-menu"
                                                            toggleContent={
                                                                <span className="pointer-events-none">
                                                                    <Icon name="compass"
                                                                          iconSpritePath={topBarSvgIcons}
                                                                          width={20} height={20}/>
                                                                    {
                                                                        topBarData.unvisitedPageCount > 0
                                                                            ? <span className="badge badge-alert dot"
                                                                                    aria-hidden="true"></span>
                                                                            : null
                                                                    }
                                                                </span>
                                                            }
                                                            toggleTitle={topBarData.unvisitedPageCount > 0
                                                                ? localizer.msg('topbar.custom-page.explore-custom-pages-new')
                                                                : localizer.msg('topbar.custom-page.explore-custom-pages')}
                                                            toggleTestId="btn-toggle-custom-pages"
                                                            menuAriaLabel={localizer.msg('topbar.custom-page.title')}
                                                            menuClassName="dropdown-menu py-2"
                                                            slide="right">
                                                <Suspense fallback={<div className="p-3 bg-transparent dropdown-item">
                                                    <ParagraphSkeleton rows={4}/>
                                                </div>}>
                                                    <CustomPageMenu localizer={localizer}
                                                                    focusId="topbar-custom-pages-menu"
                                                                    fetchTopBarCustomPages={fetchTopBarCustomPages}
                                                                    createCustomPageEnabled={topBarData.createCustomPageEnabled}/>
                                                </Suspense>
                                            </SimpleDropdown>
                                        </li>
                                    )
                                }
                                {
                                    topBarData.labelsAccessible && (
                                        <li className={`nav-item border-start-0 d-none d-lg-inline-block tour-lucido_label`}>
                                            <SimpleDropdown open={showLabels}
                                                            toggle={toggleLabels}
                                                            toggleOnRouteChange={true}
                                                            toggleClassName="btn-dropdown-menu"
                                                            toggleContent={
                                                                <span className="pointer-events-none">
                                                                    <Icon name="flag-waving"
                                                                          iconSpritePath={topBarSvgIcons}
                                                                          width={19} height={19}/>
                                                                </span>
                                                            }
                                                            toggleTitle={localizer.msg('topbar.label.title')}
                                                            toggleTestId="btn-toggle-labels"
                                                            menuAriaLabel={localizer.msg('topbar.label.title')}
                                                            menuClassName="labels-container py-2 dropdown-menu"
                                                            slide="right">
                                                <Suspense>
                                                    <LabelsMenu localizer={localizer} focusId="topbar-labels-menu"
                                                                fetchTopBarLabels={fetchTopBarLabels}/>
                                                </Suspense>
                                            </SimpleDropdown>
                                        </li>
                                    )
                                }
                                {
                                    topBarData.searchAccessible &&
                                    <li className={`nav-item border-start-0 d-lg-none`}>
                                        <Button color="default"
                                                className="search-icon"
                                                title={localizer.msg('common.search')}
                                                onClick={goToSearchPage}>
                                            <Icon name="magnifying-glass-left" iconSpritePath={topBarSvgIcons}
                                                  width={18}
                                                  height={18}/>
                                        </Button>
                                    </li>
                                }
                                {
                                    topBarData.actionItemsAccessible && (
                                        <li className="nav-item">
                                            <SimpleDropdown open={showAlerts}
                                                            toggle={toggleAlerts}
                                                            dropdownClassName="badge-icon-container"
                                                            toggleOnRouteChange={true}
                                                            toggleClassName="btn-dropdown-menu"
                                                            toggleContent={
                                                                <span
                                                                    className="pointer-events-none">
                                                                    <Icon name="square-check-rounded"
                                                                          iconSpritePath={topBarSvgIcons} width={18}
                                                                          height={18}/>
                                                                    {
                                                                        topBarData.actionItemsAvailable
                                                                            ?
                                                                            <span className="badge badge-alert dot"
                                                                                  aria-hidden="true"></span>
                                                                            : null
                                                                    }
                                                                </span>
                                                            }
                                                            toggleTitle={topBarData.actionItemsAvailable
                                                                ? localizer.msg('topbar.action-items.title-new')
                                                                : localizer.msg('topbar.action-items.title')}
                                                            toggleTestId="btn-toggle-alerts"
                                                            menuAriaLabel={localizer.msg('topbar.action-items.pending-actions')}
                                                            menuClassName="action-items-container rounded"
                                                            slide="right">
                                                <div className="my-3">
                                                    <Suspense fallback={<div className="m-3"><ParagraphSkeleton
                                                        rows={4}/></div>}>
                                                        <ActionItems localizer={localizer}
                                                                     fetchTopbarActionItems={fetchTopbarActionItems}
                                                                     toggleProfileQuestionsModal={toggleProfileFieldsOpen}
                                                                     toggleVerifiableFieldsOpen={toggleVerifiableFieldsOpen}/>
                                                    </Suspense>
                                                </div>

                                            </SimpleDropdown>
                                        </li>
                                    )
                                }
                                {
                                    topBarData.notificationsAccessible && (
                                        <li className="nav-item border-start-0">
                                            <SimpleDropdown open={showNotifications}
                                                            toggle={toggleNotifications}
                                                            dropdownClassName="badge-icon-container"
                                                            slide="right"
                                                            toggleClassName="btn-dropdown-menu"
                                                            toggleOnRouteChange={true}
                                                            toggleTestId="btn-toggle-notifications"
                                                            menuAriaLabel={localizer.msg('topbar.notifications.title')}
                                                            toggleContent={
                                                                <span className="pointer-events-none">
                                                                    <Icon name="bell"
                                                                          iconSpritePath={topBarSvgIcons} width={20}
                                                                          height={20}/>
                                                                    {
                                                                        topBarData.unseenNotificationCount > 0
                                                                            ? <span
                                                                                className="badge badge-alert numeric fw-bold d-flex align-items-center justify-content-center">
                                                                                     {truncateTo99(topBarData.unseenNotificationCount)}
                                                                                 </span>
                                                                            : null
                                                                    }
                                                                </span>
                                                            }
                                                            toggleTitle={topBarData.unseenNotificationCount > 0
                                                                ? localizer.msg('topbar.notifications.title-new', {count: topBarData.unseenNotificationCount})
                                                                : localizer.msg('topbar.notifications.title')}
                                                            menuClassName="notifications-container">
                                                <Suspense
                                                    fallback={
                                                        <div className="p-3">
                                                            <ParagraphSkeleton rows={4}/>
                                                        </div>}>
                                                    <TopbarNotifications
                                                        fetchTopbarNotifications={fetchTopbarNotifications}
                                                        getDataForDigestModal={toggleDigestModal}/>
                                                </Suspense>
                                            </SimpleDropdown>
                                        </li>
                                    )
                                }
                                {
                                    privateMessageEnabled && (
                                        <li className="nav-item border-start-0">
                                            <SimpleDropdown open={showMessages}
                                                            toggle={toggleMessages}
                                                            toggleOnRouteChange={true}
                                                            dropdownClassName="badge-icon-container"
                                                            menuClassName="messages-container"
                                                            slide="right"
                                                            toggleTestId="btn-toggle-messages"
                                                            toggleClassName="btn-dropdown-menu"
                                                            toggleContent={
                                                                <span className="pointer-events-none">
                                                                    <Icon name="envelope-alt"
                                                                          iconSpritePath={topBarSvgIcons}
                                                                          width={20} height={16}/>
                                                                    {
                                                                        topBarData.unseenMessageCount > 0
                                                                            ? <span aria-hidden="true"
                                                                                    className="badge badge-alert numeric fw-bold d-flex align-items-center justify-content-center">
                                                                                {truncateTo99(topBarData.unseenMessageCount)}
                                                                             </span>
                                                                            : ''
                                                                    }
                                                                 </span>
                                                            }
                                                            toggleTitle={topBarData.unseenMessageCount > 0
                                                                ? localizer.msg('topbar.messages.messages-new', {count: topBarData.unseenMessageCount})
                                                                : localizer.msg('topbar.messages.messages')}
                                                            menuAriaLabel={localizer.msg('topbar.messages.messages')}>
                                                <div className="m-3">
                                                    <p className="font-size-md fw-bold h5 mb-3">{localizer.msg('topbar.messages.messages')}</p>
                                                    <Suspense fallback={<div className="p-1">
                                                        <ParagraphSkeleton rows={4}/>
                                                    </div>}>
                                                        <Messages
                                                            focusId="topbar-messages-menu"
                                                            toggle={toggleComposeMessage}
                                                            localizer={localizer}
                                                            fetchTopBarMessages={fetchTopBarMessages}
                                                            cookieConsentConfig={cookieConsentConfig}
                                                            actorId={actor.id}
                                                            classificationEnabled={classificationEnabled}/>
                                                    </Suspense>
                                                </div>
                                            </SimpleDropdown>
                                        </li>
                                    )
                                }
                                <li className="nav-item d-lg-block border-start-0 tour-lucido_idea_submission">
                                    <Button color="default"
                                            className="btn-topbar-submit-idea"
                                            onClick={onSubmitIdeaButtonClick}
                                            title={localizer.msg(isNotIdeaSubmissionAllowed() ? 'common.submit-idea-disabled' : 'common.submit-idea')}
                                            disabled={isNotIdeaSubmissionAllowed()}
                                            data-test-element-id="btn-toggle-submit-idea">
                                        <Icon name="plus-solid-circle" iconSpritePath={topBarSvgIcons} width={30}
                                              height={30}/>
                                    </Button>
                                </li>
                                <li className="nav-item" data-member-id={actor.id}>
                                    <ProfileMenu
                                        currentMenuPath={currentMenuPath}
                                        topBarSvgIcons={topBarSvgIcons}
                                        localizer={localizer}
                                        name={actor.name}
                                        username={actor.username}
                                        communityLogo={logo}
                                        altText={topBarData.altText}
                                        node={topBarData.node}
                                        profileMenu={topBarData.profileMenuItems}
                                        privilegeStateMenu={topBarData.privilegeStateMenuItems}
                                        sudoMenu={topBarData.sudoMenuItems}
                                        communityMenu={topBarData.communityMenuItems}
                                        avatar={actor.avatar}
                                        newFeaturesAvailable={topBarData.newFeaturesAvailable}
                                        debugLocalizerMenuVisible={topBarData.debugLocalizerMenuVisible}
                                        whiteboardAccessible={topBarData.whiteboardAccessible}
                                        createCustomPageEnabled={topBarData.createCustomPageEnabled}
                                        fetchTopBarCustomPages={fetchTopBarCustomPages}
                                        fetchTopBarLabels={fetchTopBarLabels}
                                        sudoAdmin={sudoAdmin}
                                        deSudoAdmin={deSudoAdmin}
                                        sudoer={topBarData.sudoer}
                                        updateThemePreference={updateThemePreference}
                                    />
                                </li>
                            </ul>
                    }
                </div>
            </nav>

            {
                showComposeMessage &&
                <Suspense>
                    <MessageModal fetchClassifications={fetchClassifications}
                                  open={showComposeMessage}
                                  toggle={toggleComposeMessage}
                                  fetchRecipients={fetchRecipients}
                                  localizer={localizer}
                                  onMemberSendMessages={onMemberSendMessages}
                                  tempImageUpload={tempImageUpload}
                                  communityName={communityName}
                                  offensiveEmojis={offensiveEmojis}
                                  maxFileSizeLimit={maxFileSizeLimit}
                                  maxSubjectLength={privateMessageMaxSubjectLength}
                                  classificationEnabled={classificationEnabled}
                                  privateMessageMaxRecipients={privateMessageMaxRecipients}/>
                </Suspense>
            }

            <DigestModal showDigestNotificationModal={showDigestNotificationModal}
                         digestNotificationData={digestNotificationData}
                         toggleDigestNotificationModal={toggleDigestNotificationModal}/>

            {
                profileFieldsOpen &&
                <Suspense>
                    <EditProfileQuestions
                        isOpen={profileFieldsOpen}
                        toggle={() => toggleProfileFieldsOpen(false)}
                        localizer={localizer}
                        fetchProfileFields={fetchProfileFields}
                        updateProfileFields={updateProfileFields}
                        verified={false}
                        memberId={currentProfileId}
                        reFetchProfileFieldModalStatus={reFetchProfileFieldStatus}
                    />
                </Suspense>
            }
            {
                verifiableFieldsOpen &&
                <Suspense>
                    <EditProfileQuestions
                        isOpen={verifiableFieldsOpen}
                        toggle={() => toggleVerifiableFieldsOpen(false)}
                        localizer={localizer}
                        fetchProfileFields={fetchIncompleteVerifiableProfileFields}
                        updateProfileFields={updateProfileFields}
                        verified={true}
                        memberId={currentProfileId}
                        reFetchProfileFieldModalStatus={reFetchProfileFieldStatus}
                    />
                </Suspense>
            }
            {
                incompleteRequiredFieldsOpen &&
                <Suspense>
                    <EditProfileQuestions
                        isOpen={incompleteRequiredFieldsOpen}
                        toggle={() => toggleIncompleteRequiredFieldsOpen(false)}
                        localizer={localizer}
                        fetchProfileFields={fetchProfileFields}
                        updateProfileFields={updateProfileFields}
                        verified={false}
                        closable={false}
                        memberId={currentProfileId}
                        editMode={'incompleteRequiredFields'}
                        reFetchProfileFieldModalStatus={reFetchProfileFieldStatus}
                    />
                </Suspense>
            }
            {
                pendingVerifiableFieldsOpen &&
                <Suspense>
                    <EditProfileQuestions
                        isOpen={pendingVerifiableFieldsOpen}
                        toggle={() => togglePendingVerifiableFieldsOpen(false)}
                        localizer={localizer}
                        fetchProfileFields={fetchPendingVerifiableFields}
                        updateProfileFields={updateProfileFields}
                        verified={true}
                        memberId={currentProfileId}
                        editMode={'pendingVerifiableFields'}
                        reFetchProfileFieldModalStatus={reFetchProfileFieldStatus}
                    />
                </Suspense>
            }

            {
                joinCommunityProfileFieldModalOpen
                    ? (
                        <Suspense>
                            <OnboardingProfileQuestions
                                isOpen={joinCommunityProfileFieldModalOpen}
                                toggle={toggleJoinCommunityProfileFieldModal}
                                reFetchProfileFieldModalStatus={reFetchProfileFieldStatus}/>
                        </Suspense>
                    )
                    : null
            }
        </Fragment>
    );
};
