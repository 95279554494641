import React, {Dispatch, memo, SetStateAction, useCallback, useMemo, useState} from 'react';
import {useIdeaStageChange} from 'hooks/useIdeaStageChange';
import {AlertEvent, AlertType, buildAlertEventData} from '@ideascale/commons/dist/utils/AlertEvent';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useAppContext} from './AppContext';
import {useIdeasContext} from './IdeasContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useVoteAction} from 'hooks/useVoteAction';
import {useCampaignChange} from 'hooks/useCampaignChange';
import {useKudoGive} from 'hooks/useKudoGive';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {useEditIdeaActions} from 'hooks/useEditIdeaActions';
import {useAssignOwners} from 'hooks/useAssignOwners';
import {useIdeaDelete} from 'hooks/useIdeaDelete';
import {useIdeaSubscription} from 'hooks/useIdeaSubscription';
import {useIdeaAuthorFollow} from 'hooks/useIdeaAuthorFollow';
import {useIdeaCommentEnabled} from 'hooks/useIdeaCommentEnabled';
import {useIdeaPinToLandingPage} from 'hooks/useIdeaPinToLanding';
import {SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import {OrderBy} from 'models/enums/OrderBy';
import {VoteType} from 'models/enums/VoteType';
import {VoteParameters} from 'models/VoteParameters';
import {VoteRetractType} from 'models/enums/VoteRetractType';
import {ChangeCampaignParams} from 'models/types/ChangeCampaignParams';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {LabelActionParams} from 'models/types/LabelActionParams';
import {AssignOwnersParams} from 'models/types/AssignOwnersParams';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {ModifiedIdeaDetail} from 'models/ModifiedIdeaDetail';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaOwnerAssignmentResponse} from 'models/IdeaOwnerAssignmentResponse';

export type IdeaListState = {
    onToggleVote: (ideaId: number, voteType: VoteType, voteParameters: VoteParameters) => Promise<boolean>;
    onTogglePinToLandingPage: (ideaId: number, pinned: boolean) => void;
    orderBy: OrderBy;
    setOrderBy: Dispatch<SetStateAction<OrderBy>>;
    changeIdeaStage: (ideaId: number, stageId: number) => void;
    deleteIdea: (ideaId: number) => void;
    editIdea: (ideaId: number, submissionRequest: IdeaSubmissionRequest) => Promise<ModifiedIdeaDetail>;
    changeCampaign: (changeCampaignParams: ChangeCampaignParams) => Promise<IdeaSummary | undefined>;
    giveKudo: (ideaId: number) => Promise<KudoActivitySummary>;
    updateLabels: (labelActionParams: LabelActionParams) => Promise<void>;
    reportAbuse: (ideaId: number) => Promise<void>;
    reportDuplicate: (ideaId: number) => Promise<void>;
    assignOwners: (assignOwnersParams: AssignOwnersParams) => Promise<IdeaOwnerAssignmentResponse>;
    updateIdeaSubscription: (ideaId: number, subscribed: boolean) => void;
    updateIdeaAuthorFollow: (ideaId: number, followed: boolean) => void;
    updateIdeaCommentingEnabled: (ideaId: number, commentEnabled: boolean) => void;
}

const defaultValues: IdeaListState = {
    onToggleVote: () => Promise.resolve(true),
    onTogglePinToLandingPage: () => null,
    orderBy: OrderBy.DESC,
    setOrderBy: () => null,
    changeIdeaStage: () => null,
    deleteIdea: () => null,
    editIdea: () => new Promise(resolve => resolve),
    changeCampaign: () => new Promise(resolve => resolve),
    giveKudo: () => new Promise(resolve => resolve),
    updateLabels: () => new Promise(resolve => resolve),
    reportAbuse: () => new Promise(resolve => resolve),
    reportDuplicate: () => new Promise(resolve => resolve),
    assignOwners: () => new Promise(resolve => resolve),
    updateIdeaSubscription: () => null,
    updateIdeaAuthorFollow: () => null,
    updateIdeaCommentingEnabled: () => null
};

const ctx = React.createContext<IdeaListState>(defaultValues);

export const useIdeaListContext = () => {
    return React.useContext<IdeaListState>(ctx);
};

type IdeaListContextType = {
    children: React.ReactNode;
}

export const IdeaListContextProvider = memo(({children}: IdeaListContextType) => {
    const {authentication, communityConfig: {ssoEnabled}} = useAppContext();
    const {ideaListFilterQueryKey} = useIdeasContext();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [orderBy, setOrderBy] = useState(OrderBy.DESC);
    const {ideaListStageChangeMutation: {mutateAsync: changeStageAsync}} = useIdeaStageChange(ideaListFilterQueryKey);
    const {ideaListIdeaSubscriptionMutation} = useIdeaSubscription(ideaListFilterQueryKey);
    const {ideaListIdeaAuthorFollowMutation} = useIdeaAuthorFollow(ideaListFilterQueryKey);
    const {ideaListIdeaPinToLandingPageMutation} = useIdeaPinToLandingPage(ideaListFilterQueryKey);
    const {ideaListIdeaCommentEnabledMutation} = useIdeaCommentEnabled(ideaListFilterQueryKey);
    const {ideaListAssignOwnersMutation} = useAssignOwners(ideaListFilterQueryKey);
    const {ideaListDeleteIdeaMutation: deleteIdeaMutation} = useIdeaDelete(ideaListFilterQueryKey);
    const {ideaListVoteMutation: {mutateAsync: mutateVoteAsync}} = useVoteAction(ideaListFilterQueryKey);
    const {ideaListCampaignChangeMutation: {mutateAsync: changeCampaignAsync}} = useCampaignChange(ideaListFilterQueryKey);
    const {ideaListGiveKudosMutation: {mutateAsync: mutateKudosAsync}} = useKudoGive(ideaListFilterQueryKey);
    const {
        ideaListUpdateLabelsMutation,
        ideaListReportingMutation
    } = useIdeaLabels(ideaListFilterQueryKey);
    const {ideaListEditIdeaMutation: {mutateAsync: editIdeaAsync}} = useEditIdeaActions(ideaListFilterQueryKey);

    const changeIdeaStage = useCallback(async (ideaId: number, stageId: number) => {
        await changeStageAsync({ideaId, stageId});
    }, [changeStageAsync]);

    const deleteIdea = useCallback(async (ideaId: number) => {
        await deleteIdeaMutation.mutateAsync(ideaId);
    }, [deleteIdeaMutation]);

    const editIdea = useCallback(async (ideaId: number, submissionRequest: IdeaSubmissionRequest) => {
        return await editIdeaAsync({ideaId, submissionRequest});
    }, [editIdeaAsync]);

    const onToggleVote = useCallback(async (ideaId: number, voteType: VoteType, voteParameters: VoteParameters) => {
        try {
            if (authentication.actor.isAnonymous() && !ssoEnabled) {
                eventDispatcher.dispatch(SHOW_LOGIN_PROMPT, localizer.msg('login-prompt.message.ideate'));
            } else {
                const response = await mutateVoteAsync({ideaId, voteType, voteParameters});
                let message = localizer.msg('stage.ideate.alert.vote.success');
                if (response.retractType === VoteRetractType.POSITIVE) {
                    message = localizer.msg('stage.ideate.alert.vote.positive-retract');
                } else if (response.retractType === VoteRetractType.NEGATIVE) {
                    message = localizer.msg('stage.ideate.alert.vote.negative-retract');
                } else if (response.retractType === VoteRetractType.RETRACT) {
                    message = localizer.msg('stage.ideate.alert.vote.retracted');
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, message));
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
        return true;
    }, [authentication.actor, handleErrorResponse, localizer, mutateVoteAsync, ssoEnabled]);

    const changeCampaign = useCallback(async (changeCampaignParams: ChangeCampaignParams) => {
        return await changeCampaignAsync(changeCampaignParams);
    }, [changeCampaignAsync]);

    const updateLabels = useCallback(async (labelActionParams: LabelActionParams) => {
        await ideaListUpdateLabelsMutation.mutateAsync(labelActionParams);
    }, [ideaListUpdateLabelsMutation]);

    const reportAbuse = useCallback(async (ideaId: number) => {
        await ideaListReportingMutation.mutateAsync({ideaId, isAbuseReporting: true});
    }, [ideaListReportingMutation]);

    const reportDuplicate = useCallback(async (ideaId: number) => {
        await ideaListReportingMutation.mutateAsync({ideaId, isAbuseReporting: false});
    }, [ideaListReportingMutation]);

    const assignOwners = useCallback(async (assignOwnersParams: AssignOwnersParams) => {
        return await ideaListAssignOwnersMutation.mutateAsync(assignOwnersParams);
    }, [ideaListAssignOwnersMutation]);

    const updateIdeaSubscription = useCallback(async (ideaId: number, subscribed: boolean) => {
        await ideaListIdeaSubscriptionMutation(ideaId, subscribed);
    }, [ideaListIdeaSubscriptionMutation]);

    const updateIdeaAuthorFollow = useCallback(async (ideaId: number, followed: boolean) => {
        await ideaListIdeaAuthorFollowMutation(ideaId, followed);
    }, [ideaListIdeaAuthorFollowMutation]);

    const onTogglePinToLandingPage = useCallback(async (ideaId: number, pinned: boolean) => {
        await ideaListIdeaPinToLandingPageMutation(ideaId, pinned);
    }, [ideaListIdeaPinToLandingPageMutation]);

    const updateIdeaCommentingEnabled = useCallback(async (ideaId: number, commentEnabled: boolean) => {
        await ideaListIdeaCommentEnabledMutation(ideaId, commentEnabled);
    }, [ideaListIdeaCommentEnabledMutation]);

    const contextVales = useMemo(() => {
        return {
            orderBy,
            setOrderBy,
            changeIdeaStage,
            deleteIdea,
            editIdea,
            onToggleVote,
            onTogglePinToLandingPage,
            changeCampaign,
            giveKudo: mutateKudosAsync,
            updateLabels,
            reportAbuse,
            reportDuplicate,
            assignOwners,
            updateIdeaSubscription,
            updateIdeaAuthorFollow,
            updateIdeaCommentingEnabled
        };
    }, [assignOwners, changeCampaign, changeIdeaStage, deleteIdea, editIdea, mutateKudosAsync, onTogglePinToLandingPage, onToggleVote, orderBy, reportAbuse, reportDuplicate, updateIdeaAuthorFollow, updateIdeaCommentingEnabled, updateIdeaSubscription, updateLabels]);

    return (
        <ctx.Provider value={contextVales}>
            {children}
        </ctx.Provider>
    );
});
