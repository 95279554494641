import {SharingMedium} from '@ideascale/commons/dist/models/enums/SharingMedium';
import {UriBuilder} from '@ideascale/commons/dist/services/UriBuilder';
import {appLinks} from 'services/AppLinks';
import {SharingMedia} from 'models/SharingMedia';
import {NotificationType} from 'models/enums/NotificationType';
import {useAppContext} from 'contexts/AppContext';

const SOCIAL_SHARE_CONSTANTS = {
    facebookShare: 'https://www.facebook.com/sharer/sharer.php?locale=',
    linkedinShare: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    twitterShare: 'https://twitter.com/intent/tweet?tw_p=tweetbutton&url=',
    twitterString: ':Idea Management - Innovation Management - Crowdsourcing - Suggestion Box - Customer Feedback Software',
    text: '&text=',
    hashtag: '&hashtags=',
    related: '&related=',
    via: '&via='
};
export const useSocialShareServices = () => {
    const {communityConfig} = useAppContext();

    const getSocialIdeaShareURLs = (allowedMediaList: SharingMedia[] = [], ideaTitle: string, ideaId: number) => {
        const result: { facebook: string, twitter: string, linkedin: string } = {
            facebook: '',
            twitter: '',
            linkedin: ''
        };
        const ideaShareUrl = communityConfig.url + appLinks.ideaShare(ideaId);

        if (allowedMediaList?.find(media => media.id === SharingMedium.FACEBOOK)) {
            result.facebook = facebookShare(allowedMediaList, ideaShareUrl);
        }
        if (allowedMediaList?.find(media => media.id === SharingMedium.TWITTER)) {
            result.twitter = twitterShare(false, communityConfig.url, communityConfig.name, ideaShareUrl, allowedMediaList, communityConfig.whiteLabeled, ideaTitle);
        }
        if (allowedMediaList?.find(media => media.id === SharingMedium.LINKEDIN)) {
            result.linkedin = linkedinShare(allowedMediaList, ideaShareUrl);
        }
        return result;
    };

    const getSocialDigestShareURLs = (allowedMediaList: SharingMedia[] = [], campaignId: number, campaignName: string, memberId: number, year: string, month: string | undefined, week: string | undefined, notificationType: NotificationType) => {
        const result: { facebook: string, twitter: string, linkedin: string } = {
            facebook: '',
            twitter: '',
            linkedin: ''
        };
        const digestShareURL = notificationType === NotificationType.MONTHLY_MODERATOR_DIGEST
            ? `${communityConfig.url + process.env.PUBLIC_URL + '/campaign-performance/' + campaignId + '/member/' + memberId + '/monthly/' + year + '/' + month}`
            : `${communityConfig.url + process.env.PUBLIC_URL + '/campaign-performance/' + campaignId + '/member/' + memberId + '/weekly/' + year + '/' + week}`;

        if (allowedMediaList?.find(media => media.id === SharingMedium.FACEBOOK)) {
            result.facebook = facebookShare(allowedMediaList, digestShareURL, true);
        }
        if (allowedMediaList?.find(media => media.id === SharingMedium.TWITTER)) {
            result.twitter = twitterShare(false, communityConfig.url, communityConfig.name, digestShareURL, allowedMediaList, communityConfig.whiteLabeled, '', campaignName, true);
        }
        if (allowedMediaList?.find(media => media.id === SharingMedium.LINKEDIN)) {
            result.linkedin = linkedinShare(allowedMediaList, digestShareURL, true);
        }
        return result;
    };

    const facebookShare = (allowedMediaList: SharingMedia[], url: string, isDigestShare?: boolean) => {
        const mediaShareData = allowedMediaList.find(media => media.name === 'FACEBOOK');
        const pathToAdd = mediaShareData?.socialClickParam;
        if (!isDigestShare && pathToAdd) {
            url = url + '/' + pathToAdd;
        }
        const urlToShare = new UriBuilder(url).build();
        return SOCIAL_SHARE_CONSTANTS.facebookShare + mediaShareData?.locale + '&u=' + encodeURI(urlToShare);
    };

    const linkedinShare = (allowedMediaList: SharingMedia[], url: string, isDigestShare?: boolean) => {
        const pathToAdd = allowedMediaList.find(media => media.name === 'LINKEDIN')?.socialClickParam;
        if (!isDigestShare && pathToAdd) {
            url = url + '/' + pathToAdd;
        }
        const urlToShare = new UriBuilder(url).build();
        return SOCIAL_SHARE_CONSTANTS.linkedinShare + encodeURI(urlToShare);
    };

    const twitterShare = (isCommunityShare: boolean, communityUrl: string, communityName: string, url: string, allowedMediaList: SharingMedia[], whiteLabeled: boolean, ideaTitle?: string, campaignName?: string, isDigestShare?: boolean) => {
        const twitterData = allowedMediaList.find(media => media.name === 'TWITTER');
        if (twitterData) {
            const pathToAdd = twitterData?.socialClickParam;
            if (!isDigestShare && pathToAdd) {
                url = url + '/' + pathToAdd;
            }
            const urlToShare = new UriBuilder(url).build();
            let titleToTweet;
            if (isCommunityShare) {
                titleToTweet = twitterData.defaultTweetOnCommunityShare
                    ? twitterData.defaultTweetOnCommunityShare
                        .replace('{communityName}', communityConfig.name)
                        .replace('{communityUrl}', communityConfig.url)
                        .replace('{communityUrl}', urlToShare)
                    : '';
            } else {
                titleToTweet = twitterData.defaultTweetOnIdeaShare
                    ? twitterData.defaultTweetOnIdeaShare
                        .replace('{ideaTitle}', ideaTitle || '')
                        .replace('{communityName}', communityName)
                        .replace('{ideaUrl}', urlToShare)
                    : twitterData.defaultTweetOnCampaignPerformanceShare && campaignName
                        ? twitterData.defaultTweetOnCampaignPerformanceShare
                            .replace('{campaignName}', campaignName)
                            .replace('{communityName}', communityName)
                            .replace('{campaignPerformanceUrl}', urlToShare)
                        : ''
            }
            return tweet(urlToShare, titleToTweet, twitterData, whiteLabeled);
        }
        return '';
    };

    const tweet = (url: string, title: string, twitterData: SharingMedia | undefined, whiteLabeled: boolean) => {
        let buffer = SOCIAL_SHARE_CONSTANTS.twitterShare + encodeURI(url) + SOCIAL_SHARE_CONSTANTS.text + encodeURIComponent(title);

        if (twitterData && twitterData.twitterHashtag && twitterData.twitterHashtag.replace(/ /g, '').length > 0) {
            buffer = buffer + SOCIAL_SHARE_CONSTANTS.hashtag + encodeURI(twitterData.twitterHashtag);
        }

        let twitterUserName = twitterData?.appTwitterUserName;
        let via = twitterData?.twitterUserName ? twitterData.twitterUserName : '';

        if (!whiteLabeled && twitterUserName !== via && twitterUserName) {
            buffer = buffer + SOCIAL_SHARE_CONSTANTS.related + encodeURI(twitterUserName) + encodeURI(SOCIAL_SHARE_CONSTANTS.twitterString);
        }

        if (via) {
            buffer = buffer + SOCIAL_SHARE_CONSTANTS.via + encodeURI(via);
        }

        return buffer;
    };

    return {
        getSocialIdeaShareURLs,
        getSocialDigestShareURLs,
        twitterShare,
        facebookShare,
        linkedinShare,
        tweet
    };
};