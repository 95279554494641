import React, {lazy, Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import {IdeascaleSlider} from '@ideascale/ui';
import svgIconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData,
} from '@ideascale/commons/dist/utils/AlertEvent';
import {ClassificationLabel} from '@ideascale/commons/dist/components/classification-rendering/ClassificationLabel';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {LayoutUtil} from '@ideascale/commons/dist/utils/LayoutUtil';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useApplicableClassifications} from '@ideascale/commons/dist/hooks/useApplicableClassifications';
import {useAppContext} from 'contexts/AppContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useExpiryMessage} from 'hooks/useExpiryMessage';
import {HtmlRenderer} from 'components/shared/HtmlRenderer';
import {appLinks} from 'services/AppLinks';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {CommunitySlide} from 'models/CommunitySlide';
import {CampaignSlide} from 'models/CampaignSlide';
import {SlideType} from 'models/enums/SlideType';

const CommunityTileEdit = lazy(() => import('components/edit-mode/CommunityTileEdit').then(module => ({default: module.CommunityTileEdit})));

const PAGE_SIZE = 15;
const DEFAULT_SLIDES_TO_SHOW = 3;

type PageData = {
    hasMore: boolean,
    currentPage: number
}

type CampaignSliderContainerProps = {
    hidden: boolean;
}

const prepareCommunitySlide = (data: CommunitySlide | undefined) => {
    if (data) {
        return {
            ...data,
            subtitle: data.subtitle
                ? <HtmlRenderer content={data.subtitle} elementId="community-subtitle"/>
                : undefined,
            titleTestId: 'community-name'
        };
    }
};
export const CampaignSliderContainer = (props: CampaignSliderContainerProps) => {
    const {hidden} = props;

    const {editModeEnabled} = useAppContext();
    const localizer = useLocalizer();
    const navigate = useNavigate();
    const communityService = useCommunityService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {getClassificationAttribute} = useApplicableClassifications('', []);
    const [pageData, setPageData] = useState<PageData>({hasMore: true, currentPage: 0});
    const [loading, setLoading] = useState(true);
    const [communityData, setCommunityData] = useState<CommunitySlide>();
    const [campaignData, setCampaignData] = useState<CampaignSlide[]>([]);
    const campaignsPerSlide = useRef(DEFAULT_SLIDES_TO_SHOW);

    const expiryMessage = useExpiryMessage();

    const dataBuilder = useCallback(() => {
        let newData = campaignData;
        newData.forEach(data => {
            data.content = data.subtitle;
            data.expiryDate = data.countdownEnabled ? data.expiryDate : '';
            data.ariaLabel = localizer.msg('campaign.details.aria-label', {
                title: data.title,
                status: data.status ? `(${data.status})` : '',
            });
        });
        return newData;
    }, [campaignData, localizer]);

    const visitCampaignPage = (campaignId: number) => {
        const clickedCampaign: CampaignSlide | undefined = campaignData.find(data => data.id === campaignId);
        navigate(clickedCampaign?.defaultStage
            ? appLinks.ideas(String(campaignId), clickedCampaign?.defaultStage.key)
            : appLinks.campaign(String(campaignId))
        );
    };

    const toggleSubscribe = (isSubscribed: boolean, campaignId: number) => {
        let newData = cloneDeep(campaignData);
        newData.forEach(slide => {
            if (slide.id === campaignId) {
                if (slide.subscribed) {
                    unsubscribeToCampaign(campaignId).then((response) => {
                        if (response && response.status) {
                            slide.subscribed = false;
                            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
                        }
                    });
                } else {
                    subscribeToCampaign(campaignId).then((response) => {
                        if (response && response.status) {
                            slide.subscribed = true;
                            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, response.message));
                        }
                    });
                }
                slide.subscribed = !isSubscribed;
            }
        });
        setCampaignData(newData);
    };

    const subscribeToCampaign = async (id: number): Promise<CampaignSubscribeResponse> => {
        if (communityService !== null) {
            try {
                return await communityService.subscribeCampaign(id);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return CampaignSubscribeResponse.EMPTY;
    };

    const unsubscribeToCampaign = async (id: number): Promise<CampaignSubscribeResponse> => {
        if (communityService !== null) {
            try {
                return await communityService.unsubscribeCampaign(id);
            } catch (error: any) {
                handleErrorResponse(error);
            }
        }
        return CampaignSubscribeResponse.EMPTY;
    };

    const onSlidesChanged = (currentSlide: number) => {
        if (pageData.hasMore && (currentSlide * campaignsPerSlide.current >= ((pageData.currentPage + 1) * PAGE_SIZE - (2 * campaignsPerSlide.current)))) {
            if (communityService !== null) {
                communityService.fetchSliderData({
                    limit: PAGE_SIZE,
                    page: pageData.currentPage + 1
                }).then(data => {
                        let newData = data.content as CampaignSlide[];
                        setCampaignData(prevState => [...prevState, ...newData]);
                        setPageData({hasMore: data.hasMore, currentPage: data.pageNo});
                    }
                );
            }
        }
    };

    useEffect(() => {
        if (LayoutUtil.isMobileLayout()) {
            campaignsPerSlide.current = 1;
        }
        if (communityService !== null) {
            communityService.fetchSliderData({limit: PAGE_SIZE, page: 0}).then(data => {
                    setPageData({hasMore: data.hasMore, currentPage: data.pageNo});
                    let dataArray: CampaignSlide[] = [];
                    data.content.forEach(data => {
                        if (data.type === SlideType.COMMUNITY) {
                            setCommunityData(data as CommunitySlide);
                        } else {
                            dataArray.push(data as CampaignSlide);
                        }
                    });
                    setCampaignData(dataArray);
                    setLoading(false);
                }
            ).catch(error => {
                handleErrorResponse(error);
                setLoading(false);
            });
        }
    }, [communityService, handleErrorResponse]);

    return (
        <section id="carousel-campaign"
                 className={`slide mb-3 position-relative ${editModeEnabled ? 'edit-mode-element' : ''} ${hidden ? 'd-none' : ''}`}
                 style={{minHeight: '165px'}}>
            {
                editModeEnabled
                    ? <Suspense><CommunityTileEdit/></Suspense>
                    : <IdeascaleSlider loading={loading}
                                       ariaLabel={localizer.msg('campaign.carousel')}
                                       autoplay={false}
                                       autoplaySpeed={5000}
                                       infiniteSlides={!pageData.hasMore}
                                       itemsPerSlide={campaignsPerSlide.current}
                                       introSlide={prepareCommunitySlide(communityData)}
                                       slides={dataBuilder()}
                                       afterChange={onSlidesChanged}
                                       onItemSubscribeClicked={toggleSubscribe}
                                       onSlideClicked={visitCampaignPage}
                                       onSlideEntered={visitCampaignPage}
                                       statusMessage={localizer.msg('campaign.details.new')}
                                       svgIconPath={svgIconPath}
                                       classificationLabelConfig={{
                                           ClassificationLabel: ClassificationLabel,
                                           getClassificationAttribute
                                       }}
                                       expiryMessages={expiryMessage}
                    />
            }
        </section>
    );
};