import {DateTime} from 'luxon';
import flatpickr from 'flatpickr';
import {DateTimeUtil} from '@ideascale/commons/dist/utils/DateTimeUtil';

export class TimeUtil {
    static currentTimeInMilliSecond(): number {
        return new Date().getTime();
    }

    static currentMoment(timeZone: string): DateTime {
        return DateTime.local().setZone(timeZone);
    }

    static tomorrow(timeZone: string): DateTime {
        return this.currentMoment(timeZone).plus({days: 1});
    }

    static monday(timeZone: string): DateTime {
        const now = this.currentMoment(timeZone);
        return now.set({weekday: 1}).plus({weeks: 1});
    }

    static oneWeekLater(timeZone: string): DateTime {
        return this.currentMoment(timeZone).plus({weeks: 1});
    }

    static oneMonthLater(timeZone: string): DateTime {
        return this.currentMoment(timeZone).plus({months: 1});
    }

    static getMomentAt(timeZone: string, year: number, month: number, day: number): DateTime {
        return DateTime.local(year, month, day).setZone(timeZone);
    }

    static calculateTimeLeft(endDate: string) {
        let difference = DateTime.fromISO(endDate).toMillis() - TimeUtil.currentTimeInMilliSecond();
        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };

    static isBefore(endDate: string) {
        let difference = DateTime.fromISO(endDate).toMillis() - TimeUtil.currentTimeInMilliSecond();
        return difference <= 0;
    }

    static isAfter(endDate: string) {
        let difference = DateTime.fromISO(endDate).toMillis() - TimeUtil.currentTimeInMilliSecond();
        return difference > 0;
    }

    static formatToISODate(date: Date, timeZone: string, endTimeStamp: boolean = false) {
        if (endTimeStamp) {
            return DateTimeUtil.castAtTimeZone(`${TimeUtil.toDateString(date)} 23:59:59`, 'LL/dd/yyyy HH:mm:ss', timeZone).toUTC().toISO();
        }
        return DateTimeUtil.castAtTimeZone(`${TimeUtil.toDateString(date)} 00:00:00`, 'LL/dd/yyyy HH:mm:ss', timeZone).toUTC().toISO();
    }

    static toDateString(date: Date, format: string = 'm/d/Y') {
        return flatpickr.formatDate(date, format);
    }

    static parseToDate(date: string) {
        return flatpickr.parseDate(date) || new Date();
    }

    static getMonthNameFromNumber(month: number) {
        return DateTime.fromObject({month: month}).toFormat('LLLL');
    }

    static getFormattedDateTime(isoTimeString: string, format: string) {
        return DateTime.fromISO(isoTimeString).toFormat(format);
    }
}