import {FieldAttributeParameters} from '@ideascale/commons/dist/models/classification/FieldAttributeApiParameters';
import {PrivateMessageRequestParams} from '@ideascale/commons/dist/models/PrivateMessageRequestParams';

export class IdeaInternalShareRequestParams extends PrivateMessageRequestParams {
    constructor(
        ids: number[],
        subject: string,
        body: string,
        public ideaId: number,
        public attributeApiParameters?: FieldAttributeParameters[]
    ) {
        super(ids, subject, body, attributeApiParameters);
    }
}