import {DateTimeFormat} from '@ideascale/commons/dist/models/DateTimeFormat';
import {LinkQualifier} from '@ideascale/commons/dist/models/LinkQualifier';
import {IdeaListViewMode} from './enums/IdeaListViewMode';
import {Language} from './types/Language';
import {IdeaListMode} from './enums/IdeaListMode';
import {AdminPermissionHolder} from './edit-mode/AdminPermissionHolder';
import {AdminPermissionScope} from './enums/AdminPermissionScope';
import {CampaignTab} from './enums/CampaignTab';

export class CommunityConfig {
    static readonly EMPTY = new CommunityConfig(0, '', '', '', '', '', false, false, {
            id: -99,
            name: '',
            code: ''
        }, false, false,
        false, false, false, false, false,
        false, DateTimeFormat.EMPTY, new LinkQualifier(), 0, 0, 'home',
        false, IdeaListViewMode.UNDEFINED, false, false, false, false, true, true, false, false, false,
        false, [], false, true, false, false, false, '', false, false, IdeaListMode.TRENDING, {
            id: -1,
            name: 'English',
            code: 'en'
        }, false, false, false, '', false, '', '', false, [], '', '', '', false, false, false, false, false, 0, 50, false, false, false, AdminPermissionHolder.EMPTY, '', '', '', '', '', false, CampaignTab.IDEAS);

    constructor(
        public id: number,
        public name: string,
        public logo: string,
        public altText: string,
        public bannerImageUrl: string,
        public bannerImageAltText: string,
        public bannerGradientEnabled: boolean,
        public bannerTitleInBlack: boolean,
        public defaultLanguage: Language,
        public publicCommunity: boolean,
        public brandingAllowed: boolean,
        public readCommunity: boolean,
        public freeLicense: boolean,
        public hideCommunityLogo: boolean,
        public atMentionEnabled: boolean,
        public ideaOwnershipEnabled: boolean,
        public displayActualDays: boolean,
        public dateTimeFormat: DateTimeFormat,
        public linkQualifiers: LinkQualifier,
        public maxFileSizeLimit: number,
        public maxAvatarFileSizeLimit: number,
        public defaultPage: 'home' | 'landing',
        public draftEnabled: boolean,
        public ideaListViewMode: IdeaListViewMode,
        public noIndexOrFollow: boolean,
        public seoLinkHidden: boolean,
        public draftsTabEnabled: boolean,
        public myIdeasTabEnabled: boolean,
        public tagEnabled: boolean,
        public hybridTag: boolean,
        public campaignSpecificTag: boolean,
        public moderatorTagEnabled: boolean,
        public predefinedModeratorTags: boolean,
        public customLandingPageEnabled: boolean,
        public supportedLanguages: Language[],
        public multipleLanguageSupported: boolean,
        public netVotesEnabled: boolean,
        public voteDownEnabled: boolean,
        public ssoEnabled: boolean,
        public stageTimelineEnabled: boolean,
        public url: string,
        public whiteLabeled: boolean,
        public translatedIdeaShowAllowed: boolean,
        public defaultIdeaListMode: IdeaListMode,
        public preferredLanguage: Language,
        public submissionAllowed: boolean,
        public contentTranslationEnabled: boolean,
        public displayUpDownVoteCounts: boolean,
        public faviconImageUrl: string,
        public ideaEmailShareEnabled: boolean,
        public communityTrackingCode: string,
        public ideascaleTrackingCode: string,
        public logoFrameEnabled: boolean,
        public offensiveEmojis: string[],
        public highlightColor: string,
        public accentColorOne: string,
        public accentColorTwo: string,
        public inviteFriendEnabled: boolean,
        public supportChatEnabled: boolean,
        public classificationEnabled: boolean,
        public showRecommendedIdeas: boolean,
        public communityReadOnly: boolean,
        public privateMessageMaxRecipients: number,
        public privateMessageMaxSubjectLength: number,
        public privateMessageEnabled: boolean,
        public aiTextAssistEnabled: boolean,
        public aiImageAssistEnabled: boolean,
        public adminPermissionHolder: AdminPermissionHolder,
        public upgradePlanUrl: string,
        public subtitle: string,
        public communityTileImageUrl: string,
        public communityTileImageAltText: string,
        public helpSiteBaseUrl: string,
        public unfurlUrlEnabled: boolean,
        public defaultCampaignTab: CampaignTab,
    ) {
    }

    hasTranslationPermission = () => {
        return this.adminPermissionHolder?.translationEnabled;
    };

    hasEditPermission = (scope: AdminPermissionScope) => {
        return this.adminPermissionHolder?.editPermissions.includes(scope);
    };

    hasAdminEditPermissions = () => {
        return Object.values(AdminPermissionScope).some(value => this.adminPermissionHolder?.editPermissions.includes(value));
    };
}