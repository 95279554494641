import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Button, DropdownItem, Icon, Modal, Spinner, Switch, Theme} from '@ideascale/ui';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import {
    AlertEvent,
    AlertType,
    buildAlertEventData
} from '@ideascale/commons/dist/utils/AlertEvent';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {useToggle} from '@ideascale/commons/dist/hooks/useToggle';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {reloadPage} from '@ideascale/commons/dist/utils/CommonUtil';
import {LayoutUtil} from '@ideascale/commons/dist/utils/LayoutUtil';
import {setDebugLocalizerCookieAndReload} from '@ideascale/commons/dist/utils/CommonUtil';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {ProfileMenuName} from '@ideascale/commons/dist/models/enums/ProfileMenuName';
import {Member} from '@ideascale/commons/dist/models/Member';
import {PagedResponseContent} from '@ideascale/commons/dist/models/PagedResponseContent';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {SimpleDropdown} from '@ideascale/commons/dist/components/simple-dropdown/SimpleDropdown';
import {SimpleDropdownDivider} from '@ideascale/commons/dist/components/simple-dropdown/SimpleDropdownDivider';
import {appConstants, FEATURE_TOUR_EVENTS} from '@ideascale/commons/dist/shared/AppConstants';
import { LocalizationMode } from '@ideascale/commons/dist/shared/localization/LocalizationMode';
import { FeatureTourStep } from '@ideascale/commons/dist/models/enums/FeatureTourStep';
import { TobBarLogo } from '@ideascale/commons/dist/components/topbar/topbar-logo/TobBarLogo';
import {useAppContext} from 'contexts/AppContext';
import {useTopBarService} from 'hooks/useTopBarService';
import {useEditModeAccessibilityHelper} from 'hooks/useEditModeAccessibilityHelper';
import {useFeatureTourStore} from 'stores/useFeatureTourStore';
import {appLinks} from 'services/AppLinks';
import {DropdownMenuItem} from './DropdownMenuItem';
import {CustomPageMenu} from '../CustomPageMenu';
import {PageParameters} from 'models/types/PageParameters';
import {CustomPageData} from 'models/CustomPageData';
import {ROUTES} from 'shared/Routes';
import {LabelsMenu} from '../LabelsMenu';
import './ProfileMenu.scss';

type MenuItem = {
    name: string;
    url: string;
    icon?: string;
    sudo: boolean;
    externalLink: boolean;
    methodType?: string;
    key?: string;
}

type ProfileMenuProps = {
    name: string;
    communityLogo: string;
    altText: string;
    node: string;
    currentMenuPath: string | RegExp;
    topBarSvgIcons: string;
    profileMenu: MenuItem[];
    privilegeStateMenu: MenuItem[];
    sudoMenu: MenuItem[];
    communityMenu: MenuItem[];
    avatar: string,
    username: string,
    newFeaturesAvailable: boolean;
    debugLocalizerMenuVisible: boolean;
    localizer: Localizer;
    fetchTopBarCustomPages: (pageParameters: PageParameters) => Promise<PagedResponseContent<CustomPageData>>;
    fetchTopBarLabels: (pageParameters: PageParameters) => Promise<PagedResponseContent<LabelData>>;
    sudoAdmin: () => Promise<void>;
    deSudoAdmin: () => Promise<void>;
    sudoer?: Member;
    updateThemePreference: (theme: Theme) => Promise<void>;
    whiteboardAccessible: boolean;
    createCustomPageEnabled: boolean;
}

export const ProfileMenu = (props: ProfileMenuProps) => {
    const {
        topBarSvgIcons,
        avatar,
        username,
        name,
        communityLogo,
        altText,
        communityMenu,
        profileMenu,
        privilegeStateMenu,
        node,
        sudoMenu,
        newFeaturesAvailable,
        debugLocalizerMenuVisible,
        localizer,
        fetchTopBarCustomPages,
        fetchTopBarLabels,
        sudoAdmin,
        deSudoAdmin,
        sudoer,
        updateThemePreference,
        whiteboardAccessible,
        createCustomPageEnabled,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const query = useUrlQuery();
    const [dropdownOpen, toggleDropDown] = useToggle(false);
    const [exploreDropdownOpen, toggleExploreDropdown] = useToggle(false);
    const [labelDropdownOpen, toggleLabelDropdown] = useToggle(false);
    const [showSudoProgressModal, setShowSudoProgressModal] = useState(false);
    const {
        authentication,
        localizationMode,
        setLocalizationMode,
        darkMode,
        toggleDarkMode,
    } = useAppContext();
    const {actor} = authentication;

    const {enablePrivilege, disablePrivilege} = useTopBarService();
    const {isEditModeAccessibleRoute} = useEditModeAccessibilityHelper();

    const tourShowedRef = useRef<boolean>(false);
    const featureTourStepName = useFeatureTourStore(state => (state.currentStepName));
    const [hasNewFeatures, setHasNewFeatures] = useState(newFeaturesAvailable);

    const onSudoAdminClicked = () => {
        setShowSudoProgressModal(true);
        sudoAdmin().then(() => reloadPage(2000));
    };

    const onDeSudoAdminClicked = () => {
        setShowSudoProgressModal(true);
        deSudoAdmin().then(() => reloadPage(2000));
    };

    const onDebugLocalizerClicked = (localizationMode: LocalizationMode) => {
        setLocalizationMode(localizationMode);
        setDebugLocalizerCookieAndReload(localizationMode);
    };

    const renderSudoMenuItem = (menuItem: MenuItem) => {
        if (menuItem.key === ProfileMenuName.SUDO_ADMIN) {
            return (
                <Button color="default"
                        className={`fw-normal dropdown-item ${menuItem.sudo ? 'highlighted' : ''}`}
                        key={menuItem.name.replace(/\s/g, '-')}
                        onClick={onSudoAdminClicked} data-test-element-id="sudo-link">
                    {menuItem.name}
                </Button>
            );
        } else if (menuItem.key === ProfileMenuName.SWITCH_BACK && actor.isSudo) {
            return (
                <Button color="default"
                        className={`fw-normal dropdown-item ${menuItem.sudo ? 'highlighted' : ''}`}
                        key={menuItem.name.replace(/\s/g, '-')}
                        onClick={onDeSudoAdminClicked} data-test-element-id="desudo-link">
                    {menuItem.name}
                </Button>
            );
        }
    };

    const renderInternalItem = (menuItem: MenuItem, routeLink: string, highlightIcon = false) => {
        return (
            <Link className="dropdown-item fw-normal text-default"
                  to={routeLink}
                  data-test-element-id={menuItem?.name ? `${menuItem.name.toLowerCase().replaceAll(/\s/g, '-')}-link` : ''}
                  onClick={() => {
                      if (menuItem.key === ProfileMenuName.NEW_FEATURES) {
                          setHasNewFeatures(false);
                      }
                  }}>
                {
                    menuItem.icon
                        ?
                        <Icon name={menuItem.icon}
                              iconSpritePath={topBarSvgIcons}
                              className={highlightIcon ? 'active' : ''}
                              width={20}
                              height={20}/>
                        :
                        null
                }
                {menuItem.name}
            </Link>
        );
    };

    const renderConditionalMenuItems = (menuItem: MenuItem) => {
        if (menuItem.key === ProfileMenuName.NEW_FEATURES) return renderInternalItem(menuItem, ROUTES.NEW_FEATURES, hasNewFeatures);
        else if (menuItem.key === ProfileMenuName.INVITE_FRIEND) return renderInternalItem(menuItem, ROUTES.INVITE_FRIENDS);
        else return <DropdownMenuItem item={menuItem}
                                      elementId={menuItem?.name ? `${menuItem.name.toLowerCase().replaceAll(/\s/g, '-')}-link` : ''}
                                      topBarSvgIcons={topBarSvgIcons}/>;
    };

    const renderMenuItems = (menuItems: MenuItem[]) => {
        if (menuItems && menuItems.length > 0) {
            return menuItems.map((menuItem, index) =>
                <Fragment key={index}>
                    {
                        (menuItem.key === ProfileMenuName.SUDO_ADMIN || menuItem.key === ProfileMenuName.SWITCH_BACK)
                            ?
                            renderSudoMenuItem(menuItem)
                            :
                            (menuItem.key === ProfileMenuName.NEW_FEATURES)
                                ?
                                renderInternalItem(menuItem, ROUTES.NEW_FEATURES, hasNewFeatures)
                                :
                                renderConditionalMenuItems(menuItem)
                    }
                    {
                        (index + 1 === menuItems.length) &&
                        <SimpleDropdownDivider/>
                    }
                </Fragment>
            );
        }
        return null;
    };

    const onPrivilegeItemClick = async (menuItem: MenuItem) => {
        if (menuItem.key === ProfileMenuName.DISABLE_PRIVILEGE) {
            await disablePrivilege(actor.id);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('frontend-shared.topbar.moderator-role.revoked')));
            reloadPage(1000);
        } else if (menuItem.key === ProfileMenuName.ENABLE_PRIVILEGE) {
            await enablePrivilege(actor.id);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, localizer.msg('frontend-shared.topbar.moderator-role.restored')));
            reloadPage(1000);
        }
    };

    const renderPrivilegeItem = (menuItem: MenuItem) => {
        return (
            <Button color="default" className="fw-normal dropdown-item"
                    data-test-element-id={menuItem?.name ? `${menuItem.name.toLowerCase().replaceAll(/\s/g, '-')}-link` : ''}
                    onClick={() => onPrivilegeItemClick(menuItem)}>
                {menuItem.icon ? <Icon name={menuItem.icon} iconSpritePath={topBarSvgIcons} fill={'#666666'} width={20}
                                       height={20}/> : null} {menuItem.name}
            </Button>
        );
    };

    const renderPrivilegeMenuItems = (menuItems: MenuItem[]) => {
        if (menuItems && menuItems.length > 0) {
            return menuItems.map((menuItem, index) =>
                <Fragment key={index}>
                    {
                        renderPrivilegeItem(menuItem)
                    }
                    {(index + 1 === menuItems.length) && (
                        <SimpleDropdownDivider/>
                    )}
                </Fragment>
            );
        }
        return null;
    };

    const renderLocalizationDebugMenus = () => {
        if (localizationMode === LocalizationMode.DEFAULT) {
            return (
                <Fragment>
                    <Button color="default" className="highlighted fw-normal dropdown-item"
                            data-test-element-id="enable-blank-localizer-link"
                            onClick={() => onDebugLocalizerClicked(LocalizationMode.BLANK)}>
                        {'Enable Blank Localizer'}
                    </Button>

                    <Button color="default" className="highlighted fw-normal dropdown-item"
                            data-test-element-id="enable-bounce-localizer-link"
                            onClick={() => onDebugLocalizerClicked(LocalizationMode.BOUNCE)}>
                        {'Enable Bounce Localizer'}
                    </Button>
                    <SimpleDropdownDivider/>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <DropdownItem className="highlighted"
                                  data-test-element-id="disable-localizer-debugging-link"
                                  onClick={() => onDebugLocalizerClicked(LocalizationMode.DEFAULT)}>
                        {'Disable Localizer Debugging'}
                    </DropdownItem>
                    <SimpleDropdownDivider/>
                </Fragment>
            );
        }
    };

    const onUserProfileClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (actor.hasPendingMembership()) {
            event.preventDefault();
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.member_awaiting_approval')));
        } else if (actor.communityTosAcceptanceRequired) {
            event.preventDefault();
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg('frontend-shared.errors.community_tos_not_accepted')));
        } else {
            return true;
        }
    };

    const onThemeChange = (e: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        void updateThemePreference(!darkMode ? Theme.DARK : Theme.LIGHT);
        toggleDarkMode(!darkMode);
        toggleDropDown(false);
    };

    const onCLickEditMode = useCallback(() => {
        const newUrlQuery = new URLSearchParams(query);
        newUrlQuery.set(ROUTES.SEARCH_QUERY.EDIT_MODE, 'enabled');
        navigate({
            pathname: location.pathname,
            search: newUrlQuery.toString()
        });
    }, [location.pathname, navigate, query]);

    useEffect(() => {
        if (dropdownOpen && !tourShowedRef.current) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.SET_TOUR_STEP_INDEX, FeatureTourStep.LUCIDO_NEW_PROFILE);
            tourShowedRef.current = true;
        } else if (tourShowedRef.current) {
            eventDispatcher.dispatch(FEATURE_TOUR_EVENTS.RESET_TOUR_STEP_INDEX);
            tourShowedRef.current = false;
        }
    }, [dropdownOpen]);


    const onDropdownToggled = useCallback((nextValue?: boolean) => {
        if (dropdownOpen && featureTourStepName === FeatureTourStep.LUCIDO_NEW_PROFILE) {
            return;
        } else {
            toggleDropDown(nextValue);
        }
    }, [dropdownOpen, featureTourStepName, toggleDropDown]);

    useEffect(() => {
        setHasNewFeatures(newFeaturesAvailable);
    }, [newFeaturesAvailable]);

    return (
        <Fragment>
            <SimpleDropdown open={dropdownOpen}
                            toggle={onDropdownToggled}
                            toggleOnRouteChange={true}
                            toggleClassName="btn-profile-menu shadow-none d-flex align-items-center"
                            toggleTitle={localizer.msg('common.profile')}
                            toggleContent={
                                <Fragment>
                                    {
                                        actor.isSudo && sudoer && sudoer.id > 0 &&
                                        <Fragment>
                                            <Avatar size="sm" className="pointer-events-none" src={sudoer.avatar}
                                                    alt={sudoer.username ? localizer.msg('common.user-avatar', {username: sudoer.username}) : ''}
                                                    title={sudoer.name}/>
                                            <Icon name="triangle-right" iconSpritePath={topBarSvgIcons} fill={'#666'}
                                                  width={18}
                                                  height={18}/>
                                        </Fragment>
                                    }
                                    <Avatar size="sm" className="pointer-events-none" src={avatar}
                                            alt={username ? localizer.msg('common.user-avatar', {username}) : ''}
                                            title={name}/>
                                </Fragment>
                            }
                            toggleTestId="btn-toggle-profile-menu"
                            slide="right">
                <div className="profile-menu py-2">
                    <a className="navbar-brand d-lg-none mx-4 logo text-default" href={process.env.PUBLIC_URL}
                       tabIndex={-1}>
                        <TobBarLogo logo={communityLogo} alt={altText}/>
                    </a>

                    <Link
                        className="btn btn-link user-info dropdown-item d-flex align-items-center bg-transparent d-flex align-items-center tour-lucido_new_profile"
                        to={appLinks.profile(actor.id)}
                        data-test-element-id="profile-link"
                        onClick={onUserProfileClick}
                        aria-label={localizer.msg('topbar.profile-for', {memberName: name, username: username})}>
                        <Avatar size="md" className="me-2" src={avatar} title={name}
                                alt={username ? localizer.msg('common.user-avatar', {username}) : ''}/>
                        <span className="d-flex flex-column">
                                    <span
                                        className="text-truncate d-inline-block user-name"
                                        data-test-element-id="display-name"
                                        title={name}>{name}</span>
                                    <span className="font-size-sm fw-normal text-truncate d-inline-block user-name"
                                          data-test-element-id="user-name"
                                          title={username}>{username && <>@{username}</>}</span>
                                </span>
                    </Link>
                    <SimpleDropdownDivider/>
                    {
                        LayoutUtil.isMobileLayout() && !authentication.isCommunityTosAcceptanceRequired() &&
                        <div className="discover-labels-menu d-lg-none">
                            <SimpleDropdown open={exploreDropdownOpen} toggle={toggleExploreDropdown}
                                            slide="right"
                                            toggleClassName="w-100 fw-normal text-start dropdown-item d-flex align-items-center"
                                            toggleContent={
                                                <Fragment>
                                                    <Icon name="compass" iconSpritePath={topBarSvgIcons}
                                                          fill={'#666'}
                                                          width={18}
                                                          height={18}/>
                                                    <span>
                                                        {localizer.msg('topbar.custom-page.explore-custom-pages')}
                                                    </span>
                                                    <Icon name="chevron-right" className="ms-auto"
                                                          iconSpritePath={topBarSvgIcons}
                                                          fill={'#666'}
                                                          width={18}
                                                          height={18}/>
                                                </Fragment>
                                            }>
                                <Fragment>
                                    <div className="d-flex align-items-center w-100">
                                        <Button color="default" className="fw-semibold d-flex align-items-center"
                                                onClick={(e) => {
                                                    toggleExploreDropdown(false);
                                                    e.stopPropagation();
                                                }}>
                                            <Icon name="chevron-left" className="mr-2"
                                                  iconSpritePath={topBarSvgIcons}
                                                  width={25}
                                                  height={25}/>
                                            {localizer.msg('common.back')}
                                        </Button>

                                        <Button color="default" className="fw-normal ms-auto"
                                                onClick={toggleDropDown}
                                                title={localizer.msg('common.close')}>
                                            <Icon name="cross"
                                                  iconSpritePath={topBarSvgIcons}
                                                  width={16}
                                                  height={16}/>
                                        </Button>
                                    </div>
                                    <CustomPageMenu localizer={localizer}
                                                    focusId="profile-custom-pages-menu"
                                                    fetchTopBarCustomPages={fetchTopBarCustomPages}
                                                    createCustomPageEnabled={createCustomPageEnabled}/>
                                </Fragment>
                            </SimpleDropdown>

                            <SimpleDropdown open={labelDropdownOpen}
                                            toggle={toggleLabelDropdown}
                                            slide="right"
                                            toggleClassName="w-100 fw-normal text-start dropdown-item d-flex align-items-center"
                                            toggleContent={
                                                <Fragment>
                                                    <Icon name="flag-waving" iconSpritePath={topBarSvgIcons}
                                                          fill={'#666'}
                                                          width={18}
                                                          height={18}/>
                                                    {localizer.msg('topbar.label.title')}
                                                    <Icon name="chevron-right" className="ms-auto"
                                                          iconSpritePath={topBarSvgIcons}
                                                          fill={'#666'}
                                                          width={18}
                                                          height={18}/>
                                                </Fragment>
                                            }>
                                <Fragment>
                                    <div className="d-flex align-items-center w-100">
                                        <Button color="default" className="fw-semibold d-flex align-items-center"
                                                onClick={(e) => {
                                                    toggleLabelDropdown(false);
                                                    e.stopPropagation();
                                                }}>
                                            <Icon name="chevron-left" className="mr-2"
                                                  iconSpritePath={topBarSvgIcons}
                                                  width={25}
                                                  height={25}/>
                                            {localizer.msg('common.back')}
                                        </Button>

                                        <Button color="default" className="fw-normal ms-auto"
                                                onClick={toggleDropDown}
                                                title={localizer.msg('common.close')}>
                                            <Icon name="cross"
                                                  iconSpritePath={topBarSvgIcons}
                                                  width={16}
                                                  height={16}/>
                                        </Button>
                                    </div>
                                    <LabelsMenu localizer={localizer}
                                                focusId="profile-topbar-labels-menu"
                                                fetchTopBarLabels={fetchTopBarLabels}/>
                                </Fragment>
                            </SimpleDropdown>
                            <SimpleDropdownDivider/>
                        </div>
                    }

                    {renderMenuItems(communityMenu)}

                    {
                        whiteboardAccessible &&
                        <Fragment>
                            <a className="dropdown-item fw-normal text-default" href={appLinks.whiteboard()}>
                                <Icon name={'ideascale-whiteboard-logo'}
                                      iconSpritePath={topBarSvgIcons}
                                      width={20}
                                      height={20}/>
                                {localizer.msg('frontend-shared.topbar.whiteboard-label')}
                            </a>
                            <SimpleDropdownDivider/>
                        </Fragment>
                    }

                    {renderMenuItems(profileMenu)}

                    {renderPrivilegeMenuItems(privilegeStateMenu)}

                    {node && <span className="dropdown-menu-item-header d-inline-block">{node}</span>}

                    {renderMenuItems(sudoMenu)}

                    {debugLocalizerMenuVisible && renderLocalizationDebugMenus()}

                    {
                        !actor.isAnonymous() &&
                        <Fragment>
                            {
                                isEditModeAccessibleRoute() && !LayoutUtil.isMobileLayout() &&
                                <Button color="default" className="cursor-pointer fw-normal dropdown-item"
                                        data-test-element-id="enable-edit-mode"
                                        onClick={onCLickEditMode}>
                                    <Icon className="pos-top-n1 me-2" iconSpritePath={topBarSvgIcons}
                                          name="pencil" width={20}
                                          height={20}/>
                                    {localizer.msg('common.edit-mode')}
                                </Button>
                            }
                            <div
                                className="highlighted dark-mode-toggle d-flex justify-content-between cursor-pointer dropdown-item"
                                onClick={onThemeChange}>
                                <div className="text-truncate">
                                    <Icon className="pos-top-n1" iconSpritePath={topBarSvgIcons}
                                          name={darkMode ? 'face-leia' : 'face-vader'} width={20}
                                          height={20}
                                          fill={'#757575'}/>
                                    {darkMode ? localizer.msg('topbar.light-mode') : localizer.msg('topbar.dark-mode')}
                                </div>
                                <Switch
                                    data-test-element-id="dark-mode-toggle-link"
                                    screenReaderLabel="Theme toggle"
                                    onChange={onThemeChange}
                                    label=""
                                    checked={darkMode}
                                    inputId="dark-mode-toggle"
                                    name="dark-mode-toggle"
                                    onText="on"
                                    offText="off"/>
                            </div>
                            <SimpleDropdownDivider/>
                        </Fragment>
                    }

                    <Button color="default" className="highlighted fw-normal dropdown-item"
                            href={appConstants.communityLogoutUrl}
                            data-test-element-id="logout-link">
                        <Icon iconSpritePath={topBarSvgIcons} name="power" width={20}
                              height={20}
                              fill={'#757575'}/> {localizer.msg('topbar.logout')}
                    </Button>
                </div>
            </SimpleDropdown>

            <Modal isOpen={showSudoProgressModal}>
                <div className="d-flex flex-column align-items-center mb-3">
                    <span className="mb-3">Please wait while the sudo process finishes.</span>
                    <Spinner src={loadingSvg} size={50}/>
                </div>
            </Modal>
        </Fragment>
    );
};
