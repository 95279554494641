import {RenderFormat} from '@ideascale/commons/dist/models/enums/RenderFormat';
import {FieldValue} from './types/FieldValue';
import {Language} from './types/Language';

export class OriginalIdeaData {
    static readonly EMPTY = new OriginalIdeaData('', '', RenderFormat.PLAIN_TEXT, {id: 0, name: '', code: ''}, []);

    constructor(
        public title: string,
        public description: string,
        public renderFormat: number,
        public language: Language,
        public fieldValues: FieldValue[],
    ) {
    }
}