import {useCallback} from 'react';
import {useAppContext} from 'contexts/AppContext';
import {useIdeaService} from './useIdeaService';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';

export const useIdeaViewMode = (ideaListQueryKey: any = '') => {
    const {setIdeaListViewMode} = useAppContext();
    const {changeViewMode} = useIdeaService();

    const setViewMode = useCallback(async (viewMode: IdeaListViewMode) => {
        await changeViewMode(viewMode);
        setIdeaListViewMode(viewMode);
    }, [setIdeaListViewMode, changeViewMode]);

    return {setViewMode};
};
