import {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import cloneDeep from 'lodash/cloneDeep';
import {EffectiveClassification} from '@ideascale/commons/dist/models/classification/EffectiveClassification';
import {PagedIdeas, useIdeasContext} from 'contexts/IdeasContext';
import {EFFECTIVE_CLASSIFICATION, QUERY_KEYS} from 'constants/AppConstants';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaDetail} from 'models/IdeaDetail';

export const useIdeaUpdater = () => {
    const queryClient = useQueryClient();
    const {ideaListFilterQueryKey} = useIdeasContext();

    const ideaDetailsQueryKey = useCallback((ideaId: number) => {
        return [QUERY_KEYS.IDEA_DETAILS, ideaId];
    }, []);

    const updateIdeaListCallback = useCallback(async <T extends keyof IdeaSummary>(ideaListQueryKey: any, ideaId: number, property: T, updater: (modifiedIdea: IdeaSummary) => IdeaSummary[T]) => {
        await queryClient.cancelQueries(ideaListQueryKey);
        queryClient.setQueryData(ideaListQueryKey, (oldIdeas: any) => {
            if (oldIdeas) {
                const newIdeas = {...oldIdeas as { pages: PagedIdeas[], pageParams: any }};
                newIdeas.pages?.every((page: PagedIdeas) => {
                    const modifyIdea = page.data.find((idea) => idea.id === ideaId);
                    if (modifyIdea) {
                        modifyIdea[property] = updater(modifyIdea);
                        return false;
                    }
                    return true;
                });
                return newIdeas;
            }
            return [];
        });
    }, [queryClient]);

    const updateIdeaDetailsCallback = useCallback(async <T extends keyof IdeaDetail>(ideaId: number, property: T, updater: (modifiedIdea: IdeaDetail) => IdeaDetail[T]) => {
        await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
        queryClient.setQueryData(ideaDetailsQueryKey(ideaId), prevState => {
            const newIdeaDetails = {...prevState as IdeaDetail};
            newIdeaDetails[property] = updater(newIdeaDetails);
            return newIdeaDetails as IdeaDetail;
        });
    }, [ideaDetailsQueryKey, queryClient]);

    const updateIdea = useCallback(async <T extends keyof IdeaSummary>(ideaListQueryKey: any, ideaId: number, property: T, value: IdeaSummary[T]) => {
        await queryClient.cancelQueries(ideaListQueryKey);
        queryClient.setQueryData(ideaListQueryKey, (oldIdeas: any) => {
            if (oldIdeas) {
                const newIdeas = {...oldIdeas as { pages: PagedIdeas[], pageParams: any }};
                newIdeas.pages?.every((page: PagedIdeas) => {
                    const modifyIdea: { [index: string]: any } | undefined = page.data.find((idea: IdeaSummary) => idea.id === ideaId);
                    if (modifyIdea) {
                        modifyIdea[property] = value;
                        return false;
                    }
                    return true;
                });
                return newIdeas;
            }
            return [];
        });
    }, [queryClient]);

    const updateIdeaDetails = useCallback(async <T extends keyof IdeaDetail>(ideaId: number, property: T, value: IdeaDetail[T]) => {
        await queryClient.cancelQueries(ideaDetailsQueryKey(ideaId));
        queryClient.setQueryData(ideaDetailsQueryKey(ideaId), prevState => {
            const newIdeaDetails = {...prevState as IdeaDetail};
            newIdeaDetails[property] = value;
            return newIdeaDetails as IdeaDetail;
        });
    }, [ideaDetailsQueryKey, queryClient]);

    const replaceFullIdeaDetails = useCallback(async (ideaDetail: IdeaDetail) => {
        await queryClient.cancelQueries(ideaDetailsQueryKey(ideaDetail.id));
        queryClient.setQueryData(ideaDetailsQueryKey(ideaDetail.id), ideaDetail);
    }, [ideaDetailsQueryKey, queryClient]);

    const removeIdeaFromIdeaList = useCallback((ideaListQueryKey: any, ideaId: number): IdeaSummary | undefined => {
        let deletedIdea: IdeaSummary | undefined = undefined;
        if (ideaListQueryKey) {
            queryClient.setQueryData(ideaListQueryKey, (oldIdeaList) => {
                const newIdeaList = cloneDeep(oldIdeaList) as { pages: PagedIdeas[], pageParams: any };
                newIdeaList.pages.forEach(page => {
                    const filterDeletedIdea = page.data.filter(idea => idea.id === ideaId);
                    if (filterDeletedIdea) {
                        deletedIdea = filterDeletedIdea[0];
                    }
                    page.data = page.data.filter(idea => idea.id !== ideaId);
                });
                return newIdeaList;
            });
        }
        return deletedIdea;
    }, [queryClient]);

    const getCachedIdeaDetails = useCallback((ideaId: number) => {
        return queryClient.getQueryData(ideaDetailsQueryKey(ideaId)) as IdeaDetail;
    }, [ideaDetailsQueryKey, queryClient]);

    const replaceIdeaSummaryByDetails = useCallback((ideaId: number) => {
        const ideaDetails = getCachedIdeaDetails(ideaId);
        if (ideaDetails) {
            queryClient.setQueryData(ideaListFilterQueryKey, (previousIdeaList) => {
                if (previousIdeaList) {
                    const newIdeaList = cloneDeep(previousIdeaList) as { pages: PagedIdeas[], pageParams: any };
                    const updatedPagedIdeas = newIdeaList.pages.map((page: PagedIdeas) => {
                        const findIndex = page.data.findIndex((idea: IdeaSummary) => idea.id === ideaDetails.id);
                        if (findIndex > -1) {
                            page.data[findIndex] = ideaDetails as IdeaSummary;
                        }
                        return page;
                    });
                    return {...newIdeaList, pages: updatedPagedIdeas};
                }
            });
        }
    }, [getCachedIdeaDetails, ideaListFilterQueryKey, queryClient]);

    const updateIdeaEffectiveClassificationAttribute = useCallback((ideaId: number, ideaEffectiveClassification: EffectiveClassification | undefined) => {
        const cachedIdeaDetails = getCachedIdeaDetails(ideaId);
        if (cachedIdeaDetails && ideaEffectiveClassification) {
            updateIdeaDetails(ideaId, 'attributes', [...cachedIdeaDetails?.attributes.filter(attr => attr.name !== EFFECTIVE_CLASSIFICATION) ?? [], {
                name: EFFECTIVE_CLASSIFICATION,
                value: ideaEffectiveClassification.classificationSummary
            }]).then();
        }
    }, [getCachedIdeaDetails, updateIdeaDetails]);

    return {
        updateIdea,
        updateIdeaDetails,
        updateIdeaListCallback,
        updateIdeaDetailsCallback,
        ideaDetailsQueryKey,
        replaceFullIdeaDetails,
        removeIdeaFromIdeaList,
        getCachedIdeaDetails,
        replaceIdeaSummaryByDetails,
        updateIdeaEffectiveClassificationAttribute
    };
};