import React, {Fragment, ReactNode, useEffect} from 'react';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {SimpleDropdown} from '@ideascale/commons/dist/components/simple-dropdown/SimpleDropdown';
import {useToggle} from '@ideascale/commons/dist/hooks/useToggle';
import {TOGGLE_SIDEBAR} from 'constants/AppConstants';
import {useResizeWindow} from 'hooks/useResizeWindow';
import './SidebarContainer.scss';

type SidebarContainerProps = {
    children: ReactNode;
    className?: string;
}

const skipSidebarId = 'sidebar-wrapper';

export const SidebarContainer = (props: SidebarContainerProps) => {
    const {children, className = ''} = props;
    const [showSidebar, toggleSidebar] = useToggle(false);
    const {isMobileLayout} = useResizeWindow();

    useEffect(() => {
        if (isMobileLayout) {
            eventDispatcher.addListener(TOGGLE_SIDEBAR, () => {
                toggleSidebar();
            });
            return () => {
                eventDispatcher.removeListener(TOGGLE_SIDEBAR);
            };
        }
    }, [toggleSidebar, isMobileLayout]);

    const renderSidebar = () => {
        if (!isMobileLayout) {
            return (
                <aside className={`col-sidebar col-12 col-lg-3 d-none d-lg-block ${className ? className : ''}`}
                       aria-label="LayoutSidebarContainer">
                    <div className="sidebar-wrapper" id={skipSidebarId} tabIndex={-1}>
                        {children}
                    </div>
                </aside>
            );
        } else {
            return (
                <SimpleDropdown open={showSidebar}
                                toggle={toggleSidebar}
                                slide="right"
                                toggleOnRouteChange={true}
                                externalButtonId="sidebar-toggle-button"
                                toggleClassName="d-none"
                                toggleTestId="btn-toggle-mobile-view-sidebar"
                                toggleTitle={'LayoutSidebarContainer'}
                                dropdownClassName={'sidebar-mobile-wrapper'}
                                toggleContent={
                                    <Icon iconSpritePath={svgIconsPath}
                                          name="chevron-left-page-square-outlined-rounded"
                                          width={28}
                                          height={36}/>
                                }>
                    <div className="sidebar-wrapper" id={skipSidebarId}>
                        {children}
                    </div>
                </SimpleDropdown>
            );
        }
    };

    return (
        <Fragment>
            {renderSidebar()}
        </Fragment>
    );
};
