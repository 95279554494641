import React from 'react';
import {ParagraphSkeleton, Skeleton} from '@ideascale/ui';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';

type IdeaSkeletonProps = {
    className?: string;
    viewMode?: IdeaListViewMode;
    details?: boolean;
}

export const IdeaSkeleton = (props: IdeaSkeletonProps) => {
    const {viewMode = IdeaListViewMode.LIST_VIEW, details = false, className = ''} = props;

    return (
        <article className={`idea ${details ? `idea-details` : ''} ${className}`}>
            <header className="idea-header">
                <p className="idea-campaign">
                    <Skeleton width="50%"/>
                </p>

                <div className="idea-title">
                    <Skeleton/>
                </div>

                <div className="idea-meta-author idea-meta">
                    <div className="author-details">
                        <Skeleton cssClass="avatar avatar-sm" circle width="30px"/>
                        <Skeleton
                            cssClass={`mt-3 d-inline-block align-middle ${viewMode !== IdeaListViewMode.COMPACT_VIEW ? 'ms-2' : ''}`}
                            width="60%"/>
                    </div>
                </div>
            </header>
            <div className="idea-main-details">
                <Skeleton cssClass="mb-2 rounded" height="100px"/>
                <Skeleton/>
                <Skeleton cssClass="mb-3"/>
                <ParagraphSkeleton rows={4}/>
            </div>
        </article>
    );
};