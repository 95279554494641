import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {GroupOptionType, OptionType} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {CampaignsHolder} from '@ideascale/commons/dist/models/CampaignsHolder';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {CampaignDropdownHeader} from '@ideascale/commons/dist/components/campaign-select/CampaignDropdownHeader';
import {CampaignDropdownLabel} from '@ideascale/commons/dist/components/campaign-select/CampaignDropdownLabel';
import {CampaignSelect} from '@ideascale/commons/dist/components/campaign-select/CampaignSelect';
import {useRoutesMatch} from '@ideascale/commons/dist/hooks/useRoutesMatch';
import {useAppContext} from 'contexts/AppContext';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';

type CampaignSelectionDropdownProps = {
    localizer: Localizer;
    fetchTopBarCampaigns: (pageParameters: PageParameters) => Promise<PagedResponseContent<CampaignsHolder<Campaign>>>;
}

const LEADERBOARD_ROUTES = [ROUTES.LEADERBOARD.BASE, ROUTES.LEADERBOARD.WITH_TYPE];
const LANDING_PAGE_ROUTES = [ROUTES.LANDING, ROUTES.LANDING_PAGE_ACTION];

export const PageNavigationDropdown = (props: CampaignSelectionDropdownProps) => {
    const {localizer, fetchTopBarCampaigns} = props;
    const navigate = useNavigate();
    const {currentCampaign, communityConfig, authentication} = useAppContext();
    const profileRouteMatch = useRoutesMatch(ROUTES.PROFILE.BASE_WITH_WILDCARD);
    const tagsRouteMatch = useRoutesMatch(ROUTES.TAGS);
    const searchRouteMatch = useRoutesMatch(ROUTES.SEARCH);
    const leaderboardRouteMatch = useRoutesMatch(LEADERBOARD_ROUTES);
    const landingPageRouteMatch = useRoutesMatch(LANDING_PAGE_ROUTES);
    const newFeatureRouteMatch = useRoutesMatch(ROUTES.NEW_FEATURES);
    const inviteFriendsRouteMatch = useRoutesMatch(ROUTES.INVITE_FRIENDS);
    const homeRouteMatch = useRoutesMatch(ROUTES.HOME);
    const communityTosRouteMatch = useRoutesMatch(ROUTES.MEMBERSHIP_TOS_VIEW);
    const selectableRoutePages = useMemo(() => ({
        landing: {
            value: -1,
            label: localizer.msg('page.landing'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                className="homepage" iconName="page-book-open"
                groupName={localizer.msg('page.landing')}
            />
        },
        leaderboard: {
            value: -2,
            label: localizer.msg('sidebar.leaderboard.leaderboard'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                className="leaderboard" iconName="leaderboard-numbers-solid"
                groupName={localizer.msg('sidebar.leaderboard.leaderboard')}
            />
        },
        profile: {
            value: -3,
            label: localizer.msg('common.profile'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                className="profile" iconName="person-solid"
                groupName={localizer.msg('common.profile')}
            />
        },
        search: {
            value: -4,
            label: localizer.msg('search.search-result'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                className="search" iconName="magnifying-glass-graph"
                groupName={localizer.msg('search.search-result')}
            />
        },
        newFeature: {
            value: -5,
            label: localizer.msg('new-features.heading'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                className="new-feature-icon" iconName="birthday-cake"
                groupName={localizer.msg('new-features.heading')}
            />
        },
        inviteFriends: {
            value: -6,
            label: localizer.msg('invite-friends.heading'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                iconName="person-plus"
                groupName={localizer.msg('invite-friends.heading')}
            />
        },
        tags: {
            value: -7,
            label: localizer.msg('common.tags'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                iconName="tag"
                groupName={localizer.msg('common.tags')}
            />
        },
        communityTos: {
            value: -8,
            label: localizer.msg('terms-of-service.heading'),
            labelAsJsx: <CampaignDropdownHeader
                svgIconsPath={svgIconsPath}
                iconName="clipboard-lines"
                groupName={localizer.msg('terms-of-service.heading')}
            />
        }
    }), [localizer]);

    const homePages: GroupOptionType = useMemo(() => {
        const defaultPages = {
            label: '',
            options: [
                {
                    value: 0,
                    label: localizer.msg('page.home'),
                    labelAsJsx: <CampaignDropdownHeader
                        svgIconsPath={svgIconsPath}
                        className="homepage" iconName="house"
                        groupName={localizer.msg('page.home')}
                    />
                },
                {
                    value: -2,
                    label: localizer.msg('sidebar.leaderboard.leaderboard'),
                    labelAsJsx: <CampaignDropdownHeader
                        svgIconsPath={svgIconsPath}
                        className="leaderboard" iconName="leaderboard-numbers-solid"
                        groupName={localizer.msg('sidebar.leaderboard.leaderboard')}
                    />
                }
            ],
            separator: <div className="divider"/>
        };

        if (communityConfig.customLandingPageEnabled || authentication.community?.landingPagePublished || landingPageRouteMatch) {
            defaultPages.options.splice(1, 0, selectableRoutePages.landing);
        }
        return defaultPages;
    }, [authentication.community?.landingPagePublished, communityConfig.customLandingPageEnabled, landingPageRouteMatch, localizer, selectableRoutePages.landing]);

    const [selectedOption, setSelectedOption] = useState(homePages.options[0]);

    const prepareSelectedCampaign = useCallback((campaign: Campaign) => {
        return {
            value: campaign.id,
            label: campaign.name,
            labelAsJsx: <CampaignDropdownLabel campaign={campaign}
                                               classificationEnabled={communityConfig.classificationEnabled}/>
        };
    }, [communityConfig.classificationEnabled]);

    useEffect(() => {
        if (profileRouteMatch) {
            setSelectedOption(selectableRoutePages.profile);
        } else if (landingPageRouteMatch) {
            setSelectedOption(selectableRoutePages.landing);
        } else if (leaderboardRouteMatch) {
            setSelectedOption(selectableRoutePages.leaderboard);
        } else if (searchRouteMatch) {
            setSelectedOption(selectableRoutePages.search);
        } else if (newFeatureRouteMatch) {
            setSelectedOption(selectableRoutePages.newFeature);
        } else if (currentCampaign) {
            setSelectedOption(prepareSelectedCampaign(currentCampaign));
        } else if (inviteFriendsRouteMatch) {
            setSelectedOption(selectableRoutePages.inviteFriends);
        } else if (communityTosRouteMatch) {
            setSelectedOption(selectableRoutePages.communityTos);
        } else if (tagsRouteMatch) {
            setSelectedOption(selectableRoutePages.tags);
        } else {
            setSelectedOption(homePages.options[0]);
        }
    }, [currentCampaign, homePages.options, landingPageRouteMatch, leaderboardRouteMatch, newFeatureRouteMatch, profileRouteMatch, searchRouteMatch, inviteFriendsRouteMatch, homeRouteMatch, selectableRoutePages.landing, selectableRoutePages.leaderboard, selectableRoutePages.newFeature, selectableRoutePages.profile, selectableRoutePages.search, selectableRoutePages.inviteFriends, prepareSelectedCampaign, tagsRouteMatch, selectableRoutePages.tags, communityTosRouteMatch, selectableRoutePages.communityTos]);

    const onChangeNavigationDropdown = (value: number) => {
        if (value === 0) {
            setSelectedOption(homePages.options[0]);
            navigate(ROUTES.HOME);
        } else if (value === -1) {
            setSelectedOption(homePages.options[1]);
            if (authentication.community?.landingPagePublished) {
                navigate(ROUTES.LANDING);
            } else {
                window.location.assign(communityConfig.url);
            }
        } else if (value === -2) {
            setSelectedOption(homePages.options[2]);
            navigate(ROUTES.LEADERBOARD.BASE);
        } else if (currentCampaign?.id !== value) {
            navigate(appLinks.campaign(String(value)));
        }
    };

    return (
        <Fragment>
            <label className="sr-only" htmlFor="page-navigation-campaign-dropdown">
                {localizer.msg('common.select-campaign')}
            </label>
            <CampaignSelect
                localizer={localizer}
                tabSelectsValue={false}
                svgIconsPath={svgIconsPath}
                classificationEnabled={communityConfig.classificationEnabled}
                isMulti={false}
                value={selectedOption}
                fetchCampaigns={fetchTopBarCampaigns}
                inputId="page-navigation-campaign-dropdown"
                className={`page-selection-dropdown-container campaign-search-dropdown tour-lucido_campaign_dropdown ${communityConfig.classificationEnabled ? 'classification-topbar-dropdown' : ''}`}
                extraOptionsStart={homePages}
                onSelect={(option) => onChangeNavigationDropdown((option as OptionType).value)}/>
        </Fragment>
    );
};