import React, {Fragment, lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useIntersectionObserver} from '@ideascale/commons/dist/hooks/useIntersectionObserver';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {appLinks} from 'services/AppLinks';
import {useAppContext} from 'contexts/AppContext';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useIdeaListContext} from 'contexts/IdeaListContext';
import {useEditModeContext} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useIdeaService} from 'hooks/useIdeaService';
import {useIdeaViewMode} from 'hooks/useIdeaViewMode';
import {useMessageService} from 'hooks/useMessageService';
import {useIdeaListInfiniteScroll} from 'hooks/useIdeaListInfiniteScroll';
import {useIdeaListMode} from 'hooks/useIdeaListMode';
import {useIdeasPageRouteMatch} from 'hooks/useIdeasPageRouteMatch';
import {IdeaList} from 'components/idea/list/IdeaList';
import {IdeaListSkeleton} from 'components/idea/list/IdeaListSkeleton';
import {ROUTES} from 'shared/Routes';
import {QUERY_KEYS, STAGE_KEYS} from 'constants/AppConstants';
import {OrderBy} from 'models/enums/OrderBy';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {CommunityEditableFieldType} from 'models/edit-mode/CommunityEditableFieldType';

const IdeaListSelectedTag = lazy(() => import('components/idea/list/IdeaListSelectedTag').then(module => ({default: module.IdeaListSelectedTag})));
const IdeaListSelectedLabel = lazy(() => import('components/idea/list/IdeaListSelectedLabel').then(module => ({default: module.IdeaListSelectedLabel})));
const IdeaListNavigation = lazy(() => import('components/idea/list/IdeaListNavigation').then(module => ({default: module.IdeaListNavigation})));

type IdeaListContainerProps = {
    campaignId: string | 'active' | 'expired',
    stageKey: string;
    urlOrder: string;
    urlTag: string;
    urlModeratorTag: string;
    urlLabel: string;
}

export const IdeaListContainer = (props: IdeaListContainerProps) => {
    const {
        campaignId,
        stageKey,
        urlOrder,
        urlTag,
        urlModeratorTag,
        urlLabel
    } = props;
    const {editModeEnabled, communityConfig: {ideaListViewMode, defaultIdeaListMode}, authentication} = useAppContext();
    const {homeConfig} = useEditModeContext();
    const localizer = useLocalizer();
    const {setViewMode} = useIdeaViewMode();
    const navigate = useNavigate();
    const urlQuery = useUrlQuery();
    const {orderBy, setOrderBy} = useIdeaListContext();
    const {ideaListData} = useIdeasContext();
    const {getIdeaListModes} = useIdeaListMode();
    const [ideaMode, setIdeaMode] = useState<IdeaListMode>(defaultIdeaListMode);
    const [ideaViewMode, setIdeaViewMode] = useState<IdeaListViewMode>(ideaListViewMode);
    const {routeParams: {mode}} = useIdeasPageRouteMatch(ROUTES.CAMPAIGN.IDEAS);

    const {
        fetchMembers,
        fetchAssignedOwners,
        emailIdeaAuthor,
        fetchAuthorEmails,
        banMember,
        toggleIdeaFollowed,
        toggleAuthorFollowed,
        toggleCommentingEnabled,
        fetchModerateActions,
        fetchMoreActions,
        fetchIdeaLabels,
        togglePinIdeas,
        getAuthorEmailIdeaContent,
    } = useIdeaService();
    const {fetchRecipients} = useMessageService();
    const {updateIdeaListData, setIdeaListFilterQueryKey, clearIdeaListCachedData} = useIdeasContext();
    const fetchListPageEnabledRef = useRef(false);

    const getIdeaListQueryKey = useMemo(() => {
        return [QUERY_KEYS.IDEA_LIST, {
            campaignId: campaignId,
            stageKey: stageKey === STAGE_KEYS.ALL_STAGES ? STAGE_KEYS.UNSPECIFIED : stageKey,
            mode: ideaMode,
            order: orderBy,
            tag: urlTag,
            moderatorTag: urlModeratorTag,
            label: urlLabel,
            ideaListViewMode
        }];
    }, [campaignId, ideaListViewMode, ideaMode, orderBy, stageKey, urlLabel, urlModeratorTag, urlTag]);

    const ideaListInfiniteScrollData = useIdeaListInfiniteScroll(getIdeaListQueryKey, fetchListPageEnabledRef.current);

    const {
        pagedIdeas,
        isLoading,
        hasNextPage,
        hasPreviousPage,
        isFetchingPreviousPage,
        isFetchingNextPage,
        fetchNextPage,
        fetchPreviousPage
    } = ideaListData;

    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const loadPrevButtonRef = useRef<HTMLButtonElement>(null);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage || false,
        options: {
            rootMargin: '350px 0px 0px'
        }
    });

    useIntersectionObserver({
        target: loadPrevButtonRef,
        onIntersect: fetchPreviousPage,
        enabled: hasPreviousPage || false
    });

    const ideaList = useMemo(() => {
        let ideas: IdeaSummary[] | undefined = [];
        if (pagedIdeas) {
            pagedIdeas.pages.forEach((page: any) => {
                if (page?.data as IdeaSummary[]) {
                    ideas?.push(...page?.data as IdeaSummary[]);
                }
            });
        }
        return ideas;
    }, [pagedIdeas]);

    const onToggleOrder = useCallback(() => {
        clearIdeaListCachedData();
        const order = orderBy === OrderBy.DESC ? OrderBy.ASC : OrderBy.DESC;
        setOrderBy(order);

        const newUrlQuery = new URLSearchParams(urlQuery);
        newUrlQuery.set(ROUTES.QUERY_PARAMS.ORDER, order);
        navigate({
            pathname: appLinks.ideas(campaignId, stageKey, mode),
            search: newUrlQuery.toString()
        });
    }, [campaignId, clearIdeaListCachedData, mode, navigate, orderBy, setOrderBy, stageKey, urlQuery]);

    const hasCampaign = () => {
        return !isNaN(+campaignId);
    };

    const onChangeMode = useCallback((mode: IdeaListMode) => {
        setIdeaMode(mode);
        clearIdeaListCachedData();
        if (!urlOrder) {
            if (mode === IdeaListMode.RECOMMENDED) {
                setOrderBy(OrderBy.ASC);
            } else {
                setOrderBy(OrderBy.DESC);
            }
        }
        navigate({
            pathname: appLinks.ideas(campaignId, stageKey, mode),
            search: urlQuery.toString()
        });
    }, [campaignId, clearIdeaListCachedData, navigate, setOrderBy, stageKey, urlOrder, urlQuery]);

    useEffect(() => {
        setIdeaListFilterQueryKey(getIdeaListQueryKey);
    }, [getIdeaListQueryKey, setIdeaListFilterQueryKey]);

    useEffect(() => {
        setIdeaViewMode(ideaListViewMode);
    }, [ideaListViewMode]);

    useEffect(() => {
        updateIdeaListData({
            pagedIdeas: ideaListInfiniteScrollData.data,
            totalIdeas: ideaListInfiniteScrollData.data?.pages[0]?.totalIdeas || 0,
            isLoading: ideaListInfiniteScrollData.isLoading,
            refetch: ideaListInfiniteScrollData.refetch,
            hasNextPage: ideaListInfiniteScrollData.hasNextPage || false,
            hasPreviousPage: ideaListInfiniteScrollData.hasPreviousPage || false,
            isFetchingPreviousPage: ideaListInfiniteScrollData.isFetchingPreviousPage,
            isFetchingNextPage: ideaListInfiniteScrollData.isFetchingNextPage,
            fetchNextPage: ideaListInfiniteScrollData.fetchNextPage,
            fetchPreviousPage: ideaListInfiniteScrollData.fetchPreviousPage,
        });
    }, [ideaListInfiniteScrollData.data, ideaListInfiniteScrollData.fetchNextPage, ideaListInfiniteScrollData.fetchPreviousPage, ideaListInfiniteScrollData.hasNextPage, ideaListInfiniteScrollData.hasPreviousPage, ideaListInfiniteScrollData.isFetchingNextPage, ideaListInfiniteScrollData.isFetchingPreviousPage, ideaListInfiniteScrollData.isLoading, ideaListInfiniteScrollData.refetch, updateIdeaListData]);

    useEffect(() => {
        fetchListPageEnabledRef.current = true;
        if (mode && mode !== 'unspecified' && getIdeaListModes().includes(mode)) {
            setIdeaMode(mode);
        } else {
            setIdeaMode(defaultIdeaListMode);
        }
        return () => {
            fetchListPageEnabledRef.current = false;
        };
    }, [defaultIdeaListMode, getIdeaListModes, mode]);

    useEffect(() => {
        if (urlOrder) {
            setOrderBy(urlOrder as OrderBy);
        }
    }, [setOrderBy, urlOrder]);

    useEffect(() => {
        if (!urlOrder) {
            if (mode === IdeaListMode.RECOMMENDED && orderBy !== OrderBy.ASC) {
                setOrderBy(OrderBy.ASC);
            } else if (mode !== IdeaListMode.RECOMMENDED) {
                setOrderBy(OrderBy.DESC);
            }
        }
    }, [mode, orderBy, setOrderBy, urlOrder]);

    useEffect(() => {
        if (editModeEnabled) {
            if (homeConfig.isOperationAllowed(CommunityEditableFieldType.IDEA_LIST_MODE)) {
                setIdeaMode(homeConfig.getConfigField(CommunityEditableFieldType.IDEA_LIST_MODE).value.toLowerCase() as IdeaListMode);
            }
            if (homeConfig.isOperationAllowed(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE)) {
                setIdeaViewMode(homeConfig.getConfigField(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE).value as IdeaListViewMode);
            }
        }
    }, [editModeEnabled, homeConfig]);

    return (
        <div className="idea-list">
            <Suspense>
                <IdeaListNavigation localizer={localizer}
                                    ideaMode={ideaMode}
                                    ideaModeEditAllowed={homeConfig.isOperationAllowed(CommunityEditableFieldType.IDEA_LIST_MODE)}
                                    onChangeMode={onChangeMode}
                                    orderBy={orderBy}
                                    toggleOrder={onToggleOrder}
                                    listViewMode={authentication.isAuthenticated() ? ideaViewMode : null}
                                    listViewModeEditAllowed={homeConfig.isOperationAllowed(CommunityEditableFieldType.IDEA_LIST_VIEW_MODE)}
                                    onClickViewMode={setViewMode}/>
            </Suspense>

            {
                (urlTag || urlModeratorTag) &&
                <Suspense>
                    <IdeaListSelectedTag urlModeratorTagString={urlModeratorTag || ''}
                                         urlTagString={urlTag || ''}
                                         totalIdeasCount={ideaListData.totalIdeas}
                                         hasCampaign={hasCampaign()}/>
                </Suspense>
            }

            {
                urlLabel && ideaListData.totalIdeas > 0 &&
                <Suspense>
                    <IdeaListSelectedLabel labeledIdea={ideaList[0]} labelKey={urlLabel}
                                           count={ideaListData.totalIdeas}/>
                </Suspense>
            }

            {
                isLoading
                    ? <IdeaListSkeleton currentViewMode={ideaViewMode}/>
                    : <Fragment>
                        {
                            isFetchingPreviousPage
                                ? <IdeaListSkeleton currentViewMode={ideaViewMode}/>
                                :
                                hasPreviousPage &&
                                <div className="text-center">
                                    <button className="btn btn-gray mb-3" ref={loadPrevButtonRef}
                                            onClick={() => fetchPreviousPage()}>
                                        {localizer.msg('idea-list.load-previous')}
                                    </button>
                                </div>
                        }

                        <IdeaList className={`${hasCampaign() ? 'campaign-ideas' : ''}`} localizer={localizer}
                                  ideas={ideaList}
                                  listViewMode={ideaViewMode}
                                  fetchModerateActions={fetchModerateActions}
                                  fetchMoreActions={fetchMoreActions}
                                  fetchIdeaLabels={fetchIdeaLabels}
                                  toggleIdeaFollowed={toggleIdeaFollowed}
                                  toggleAuthorFollowed={toggleAuthorFollowed}
                                  toggleCommentingEnabled={toggleCommentingEnabled}
                                  fetchMembers={fetchMembers}
                                  fetchAssignedOwners={fetchAssignedOwners}
                                  fetchRecipients={fetchRecipients}
                                  emailIdeaAuthor={emailIdeaAuthor}
                                  fetchAuthorEmails={fetchAuthorEmails}
                                  banMember={banMember}
                                  togglePinIdeas={togglePinIdeas}
                                  getAuthorEmailIdeaContent={getAuthorEmailIdeaContent}
                                  isDataFetching={isLoading}
                        />

                        {
                            isFetchingNextPage
                                ? <IdeaListSkeleton currentViewMode={ideaViewMode}/>
                                :
                                hasNextPage &&
                                <div className="text-center mt-3">
                                    <button className="btn btn-gray" ref={loadMoreButtonRef}
                                            onClick={() => fetchNextPage()}>
                                        {localizer.msg('idea-list.load-next')}
                                    </button>
                                </div>
                        }
                    </Fragment>
            }
        </div>
    );
};