import {useCallback} from 'react';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {PageParameters} from 'models/types/PageParameters';
import {LinkableIdea} from 'models/LinkableIdea';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';

export const useLinkIdeas = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    const fetchLinkableIdeas = useCallback(async (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters): Promise<PagedResponseContent<LinkableIdea>> => {
        if (communityService !== null) {
            return await communityService.fetchLinkableIdeas(ideaId, ideaTitle, ideaDescription, pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const fetchLinkableIdeasByKeywords = useCallback(async (ideaId: number, pageParameters: PageParameters): Promise<PagedResponseContent<LinkableIdea>> => {
        if (communityService !== null) {
            return await communityService.fetchLinkableIdeasByKeywords(ideaId, pageParameters);
        }
        return PagedResponseContent.nullObject();
    }, [communityService]);

    const saveQualifierLinkedIdeas = useCallback(async (ideaId: number, linkedIdeas: QualifierLinkedIdeas[]) => {
        try {
            if (communityService !== null) {
                return await communityService.saveLinkableIdeas(ideaId, linkedIdeas);
            }
        } catch(e: any) {
            handleErrorResponse(e);
        }

    }, [communityService, handleErrorResponse]);

    const unlinkIdea = useCallback(async (ideaId: number, likedIdeaId: number, linkQualifierId: number) => {
        try {
            if (communityService !== null) {
                await communityService.unlinkIdea(ideaId, likedIdeaId, linkQualifierId);
            }
        } catch(e: any) {
            handleErrorResponse(e);
        }
    }, [communityService, handleErrorResponse]);

    return {fetchLinkableIdeas, fetchLinkableIdeasByKeywords, saveQualifierLinkedIdeas, unlinkIdea};
};