import React, {useCallback} from 'react';
import {QuickAccessibleMenu} from '@ideascale/commons/dist/components/QuickAccessibleMenu';
import {SidebarContainer} from './sidebar/SidebarContainer';

type PageLayoutContainerProps = {
    mainContent: React.ReactNode;
    sidebar?: JSX.Element;
    className?: string;
}

const skipMainContentId = 'main-content';
const skipSidebarId = 'sidebar-wrapper';

export const PageLayoutContainer = (props: PageLayoutContainerProps) => {
    const {mainContent, sidebar, className = ''} = props;
    const getQuickAccessMenuItems = useCallback(() => {
        const menuItems = [{targetId: skipMainContentId, label: 'Skip to main content', className: ''}];
        if (sidebar) {
            menuItems.push({targetId: skipSidebarId, label: 'Skip to sidebar', className: 'd-none d-lg-block'});
        }
        return menuItems;
    }, [sidebar]);

    return (
        <div className={`container mt-3 px-sm-0 ${className}`}>
            <QuickAccessibleMenu items={getQuickAccessMenuItems()}/>
            <div className="row">
                <section className={`col-12 ${!!sidebar ? 'col-main-content col-9' : ''}`}>
                    <main className="main-content" id={skipMainContentId} tabIndex={-1}>
                        {mainContent}
                    </main>
                </section>
                {!!sidebar && <SidebarContainer>
                    {sidebar}
                </SidebarContainer>}
            </div>
        </div>
    );
};
