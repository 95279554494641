import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {InfiniteScrollLoadMoreButton} from '@ideascale/commons/dist/components/InfiniteScrollLoadMoreButton';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {LayoutUtil} from '@ideascale/commons/dist/utils/LayoutUtil';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useDropdownFocus} from '@ideascale/commons/dist/hooks/useDropdownFocus';
import {useIntersectionObserver} from '@ideascale/commons/dist/hooks/useIntersectionObserver';
import {PagedResponseContent} from '@ideascale/commons/dist/models/PagedResponseContent';
import {useAppContext} from 'contexts/AppContext';
import {appLinks} from 'services/AppLinks';
import {PageParameters} from 'models/types/PageParameters';
import {CustomPageData} from 'models/CustomPageData';
import 'components/topbar/CustomPageMenu.scss';

type CustomPageProps = {
    localizer: Localizer;
    focusId: string;
    fetchTopBarCustomPages: (pageParameters: PageParameters) => Promise<PagedResponseContent<CustomPageData>>;
    createCustomPageEnabled: boolean;
}

export const CustomPageMenu = (props: CustomPageProps) => {
    const {fetchTopBarCustomPages, localizer, focusId, createCustomPageEnabled} = props;
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const {authentication} = useAppContext();
    const [customPages, setCustomPages] = useState<CustomPageData[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [scrollableContentCssClass, setScrollableContentCssClass] = useState('');
    const [loading, setLoading] = useState(true);
    const {focus} = useDropdownFocus();

    const loadCustomPages = useCallback(async () => {
        try {
            const pagedResponse = await fetchTopBarCustomPages({page: currentPage, limit: 10});
            setCurrentPage(currentPage + 1);
            setHasMore(pagedResponse.hasMore);
            setLoading(false);
            setCustomPages([...customPages, ...pagedResponse.content]);

            if (currentPage === 0 && pagedResponse.hasMore) {
                setScrollableContentCssClass('scrollable-content');
            }
            focus('#' + focusId);
        } catch (e: any) {
            handleErrorResponse(e);
        }
    }, [currentPage, customPages, fetchTopBarCustomPages, focus, focusId, handleErrorResponse]);

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadCustomPages,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        if (currentPage === 0) {
            loadCustomPages().then();
        }
    }, [currentPage, loadCustomPages]);

    return (
        <Scrollbar
            className={`scrollable-menu ${scrollableContentCssClass} custom-page-container`}
            autoHeight
            autoHeightMax={LayoutUtil.isMobileLayout() ? '100vh' : '40vh'}
            ref={scrollbarContainer}>
            <div className="my-1">
                {
                    loading ?
                        <div className="p-3 bg-transparent dropdown-item">
                            <ParagraphSkeleton rows={4}/>
                        </div> :
                        customPages.length > 0 ?
                            customPages.map(customPage => (
                                    <a className={`dropdown-item ${!customPage.visited ? 'text-link' : ''}`}
                                       title={customPage.name} href={customPage.url} key={customPage.id}>
                                        {customPage.name}
                                    </a>
                                )
                            )
                            : <div className="p-3 no-page bg-transparent">
                                    <span>
                                    {
                                        authentication.isAdmin() ?
                                            localizer.msg('topbar.custom-page.no-page.admin')
                                            : localizer.msg('topbar.custom-page.no-page.non-admin')
                                    }
                                    </span>
                                {
                                    createCustomPageEnabled &&
                                    <a href={appLinks.manageLookAndFeel}
                                       className="bg-transparent mt-4 d-block text-center dropdown-item">
                                        {localizer.msg('topbar.custom-page.create-custom-page')}
                                    </a>
                                }
                            </div>
                }
                <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                              loading={false}
                                              localizer={localizer}
                                              onCLick={loadCustomPages}
                                              loadMoreButtonRef={loadMoreButtonRef}/>
            </div>
        </Scrollbar>
    );
};