import {SharedRoutes} from '@ideascale/commons/dist/shared/SharedRoutes';

export const ROUTES = {
    HOME: '/home',
    JOIN_COMMUNITY: '/join-community',
    LANDING: '/landing',
    LANDING_PAGE_ACTION: '/landing/mode/:mode/page/:pageId',
    ALL_ACTIVE_IDEAS: '/campaigns/active/stage/all/ideas/:mode',
    ALL_EXPIRED_IDEAS: '/campaigns/expired/stage/all/ideas/:mode',
    CAMPAIGN_TOS: '/campaign/:campaignId/campaigns-tos',
    MEMBERSHIP_TOS: '/membership/tos',
    MEMBERSHIP_TOS_VIEW: '/membership/tos/view',
    TAGS: '/tags',
    CAMPAIGN_TAGS: '/campaigns/:campaignId/tags',
    IDEA_DETAILS_COMMENTS: '/idea/:ideaId/comments',
    IDEA_DETAILS_COMMENT: '/idea/:ideaId/comments/:commentId',
    IDEA_DETAILS_STAGE_COMMENT: '/idea/:ideaId/stage/:stageId/comments',
    MY_IDEAS: '/profile/:memberId/my-ideas/:myIdeasMode/stage/:stageKey/ideas/:mode',
    MESSAGE_INBOX: '/profile/:memberId/messages/inbox',
    MESSAGE_SENT: '/profile/:memberId/messages/sent',
    MESSAGE_SENT_DETAILS: '/profile/:memberId/messages/sent/:messageId',
    CAMPAIGN_PERFORMANCE: '/campaign-performance/:campaignId/member/:memberId/:type/:year/:number',
    DIGEST_SHARE: '/campaign-performances/:type/:year/:number',
    MESSAGE_COMPOSE: '/profile/:memberId/messages/compose',
    IDEA_SUBMISSION: '/idea-submission',
    DRAFT_IDEAS: '/ideas/draft',
    DRAFT: '/draft/:id',
    COMMUNITY_LOGIN: '/community-login',
    UNAUTHORIZED: '/unauthorized',
    SEARCH: '/search',
    NEW_FEATURES: '/new-features',
    INVITE_FRIENDS: '/invite-friends',
    QUERY_PARAMS: {
        ORDER: 'order',
        TAG: 'tag',
        MODERATOR_TAG: 'moderator-tag',
        QUERY: 'query',
        SUBMISSION_FORM: 'submission-form',
        LABEL: 'label',
        DRAFT_IDEA: 'draft',
        SUBMITTER_ID: 'submitter',
        CAMPAIGN: 'campaign',
        EMAIL_IDEA: 'email-idea',
        ADVANCED_SEARCH_QUERY: 'query',
        PROFILE_FIELD_EDIT: 'edit',
        MODE: 'mode',
        PAGE: 'page',
        TOS_REDIRECT_URL: 'redirectUrl',
    },
    SEARCH_QUERY: {
        EDIT_MODE: 'editMode'
    },

    LEADERBOARD: {
        BASE: '/leaderboard',
        BASE_ROUTE: '/leaderboard/*',
        TYPE: ':leaderboardType',
        WITH_TYPE: '/leaderboard/:leaderboardType',
    },

    CAMPAIGN: {
        BASE: '/campaigns/:campaignId',
        BASE_WITH_WILDCARD: '/campaigns/:campaignId/*',
        IDEAS: 'stage/:stageKey/ideas/:mode',
        IDEAS_WITH_WILDCARD: 'stage/:stageKey/ideas/:mode/*',
        IDEAS_PATH: '/campaigns/:campaignId/stage/:stageKey/ideas/:mode',
        ABOUT: 'about',
        ABOUT_PATH: '/campaigns/:campaignId/about',
        TEAM: 'team',
        TEAM_PATH: '/campaigns/:campaignId/team',
        WORKFLOW: 'workflow',
        WORKFLOW_PATH: '/campaigns/:campaignId/workflow',
    },

    PROFILE: {
        BASE: 'profile',
        BASE_WITH_WILDCARD: 'profile/*',
        PROFILE_PATH: SharedRoutes.PROFILE,
        ACTIVITY: ':memberId/activity',
        ACTIVITY_PATH: '/profile/:memberId/activity',
        NOTIFICATIONS: ':memberId/notifications',
        NOTIFICATIONS_PATH: '/profile/:memberId/notifications',
        MY_IDEAS: ':memberId/my-ideas/*',
        MY_IDEAS_PATH: '/profile/:memberId/my-ideas/*',
        MESSAGES: ':memberId/messages/*',
        MESSAGES_PATH: '/profile/:memberId/messages/*',
        INBOX_WITH_WILDCARD: 'inbox/*',
        INBOX: 'inbox/:messageId',
        SENT_WITH_WILDCARD: 'sent/*',
        SENT: 'sent/:messageId',
        COMPOSE: 'compose',
        MEMBER_ID: ':memberId'
    },

    IDEA_DETAILS: {
        BASE: '/idea/:ideaId',
        BASE_WITH_WILDCARD: '/idea/:ideaId/*',
        COMMENTS_WITH_WILDCARD: '/comments/*',
        COMMENT_PATH: '/idea/:ideaId/comments/:commentId',
        ACTIVITY_BASE: '/activity',
        ACTIVITY_PATH: '/idea/:ideaId/activity',
        ATTACHMENTS: '/attachments',
        ATTACHMENTS_PATH: '/idea/:ideaId/attachments',
        STAGE_COMMENTS_BASE: '/idea/:ideaId/stage/:stageId/comments/*',
        STAGE_COMMENTS_PATH: '/idea/:ideaId/stage/:stageId/comments',
        STAGE_COMMENT_PATH: '/idea/:ideaId/stage/:stageId/comments/:commentId',
        STAGE_COMMENTS: '/:stageId/comments',
        STAGE_COMMENTS_WITH_WILDCARD: '/stage/*',
        COMMENT: '/:commentId'
    },

    MESSAGE_INBOX_DETAILS: SharedRoutes.MESSAGE_INBOX_DETAILS,
    MESSAGES: SharedRoutes.MESSAGES,
};