import {useCallback} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {useIdeasContext} from 'contexts/IdeasContext';
import {useIdeaService} from './useIdeaService';
import {useIdeaUpdater} from './useIdeaUpdater';
import {IdeaSummary} from 'models/IdeaSummary';
import {ChangeCampaignParams} from 'models/types/ChangeCampaignParams';
import {IdeaDetail} from 'models/IdeaDetail';
import {QUERY_KEYS} from 'constants/AppConstants';

export const useCampaignChange = (ideaListQueryKey: any = '', selectedIdea?: IdeaSummary) => {
    const {fetchDropdownCampaigns, fetchCampaignStages, changeCampaign} = useIdeaService();
    const queryClient = useQueryClient();
    const {replaceFullIdeaDetails} = useIdeaUpdater();
    const {ideaListFilterQueryKey} = useIdeasContext();

    const ideaListCampaignChangeMutation = useMutation((changeCampaignParams: ChangeCampaignParams) => changeCampaign(changeCampaignParams),
        {
            onSuccess: async (ideaSummary?: IdeaSummary) => {
                ideaSummary && await queryClient.invalidateQueries(ideaListFilterQueryKey);
            },
            onSettled: async (ideaSummary, error) => {
                if (!error && ideaSummary) {
                    await queryClient.invalidateQueries([QUERY_KEYS.MODERATE_ACTIONS, ideaSummary.id]);
                }
            }
        }
    );

    const ideaDetailsCampaignChangeMutation = useMutation((changeCampaignParams: ChangeCampaignParams) => changeCampaign(changeCampaignParams),
        {
            onSuccess: async (ideaDetail?: IdeaDetail) => {
                if (ideaDetail) {
                    await replaceFullIdeaDetails(ideaDetail as IdeaDetail);
                }
            },
            onSettled: async (ideaDetail, error) => {
                if (!error && ideaDetail) {
                    await queryClient.invalidateQueries([QUERY_KEYS.MODERATE_ACTIONS, ideaDetail.id]);
                }
            }
        }
    );

    const isChangeToDifferentFunnel = useCallback((campaign: Campaign) => {
        return selectedIdea?.campaign?.funnelId !== campaign.funnelId;
    }, [selectedIdea?.campaign?.funnelId]);

    const fetchChangeCampaignStages = useCallback(async (campaign: Campaign) => {
        if (selectedIdea) {
            return fetchCampaignStages(selectedIdea.id, campaign.id);
        } else {
            return []
        }
    }, [fetchCampaignStages, selectedIdea])

    return {
        isChangeToDifferentFunnel,
        fetchChangeCampaignStages,
        fetchDropdownCampaigns,
        fetchCampaignStages,
        ideaListCampaignChangeMutation,
        ideaDetailsCampaignChangeMutation
    };
};