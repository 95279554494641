import {EmailRequest} from '@ideascale/commons/dist/models/EmailRequest';
import {PrivateMessageRequestParams} from '@ideascale/commons/dist/models/PrivateMessageRequestParams';
import {useMessageService} from './useMessageService';
import {useIdeaService} from './useIdeaService';
import {IdeaInternalShareRequestParams} from 'models/IdeaInternalShareRequestParams';
import {PageParameters} from 'models/types/PageParameters';

export const useIdeaShare = () => {
    const {onIdeaSendMessages} = useMessageService();
    const {
        emailIdeaByRecipientIds,
        fetchIdeaEmailRecipients,
        emailIdeaByRecipientEmails,
        fetchEmailIdeaContent
    } = useIdeaService();

    const ideaShareMessagesSend = (requestParams: PrivateMessageRequestParams, ideaId: number) => {
        const sendMessageRequestPayload = new IdeaInternalShareRequestParams(
            requestParams.recipientIds,
            requestParams.subject,
            requestParams.body,
            ideaId,
            requestParams.attributeApiParameters
        );
        return onIdeaSendMessages(sendMessageRequestPayload);
    };

    const emailIdeaSendByRecipientIds = (requestParams: EmailRequest, ideaId: number) => {
        return emailIdeaByRecipientIds(requestParams, ideaId);
    };

    const emailIdeaSendByRecipientEmails = (requestParams: EmailRequest, ideaId: number) => {
        return emailIdeaByRecipientEmails(requestParams, ideaId);
    };

    const getIdeaEmailRecipients = (ideaId: number, parameters: PageParameters) => {
        return fetchIdeaEmailRecipients(ideaId, parameters);
    };

    const getEmailIdeaContent = (ideaId: number) => {
        return fetchEmailIdeaContent(ideaId);
    };

    return {
        ideaShareMessagesSend,
        emailIdeaSendByRecipientIds,
        emailIdeaSendByRecipientEmails,
        getIdeaEmailRecipients,
        getEmailIdeaContent
    };
};