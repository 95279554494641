import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Button, ParagraphSkeleton, Scrollbar, ScrollbarInstance} from '@ideascale/ui';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {InfiniteScrollLoadMoreButton} from '@ideascale/commons/dist/components/InfiniteScrollLoadMoreButton';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {LayoutUtil} from '@ideascale/commons/dist/utils/LayoutUtil';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useDropdownFocus} from '@ideascale/commons/dist/hooks/useDropdownFocus';
import {useIntersectionObserver} from '@ideascale/commons/dist/hooks/useIntersectionObserver';
import {PagedResponseContent} from '@ideascale/commons/dist/models/PagedResponseContent';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {PageParameters} from 'models/types/PageParameters';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import './LabelsMenu.scss';

type LabelsMenuProps = {
    localizer: Localizer
    focusId: string;
    fetchTopBarLabels: (pageParameters: PageParameters) => Promise<PagedResponseContent<LabelData>>;
}

export const LabelsMenu = (props: LabelsMenuProps) => {
    const {fetchTopBarLabels, localizer, focusId} = props;
    const {ideaLabelRouteChange} = useIdeaLabels();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const scrollbarContainer = useRef<ScrollbarInstance>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
    const [labelDataList, setLabelDataList] = useState<LabelData[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [scrollableContentCssClass, setScrollableContentCssClass] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const {focus} = useDropdownFocus();

    const loadLabels = useCallback(async () => {
        try {
            const pagedResponse = await fetchTopBarLabels({page: currentPage, limit: 10});
            setCurrentPage(currentPage + 1);
            setHasMore(pagedResponse.hasMore);
            setLoading(false);
            setLabelDataList([...labelDataList, ...pagedResponse.content]);

            if (currentPage === 0 && pagedResponse.hasMore) {
                setScrollableContentCssClass('label-scrollable-content');
            }
            focus('#' + focusId);
        } catch (e: any) {
            handleErrorResponse(e);
        }
    }, [currentPage, fetchTopBarLabels, focus, focusId, handleErrorResponse, labelDataList]);

    const onLabelClick = (event: React.MouseEvent, labelData: LabelData) => {
        event.stopPropagation();
        event.preventDefault();
        ideaLabelRouteChange(labelData);
        eventDispatcher.dispatch(SCROLL_EVENTS.SCROLL_TOP);
    };

    useIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: loadLabels,
        enabled: hasMore,
        options: {
            root: scrollbarContainer.current?.container
        }
    });

    useEffect(() => {
        if (currentPage === 0) {
            loadLabels().then();
        }
    }, [currentPage, loadLabels]);

    return (
        <Fragment>
            <span className="fw-bold ms-3 mb-2 d-none d-lg-inline-block">
                {localizer.msg('topbar.label.title')}
            </span>
            <Scrollbar className={`scrollable-menu label-scrollable-menu mt-1 ${scrollableContentCssClass}`}
                       autoHeight
                       autoHeightMax={LayoutUtil.isMobileLayout() ? '100vh' : '40vh'}
                       ref={scrollbarContainer}>
                <div className="pe-2 py-1">
                    {
                        loading ?
                            <div className="p-3 bg-transparent dropdown-item">
                                <ParagraphSkeleton rows={6}/>
                            </div> :
                            labelDataList.length > 0
                                ? labelDataList.map(labelData => (
                                    <Button color="default"
                                            className={'label-dropdown-item dropdown-item bg-transparent'}
                                            key={labelData.id}
                                            value={labelData.id}
                                            onClick={(event) => onLabelClick(event, labelData)}>
                                        <span
                                            className={`label customize-label text-truncate label-field-${labelData.colorKey}`}>{labelData.name}</span>
                                    </Button>))
                                : <Button color="default" className="p-3 text-muted dropdown-item"
                                          disabled={true}>
                                    {localizer.msg('topbar.label.no-label')}
                                </Button>
                    }
                    <InfiniteScrollLoadMoreButton hasMore={hasMore}
                                                  localizer={localizer}
                                                  loading={false}
                                                  onCLick={loadLabels}
                                                  loadMoreButtonRef={loadMoreButtonRef}/>
                </div>
            </Scrollbar>
        </Fragment>
    );
};