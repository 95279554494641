import {Fragment, lazy, useCallback, useMemo} from 'react';
import {useQuery} from 'react-query';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {Backdrop} from '@ideascale/commons/dist/components/shared/Backdrop';
import {EmailRequest} from '@ideascale/commons/dist/models/EmailRequest';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {ParagraphSkeleton} from '@ideascale/commons/dist/components/shared/skeleton/ParagraphSkeleton';
import {PrivateMessageRequestParams} from '@ideascale/commons/dist/models/PrivateMessageRequestParams';
import {useDropdownFocus} from '@ideascale/commons/dist/hooks/useDropdownFocus';
import {useRoutesMatch} from '@ideascale/commons/dist/hooks/useRoutesMatch';
import {useToggle} from '@ideascale/commons/dist/hooks/useToggle';
import {useAppContext} from 'contexts/AppContext';
import {useSocialShareServices} from 'hooks/useSocialShareServices';
import {useIdeaShare} from 'hooks/useIdeaShare';
import {appLinks} from 'services/AppLinks';
import {ROUTES} from 'shared/Routes';
import {IdeaMoreActionResponse} from 'models/IdeaMoreActionResponse';
import {IdeaSummary} from 'models/IdeaSummary';
import {PageParameters} from 'models/types/PageParameters';
import {DEFAULT_STALE_TIME, QUERY_KEYS} from 'constants/AppConstants';
import './IdeaActions.scss';

const ShareModal = lazy(() => import('../share/ShareModal').then(module => ({default: module.ShareModal})));

type IdeaMoreActionsProps = {
    selectedIdea: IdeaSummary;
    localizer: Localizer;
    permissionData?: IdeaMoreActionResponse;
    fetchMoreActions?: (ideaId: number) => Promise<IdeaMoreActionResponse>;
    toggleShareModal?: () => void;
    toggleAssignOwnerModal: () => void;
    toggleEmailAuthorModal: () => void;
    toggleBanMemberModal: () => void;
    followAuthorAction: (followed: boolean) => void;
    stageId?: number;
}

export const IdeaMoreActions = (props: IdeaMoreActionsProps) => {
    const {
        selectedIdea,
        localizer,
        permissionData,
        toggleShareModal,
        fetchMoreActions,
        toggleAssignOwnerModal,
        toggleEmailAuthorModal,
        toggleBanMemberModal,
        followAuthorAction,
    } = props;
    const ideaDetailsRouteMatch = useRoutesMatch(ROUTES.IDEA_DETAILS.BASE);
    const [dropdownOpen, toggleDropdown] = useToggle(false);
    const [showMenuShareModal, toggleMenuShareModal] = useToggle(false);
    const {communityConfig} = useAppContext();

    const shareableText = communityConfig.url + process.env.PUBLIC_URL + appLinks.ideaDetails(selectedIdea.id);
    const shareableLink = appLinks.ideaDetails(selectedIdea.id);
    const internalMessageAdditionalURL = `<a class= "d-block cursor-pointer" href=${communityConfig.url + process.env.PUBLIC_URL + appLinks.ideaDetails(selectedIdea.id)}>${communityConfig.classificationEnabled ? `<span class='idea-title'> - Go to the Idea</span>` : selectedIdea.title}</a>`;

    const {getSocialIdeaShareURLs} = useSocialShareServices();
    const {
        emailIdeaSendByRecipientIds,
        ideaShareMessagesSend,
        getIdeaEmailRecipients,
        emailIdeaSendByRecipientEmails,
        getEmailIdeaContent
    } = useIdeaShare();

    const {focus} = useDropdownFocus();
    const menuFocusId = useMemo(() => (`idea-${selectedIdea.id}-more-actions-menu`), [selectedIdea.id]);

    const {data, isLoading, isRefetching, isFetching} = useQuery(
        [QUERY_KEYS.MORE_ACTIONS, selectedIdea.id],
        ({queryKey: [, ideaId]}) => fetchMoreActions && fetchMoreActions(Number(ideaId)),
        {
            enabled: !permissionData && dropdownOpen,
            staleTime: DEFAULT_STALE_TIME,
            onSuccess: useCallback(() => focus('#' + menuFocusId, 10), [focus, menuFocusId])
        });

    const moreActionData = useMemo(() => (
        permissionData || data || IdeaMoreActionResponse.EMPTY
    ), [data, permissionData]);

    const hasActions = useCallback(() => {
        return moreActionData.printIdeaAllowed ||
            moreActionData.ideaAuthorFollowAllowed ||
            moreActionData.emailIdeaAllowed ||
            moreActionData.emailAuthorAllowed ||
            moreActionData.assignOwnerAllowed ||
            moreActionData.banMemberAllowed ||
            moreActionData.reviewTranslationUrl ||
            moreActionData.allowedMediaList.length > 0;
    }, [moreActionData.allowedMediaList.length, moreActionData.assignOwnerAllowed, moreActionData.banMemberAllowed, moreActionData.emailAuthorAllowed, moreActionData.emailIdeaAllowed, moreActionData.ideaAuthorFollowAllowed, moreActionData.printIdeaAllowed, moreActionData.reviewTranslationUrl]);

    return (
        <Fragment>
            <ButtonDropdown className="drawer-able more-actions me-n2"
                            drop="down"
                            isOpen={dropdownOpen}
                            toggle={toggleDropdown}>
                <Backdrop/>
                <DropdownToggle tag="button" className="btn p-0"
                                data-test-element-id="btn-more-action"
                                title={localizer.msg('idea.actions.more-actions-title')}>
                    <Icon className="position-relative pos-top-n1 pointer-events-none"
                          name="dots-vertical" width={20}
                          height={20}
                          fill="#666"
                          iconSpritePath={svgIconsPath}/>
                </DropdownToggle>
                <div className="right-panel">
                    <DropdownMenu id={menuFocusId} className="more-dropdown" end>
                        {
                            (isLoading || isRefetching || isFetching)
                                ? <DropdownItem>
                                    <ParagraphSkeleton rows={6}/>
                                </DropdownItem>
                                : <Fragment>
                                    {
                                        selectedIdea.sharable && moreActionData.sharable && !ideaDetailsRouteMatch &&
                                        (moreActionData.emailIdeaAllowed || moreActionData.allowedMediaList.length > 0) &&
                                        <DropdownItem onClick={toggleShareModal || toggleMenuShareModal}
                                                      data-test-element-id="share">
                                            <Icon className="me-2" name="arrow-up-from-tray" fill="#666" width={17} height={17}
                                                  title={localizer.msg('idea.actions.share')}
                                                  iconSpritePath={svgIconsPath}/>
                                            {localizer.msg('idea.actions.share')}
                                        </DropdownItem>
                                    }

                                    {
                                        moreActionData.ideaAuthorFollowAllowed && (
                                            selectedIdea.followingIdeaAuthor ? (
                                                <DropdownItem className="d-flex align-items-center"
                                                              data-test-element-id="unfollow-author"
                                                              onClick={() => followAuthorAction(true)}>
                                                    <Icon className="me-2 active" name="star-solid" fill="#384EC1" width={17}
                                                          height={17}
                                                          iconSpritePath={svgIconsPath}/>
                                                    {localizer.msg('idea.actions.unfollow-author')}
                                                </DropdownItem>
                                            ) : (
                                                <DropdownItem className="d-flex align-items-center"
                                                              data-test-element-id="follow-author"
                                                              onClick={() => followAuthorAction(false)}>
                                                    <Icon className="me-2" name="star-solid" fill="#666" width={17} height={17}
                                                          iconSpritePath={svgIconsPath}/>
                                                    {localizer.msg('idea.actions.follow-author')}
                                                </DropdownItem>
                                            )
                                        )
                                    }

                                    {
                                        moreActionData.printIdeaAllowed && (
                                            <DropdownItem className="d-flex align-items-center" tag="a"
                                                          data-test-element-id="print-idea"
                                                          href={appLinks.printIdea(selectedIdea.id)}>
                                                <Icon className="me-2" name="printer" fill="#666" width={15} height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.print')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moreActionData.reviewTranslationUrl && (
                                            <DropdownItem className="d-flex align-items-center" tag="a"
                                                          data-test-element-id="review-translation"
                                                          href={moreActionData.reviewTranslationUrl}>
                                                <Icon className="me-2" name="google-translate" fill="#666" width={15}
                                                      height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.review-translation')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moreActionData.emailAuthorAllowed && (
                                            <DropdownItem className="d-flex align-items-center" onClick={toggleEmailAuthorModal}
                                                          data-test-element-id="email-author">
                                                <Icon className="me-2" name="envelope" fill="#666" width={15} height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.email-author')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moreActionData.banMemberAllowed && (
                                            <DropdownItem className="d-flex align-items-center" onClick={toggleBanMemberModal}
                                                          data-test-element-id="ban-member">
                                                <Icon className="me-2" name="minus-solid-circle" fill="#666" width={15}
                                                      height={15}
                                                      iconSpritePath={svgIconsPath}/>
                                                {localizer.msg('idea.actions.ban-member')}
                                            </DropdownItem>
                                        )
                                    }

                                    {
                                        moreActionData.assignOwnerAllowed && !ideaDetailsRouteMatch && (
                                            selectedIdea.ownerCount > 0 ? (
                                                <DropdownItem className="d-flex align-items-center"
                                                              onClick={toggleAssignOwnerModal}
                                                              data-test-element-id="change-owner">
                                                    <Icon className="me-2" name="clipboard-lines" fill="#666" width={15}
                                                          height={15}
                                                          iconSpritePath={svgIconsPath}/>
                                                    {localizer.msg('idea.actions.assign-owner.change')}
                                                </DropdownItem>
                                            ) : (
                                                <DropdownItem className="d-flex align-items-center"
                                                              onClick={toggleAssignOwnerModal}
                                                              data-test-element-id="assign-owner">
                                                    <Icon className="me-2" name="clipboard-lines" fill="#666" width={15}
                                                          height={15}
                                                          iconSpritePath={svgIconsPath}/>
                                                    {localizer.msg('idea.actions.assign-owner.assign')}
                                                </DropdownItem>
                                            )
                                        )
                                    }

                                    {
                                        !hasActions() &&
                                        <DropdownItem className="d-flex align-items-center pointer-events-none">
                                            {localizer.msg('idea.actions.no-actions')}
                                        </DropdownItem>
                                    }
                                </Fragment>
                        }
                    </DropdownMenu>
                </div>
            </ButtonDropdown>

            {
                showMenuShareModal &&
                <ShareModal
                    emailShareModalTitle={localizer.msg('idea.actions.email-idea-title')}
                    emailInvitationText={localizer.msg('idea.actions.email.invitation')}
                    shareableText={shareableText}
                    shareableLink={shareableLink}
                    internalShareButtonTitle={localizer.msg('idea.share.copy-title')}
                    open={showMenuShareModal}
                    toggle={toggleMenuShareModal}
                    shareContentId={selectedIdea.id}
                    title={localizer.msg('idea.share-idea')}
                    internalMessageAdditionalURL={internalMessageAdditionalURL}
                    emailIdeaAllowed={moreActionData.emailIdeaAllowed}
                    socialShareURLs={getSocialIdeaShareURLs(moreActionData.allowedMediaList, selectedIdea.title, selectedIdea.id)}
                    shareContentTitle={selectedIdea.title}
                    onSendMessage={(requestParams: PrivateMessageRequestParams) => ideaShareMessagesSend(requestParams, selectedIdea.id)}
                    emailIdeaSendByRecipientIds={(requestParams: EmailRequest) => emailIdeaSendByRecipientIds(requestParams, selectedIdea.id)}
                    emailIdeaSendByRecipientEmails={(requestParams: EmailRequest) => emailIdeaSendByRecipientEmails(requestParams, selectedIdea.id)}
                    getEmailRecipients={(parameters: PageParameters) => getIdeaEmailRecipients(selectedIdea.id, parameters)}
                    getEmailContent={() => getEmailIdeaContent(selectedIdea.id)}
                    sharable={selectedIdea.sharable}
                    campaignId={selectedIdea.campaign?.id}
                    privateCampaign={selectedIdea.campaign?.privateCampaign || false}
                />
            }
        </Fragment>
    );
};