import {LinkedIdea} from './LinkedIdea';
import {LinkIdeaQualifier} from '@ideascale/commons/dist/models/LinkIdeaQualifier';

type SimpleLinkedIdea = {
    ideaId: number;
    linkQualifierId: number;
}

export class QualifierLinkedIdeas {
    constructor(
        public linkQualifier: LinkIdeaQualifier,
        public ideas: LinkedIdea[]
    ) {
    }

    static toSimpleLinkedIdea(qualifierLinkedIdeas: QualifierLinkedIdeas[]): SimpleLinkedIdea[] {
        const linkedIdeas: SimpleLinkedIdea[] = [];
        qualifierLinkedIdeas.forEach((qualifierLinkIdea) => {
            qualifierLinkIdea.ideas.forEach(linkedIdea => {
                linkedIdeas.push({
                    ideaId: linkedIdea.id,
                    linkQualifierId: qualifierLinkIdea.linkQualifier.id
                });
            });
        });
        return linkedIdeas;
    }
}