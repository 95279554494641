import React, {useCallback, useContext, useMemo, useState} from 'react';
import {OptionType} from '@ideascale/ui';
import {SearchCategory} from 'models/enums/SearchCategory';
import {SearchParameters} from 'models/SearchParameters';
import {useAppContext} from './AppContext';

export type CustomFieldOption = {
    field: OptionType;
    values: OptionType[];
}

export type SearchState = {
    searchCategory: SearchCategory;
    term: string;
    fromDate: Date | null;
    toDate: Date | null
    archiveCampaign: boolean;
    privateCampaign: boolean;
    labels: OptionType[];
    campaigns: OptionType[];
    stages: OptionType[];
    tags: OptionType[];
    moderatorTags: OptionType[];
    ideaCustomFields: CustomFieldOption[];
    memberCustomFields: CustomFieldOption[];
    getSearchParams: SearchParameters;
}

type SearchContext = SearchState & {
    setSearchCategory: (category: SearchCategory) => void;
    updateFilters: (params: Partial<SearchState>) => void;
    addTerm: (term: string) => void;
    clearFilters: () => void;
}

const defaultValues: SearchContext = {
    searchCategory: SearchCategory.ALL,
    term: '',
    fromDate: null,
    toDate: null,
    archiveCampaign: false,
    privateCampaign: false,
    labels: [],
    campaigns: [],
    stages: [],
    tags: [],
    moderatorTags: [],
    ideaCustomFields: [],
    memberCustomFields: [],
    setSearchCategory: () => null,
    addTerm: () => null,
    updateFilters: () => null,
    clearFilters: () => null,
    getSearchParams: SearchParameters.builder().build()
};

const ctx = React.createContext<SearchContext>(defaultValues);

export const useSearchContext = () => {
    return useContext(ctx);
};

export const SearchContextProvider = ({children}: { children: React.ReactNode }) => {
    const [searchCategory, setSearchCategory] = useState<SearchCategory>(SearchCategory.ALL);
    const [term, addTerm] = useState<string>(defaultValues.term);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [archiveCampaign, setArchiveCampaign] = useState<boolean>(true);
    const [privateCampaign, setPrivateCampaign] = useState<boolean>(false);
    const [labels, setLabels] = useState<OptionType[]>([]);
    const [campaigns, setCampaigns] = useState<OptionType[]>([]);
    const [stages, setStages] = useState<OptionType[]>([]);
    const [tags, setTags] = useState<OptionType[]>([]);
    const [moderatorTags, setModeratorTags] = useState<OptionType[]>([]);
    const [ideaCustomFields, setIdeaCustomFields] = useState<CustomFieldOption[]>([]);
    const [memberCustomFields, setMemberCustomFields] = useState<CustomFieldOption[]>([]);

    const {authentication} = useAppContext();

    const clearFilters = useCallback(() => {
        addTerm(defaultValues.term);
        setFromDate(defaultValues.fromDate);
        setToDate(defaultValues.toDate);
        setLabels(defaultValues.labels);
        setCampaigns(defaultValues.campaigns);
        setStages(defaultValues.stages);
        setTags(defaultValues.tags);
        setModeratorTags(defaultValues.moderatorTags);
        setIdeaCustomFields(defaultValues.ideaCustomFields);
        setMemberCustomFields(defaultValues.memberCustomFields);
        setArchiveCampaign(defaultValues.archiveCampaign);
        setPrivateCampaign(defaultValues.privateCampaign);
    }, []);

    const updateFilters = useCallback((params: Partial<SearchState>) => {
        addTerm(params.term || defaultValues.term);
        setFromDate(params.fromDate || defaultValues.fromDate);
        setToDate(params.toDate || defaultValues.toDate);
        setLabels(params.labels || defaultValues.labels);
        setCampaigns(params.campaigns || defaultValues.campaigns);
        setStages(params.stages || defaultValues.campaigns);
        setTags(params.tags || defaultValues.tags);
        setModeratorTags(params.moderatorTags || defaultValues.moderatorTags);
        setIdeaCustomFields(params.ideaCustomFields || defaultValues.ideaCustomFields);
        setMemberCustomFields(params.memberCustomFields || defaultValues.memberCustomFields);
        setArchiveCampaign(params.archiveCampaign || defaultValues.archiveCampaign);
        setPrivateCampaign(params.privateCampaign || defaultValues.privateCampaign);
    }, []);

    const getSearchParams = useMemo(() => {
        return SearchParameters.builder()
            .term(term)
            .fromDate(fromDate, authentication.actor.timeZone)
            .toDate(toDate, authentication.actor.timeZone)
            .labelIds(labels.map(item => item.value))
            .campaignIds(campaigns.map(item => item.value))
            .stageIds(stages.map(item => item.value))
            .tags(tags.map(item => item.label))
            .moderatorTags(moderatorTags.map(item => item.label))
            .archiveCampaignIncluded(archiveCampaign)
            .privateCampaign(privateCampaign)
            .ideaSelectionFields(ideaCustomFields.map(item => ({
                id: item.field.value,
                optionIds: item.values.map(option => option.value)
            })))
            .authorSelectionFields(memberCustomFields.map(item => ({
                id: item.field.value,
                optionIds: item.values.map(option => option.value)
            })))
            .build();
    }, [archiveCampaign, authentication.actor.timeZone, campaigns, fromDate, ideaCustomFields, labels, memberCustomFields, moderatorTags, privateCampaign, stages, tags, term, toDate]);

    const contextValue = useMemo(() => {
        return {
            searchCategory,
            term,
            fromDate,
            toDate,
            archiveCampaign,
            privateCampaign,
            labels,
            campaigns,
            stages,
            tags,
            moderatorTags,
            ideaCustomFields,
            memberCustomFields,
            setSearchCategory,
            addTerm,
            updateFilters,
            clearFilters,
            getSearchParams
        };
    }, [archiveCampaign, campaigns, clearFilters, fromDate, getSearchParams, ideaCustomFields, labels, memberCustomFields, moderatorTags, privateCampaign, searchCategory, stages, tags, term, toDate, updateFilters]);

    return (
        <ctx.Provider value={contextValue}>
            {children}
        </ctx.Provider>
    );
};