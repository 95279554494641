import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {CampaignsHolder} from '@ideascale/commons/dist/models/CampaignsHolder';
import {LabelData} from '@ideascale/commons/dist/models/LabelData';
import {UploadedResponse} from '@ideascale/commons/dist/models/UploadedResponse';
import {UploadProgressCallback} from '@ideascale/commons/dist/models/UploadProgressCallback';
import {serviceLinks} from 'services/ServiceLinks';
import {AbstractService} from 'services/AbstractService';
import {LandingPageService} from 'services/LandingPageService';
import {Slider} from 'models/Slider';
import {TagData} from 'models/TagData';
import {Component} from 'models/landing-page/Component';
import {IdeaSummary} from 'models/IdeaSummary';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {PageParameters} from 'models/types/PageParameters';
import {VersionPayload} from 'models/landing-page/VersionPayload';
import {LandingPageData} from 'models/landing-page/LandingPageData';
import {TextComponentData} from 'models/landing-page/TextComponentData';
import {StatisticsRequest} from 'models/landing-page/StatisticsRequest';
import {HeroComponentData} from 'models/landing-page/HeroComponentData';
import {StatisticsResponse} from 'models/landing-page/StatisticsResponse';
import {IdeasComponentData} from 'models/landing-page/IdeasComponentData';
import {SaveVersionPayload} from 'models/landing-page/SaveVersionPayload';
import {LandingPageResponse} from 'models/landing-page/LandingPageResponse';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaSearchParameters} from 'models/types/IdeaSearchParameters';
import {IdeasComponentRequest} from 'models/landing-page/IdeasComponentRequest';
import {ActivitySummaryRequest} from 'models/landing-page/ActivitySummaryRequest';
import {LandingPagePermissions} from 'models/landing-page/LandingPagePermissions';
import {SidebarLeaderboardItem} from 'models/SidebarLeaderboardItem';
import {ActivitySummaryResponse} from 'models/landing-page/ActivitySummaryResponse';
import {CommunityStatisticsData} from 'models/landing-page/CommunityStatisticsData';
import {PageResponseIdeaSummary} from 'models/PageResponseIdeaSummary';
import {CampaignSubscribeResponse} from 'models/CampaignSubscribeResponse';
import {TranslationContentRequest} from 'models/landing-page/TranslationContentRequest';
import {LandingPageVersionResponse} from 'models/landing-page/LandingPageVersionResponse';
import {TranslationContentResponse} from 'models/landing-page/TranslationContentResponse';
import {InstructionListComponentData} from 'models/landing-page/InstructionListComponentData';
import {FeaturedCampaignsComponentData} from 'models/landing-page/FeaturedCampaignsComponentData';
import {FeaturedCampaignsComponentRequest} from 'models/landing-page/FeaturedCampaignsComponentRequest';
import {LandingPageVersion} from 'models/types/LandingPageVersion';

const {REACT_APP_ADMIN_API_URL: ADMIN_API, REACT_APP_TRANSLATION_API_URL: TRANSLATION_API} = process.env;

function getPageApiBaseUrl(permissions: LandingPagePermissions) {
    if (!permissions.edit && permissions.translation) {
        return TRANSLATION_API;
    } else {
        return ADMIN_API;
    }
}

export class LandingPageServiceImpl extends AbstractService implements LandingPageService {

    async fetchPageById(pageId: number): Promise<LandingPageData> {
        return this.getAndUnwrap(serviceLinks.pageById(pageId), {baseURL: ADMIN_API});
    }

    async fetchPageByIdAndVersion(permissions: LandingPagePermissions, pageId: number, version: number): Promise<LandingPageData> {
        return this.getAndUnwrap(serviceLinks.pageByIdAndVersion(pageId, version), {baseURL: getPageApiBaseUrl(permissions)});
    }

    async fetchLandingPage(): Promise<LandingPageData> {
        return this.getAndUnwrap(serviceLinks.landingPage());
    }

    async fetchPageByLanguageAndVersion(pageId: number, languageId: number, version: number): Promise<LandingPageData> {
        return this.getAndUnwrap(serviceLinks.pageByLanguageAndVersion(pageId, languageId, version), {baseURL: TRANSLATION_API});
    }

    fetchPageVersions(permissions: LandingPagePermissions, pageId: number): Promise<LandingPageVersionResponse> {
        return this.getAndUnwrap(serviceLinks.pageVersions(pageId), {baseURL: getPageApiBaseUrl(permissions)});
    }

    fetchLandingPageList(): Promise<LandingPageResponse> {
        return this.getAndUnwrap(serviceLinks.landingPageList(), {baseURL: ADMIN_API});
    }

    editVersion(pageId: number, version: number, newVersion: VersionPayload): Promise<LandingPageVersion> {
        return this.postAndUnwrap(serviceLinks.editVersion(pageId, version), newVersion, {baseURL: ADMIN_API});
    }

    deleteVersion(pageId: number, version: number): Promise<void> {
        return this.deleteAndUnwrap(serviceLinks.deleteVersion(pageId, version), {}, {baseURL: ADMIN_API});
    }

    async publishLandingPage(pageId: number, version?: number): Promise<LandingPageData> {
        return this.postAndUnwrap(serviceLinks.publishLandingPage(pageId, version), {}, {baseURL: ADMIN_API});
    }

    async reorderRow(pageId: number, rowId: number, position: number): Promise<void> {
        return this.postAndUnwrap(serviceLinks.landingPageReorderRow(pageId), {rowId, position}, {baseURL: ADMIN_API});
    }

    cancelEditing(pageId: number): Promise<void> {
        return this.getAsPostAndUnwrap(serviceLinks.cancelEditing(pageId), {}, {baseURL: ADMIN_API});
    }

    startEditing(pageId: number, version?: number): Promise<LandingPageData> {
        if (version !== undefined) {
            return this.getAsPostAndUnwrap(serviceLinks.editLandingPageByVersion(pageId, version), {}, {baseURL: ADMIN_API});
        } else {
            return this.getAsPostAndUnwrap(serviceLinks.editLandingPage(pageId), {}, {baseURL: ADMIN_API});
        }
    }

    doneEditing(pageId: number, versionPayload: SaveVersionPayload): Promise<LandingPageData> {
        return this.postAndUnwrap(serviceLinks.doneEditing(pageId), versionPayload, {baseURL: ADMIN_API});
    }

    async saveHeroComponentConfig(component: HeroComponentData): Promise<HeroComponentData> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async saveTextComponentConfig(component: TextComponentData): Promise<TextComponentData> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async saveInstructionListConfig(component: InstructionListComponentData): Promise<InstructionListComponentData> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async saveFeaturedCampaignConfig(component: FeaturedCampaignsComponentRequest): Promise<FeaturedCampaignsComponentData> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async saveIdeasConfig(component: IdeasComponentRequest): Promise<IdeasComponentData> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async saveCommunityStatistics(component: CommunityStatisticsData): Promise<CommunityStatisticsData> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async saveCommonConfig(component: Component): Promise<Component> {
        return this.postAndUnwrap(serviceLinks.saveLandingPageComponent(), component, {baseURL: ADMIN_API});
    }

    async fetchFeaturedCampaigns(data: PageParameters): Promise<Slider> {
        return await this.getAsPostAndUnwrap<Slider, PageParameters>(serviceLinks.slider(), data);
    }

    async subscribeCampaign(id: number): Promise<CampaignSubscribeResponse> {
        return await this.postAndUnwrap(serviceLinks.subscribeCampaign(id), undefined, undefined, CampaignSubscribeResponse);
    }

    async unsubscribeCampaign(id: number): Promise<CampaignSubscribeResponse> {
        return await this.postAndUnwrap(serviceLinks.unsubscribeCampaign(id), undefined, undefined, CampaignSubscribeResponse);
    }

    async fetchTags(pageParameters: PageParameters): Promise<PagedResponseContent<TagData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.fetchTags(), pageParameters);
    }

    async fetchIdeas(mode: IdeaListMode, searchParameters: IdeaSearchParameters): Promise<PagedResponseContent<IdeaSummary>> {
        return await this.getAsPostAndUnwrap(serviceLinks.landingPageIdeas(mode), searchParameters, {}, PageResponseIdeaSummary);
    }

    async fetchPinnedIdeas(searchParameters: IdeaSearchParameters): Promise<PagedResponseContent<IdeaSummary>> {
        return await this.getAsPostAndUnwrap(serviceLinks.landingPagePinnedIdeas(), searchParameters, {}, PageResponseIdeaSummary);
    }

    async fetchIdeaTags(pageParameters: PageParameters): Promise<PagedResponseContent<string>> {
        return await this.getAsPostAndUnwrap(serviceLinks.advancedSearchTags(), pageParameters);
    }

    async fetchLabels(pageParameters: PageParameters): Promise<PagedResponseContent<LabelData>> {
        return await this.getAsPostAndUnwrap(serviceLinks.searchLabels(), pageParameters);
    }

    async fetchCampaigns(pageParameters: PageParameters): Promise<PagedResponseContent<CampaignsHolder<Campaign>>> {
        return await this.getAsPostAndUnwrap(serviceLinks.topBarCampaigns(), pageParameters);
    }

    async fetchCommunityStatistics(parameters: StatisticsRequest): Promise<StatisticsResponse[]> {
        return await this.getAsPostAndUnwrap(serviceLinks.landingPageCommunityStatistics(), parameters);
    };

    async fetchCommunityLeaders(): Promise<SidebarLeaderboardItem[]> {
        return await this.getAndUnwrap(serviceLinks.sidebarLeaderboardItem());
    }

    async fetchActivitySummaries(timePeriodGroup: string, timePeriodUnit: number, parameters: ActivitySummaryRequest): Promise<ActivitySummaryResponse[]> {
        return await this.getAsPostAndUnwrap(serviceLinks.landingPageActivitySummaries(timePeriodGroup, timePeriodUnit), parameters);
    }

    async uploadImage(data: FormData, onUploadProgress: UploadProgressCallback): Promise<UploadedResponse> {
        return await this.postAndUnwrap(serviceLinks.landingPageUpload(), data, {onUploadProgress});
    }

    async uploadFileLink(url: string): Promise<any> {
        return await this.getAndUnwrap(serviceLinks.landingPageDownloadFileLink(), {
            data: {url}
        });
    }

    async fetchTranslatedAdminContent(parameters: TranslationContentRequest): Promise<TranslationContentResponse[]> {
        return await this.getAsPostAndUnwrap(serviceLinks.translatedContent(), parameters, {baseURL: TRANSLATION_API});
    }

    async saveTranslatedAdminContent(parameters: TranslationContentRequest): Promise<TranslationContentResponse[]> {
        return await this.postAndUnwrap(serviceLinks.saveTranslatedContent(), parameters, {baseURL: TRANSLATION_API});
    }
}