import {useRoutesMatch} from '@ideascale/commons/dist/hooks/useRoutesMatch';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {useParams} from 'react-router-dom';
import {IdeaListRouteMatchParams} from 'models/types/IdeaListRouteMatchParams';
import {ROUTES} from 'shared/Routes';

export const useIdeasPageRouteMatch = (routes?: string | string[]) => {
    const match = useRoutesMatch<IdeaListRouteMatchParams>(routes || '');
    const params = useParams<IdeaListRouteMatchParams>();
    const urlQuery = useUrlQuery();
    const urlOrder = urlQuery.get(ROUTES.QUERY_PARAMS.ORDER) || '';
    const urlTag = urlQuery.get(ROUTES.QUERY_PARAMS.TAG) || '';
    const urlModeratorTag = urlQuery.get(ROUTES.QUERY_PARAMS.MODERATOR_TAG) || '';
    const urlLabel = urlQuery.get(ROUTES.QUERY_PARAMS.LABEL) || '';
    const routeParams = match ? match.params : params;

    return {
        routeParams,
        urlOrder,
        urlTag,
        urlModeratorTag,
        urlLabel,
        urlQuery
    }
}