import {Member} from '@ideascale/commons/dist/models/Member';

export class MemberSummary extends Member {
    static EMPTY = new MemberSummary(0, '', '', '', '', false, '', '');

    firstName: string;
    lastName: string;

    constructor(id: number, name: string, username: string, avatar: string, url: string, identityHidden: boolean, firstName: string, lastName: string) {
        super(id, name, username, avatar, url, identityHidden);
        this.firstName = firstName;
        this.lastName = lastName;
    }
}