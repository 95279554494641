import {ActivityType} from './enums/ActivityType';
import {ActionType} from './enums/ActionType';
import {ActivityIcon} from './ActivityIcon';
import {Type} from 'class-transformer';
import {ClassificationFieldAttribute} from '@ideascale/commons/dist/models/classification/ClassificationFieldAttribute';


export class ActivityStreamData {
    activityType: ActivityType;
    action: ActionType;
    idea: {
        id?: number,
        title: string,
        attributes: ClassificationFieldAttribute[];
    };
    @Type(() => ActivityIcon)
    activityIcon: ActivityIcon;
    createdAt: string;
    commentId?: number;
    linkedIdea?: { id?: number, title: string, linkQualifier: string };
    linkQualifier?: string;
    member?: { id: number, name: string };
    stageName?: string;
    tag?: string;
    label?: { name: string, colorKey: string, isPrivate: boolean };
    mergedFromIdea?: boolean;
    modifyType?: 'IDEA_MODIFY' | 'COMMENT_MODIFY';
    campaignName?: string;
    commentReply?: boolean;
    kudoReceiver?: {
        avatar: string,
        id: number,
        name: string
        identityHidden: boolean
    };
}