import {useMemo} from 'react';
import {Authentication} from '@ideascale/commons/dist/models';
import {AuthServiceImpl} from '@ideascale/commons/dist/services/impl/AuthServiceImpl';
import {axiosFactory} from '@ideascale/commons/dist/utils/AxiosFactory';
import memoize from 'lodash/memoize';
import {AppServiceImpl} from 'services/impl/AppServiceImpl';
import {CommunityServiceImpl} from 'services/impl/CommunityServiceImpl';
import {useAppContext} from 'contexts/AppContext';
import {AppService} from 'services/AppService';
import {CommunityService} from 'services/CommunityService';
import {LandingPageService} from 'services/LandingPageService';
import {LandingPageServiceImpl} from 'services/impl/LandingPageServiceImpl';
import {EditModeService} from 'services/EditModeService';
import {EditModeServiceImpl} from 'services/impl/EditModeServiceImpl';

const {REACT_APP_BASE_API_URL = '', REACT_APP_ADMIN_API_URL = ''} = process.env;

export function createAuthService() {
    return new AuthServiceImpl(axiosFactory.create(REACT_APP_BASE_API_URL));
}

const _createAppService = memoize((authentication: Authentication) => new AppServiceImpl(axiosFactory.createWithAuthentication(REACT_APP_BASE_API_URL, authentication)));

export function createAppService(authentication: Authentication) {
    return _createAppService(authentication);
}

export const useAppService = (): AppService | null => {
    const {authentication} = useAppContext();
    return useMemo((): AppService | null => {
        if (authentication.isNotEmpty()) {
            return createAppService(authentication);
        } else {
            return null;
        }
    }, [authentication]);
};

const _createCommunityService = memoize((authentication: Authentication) => new CommunityServiceImpl(axiosFactory.createWithAuthentication(REACT_APP_BASE_API_URL, authentication)));

export function createCommunityService(authentication: Authentication) {
    return _createCommunityService(authentication);
}

export const useCommunityService = (): CommunityService | null => {
    const {authentication} = useAppContext();
    return useMemo((): CommunityService | null => {
        if (authentication.isNotEmpty()) {
            return createCommunityService(authentication);
        } else {
            return null;
        }
    }, [authentication]);
};

const _createLandingPageService = memoize((authentication: Authentication) => new LandingPageServiceImpl(axiosFactory.createWithAuthentication(REACT_APP_BASE_API_URL, authentication)));

export function createLandingPageService(authentication: Authentication) {
    return _createLandingPageService(authentication);
}

export const useLandingPageService = (): LandingPageService | null => {
    const {authentication} = useAppContext();
    return useMemo((): LandingPageService | null => {
        if (authentication.isNotEmpty()) {
            return createLandingPageService(authentication);
        } else {
            return null;
        }
    }, [authentication]);
};

const _createEditModeService = memoize((authentication: Authentication) => new EditModeServiceImpl(axiosFactory.createWithAuthentication(REACT_APP_ADMIN_API_URL, authentication)));

export function createEditModeService(authentication: Authentication) {
    return _createEditModeService(authentication);
}

export const useEditModeService = (): EditModeService | null => {
    const {authentication} = useAppContext();
    return useMemo((): EditModeService | null => {
        if (authentication.isNotEmpty()) {
            return createEditModeService(authentication);
        } else {
            return null;
        }
    }, [authentication]);
};