import React, {useMemo} from 'react';
import {Trans} from 'react-i18next';
import {APP_EVENTS} from '@ideascale/commons/dist/shared/AppConstants';
import {Cookies} from '@ideascale/commons/dist/models/enums/Cookies';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {useLocalizer} from './useLocalizer';
import {useAppContext} from 'contexts/AppContext';

export const useCookieConsentConfiguration = () => {
    const localizer = useLocalizer();
    const {cookieConsent: {needsCookieConsent, cookieCategories}} = useAppContext();
    return useMemo(() => {
        return {
            needsCookieConsent: needsCookieConsent,
            useAllCookies: cookieCategories.includes(Cookies.NON_ESSENTIAL),
            contentUnblockedMessage: (
                <Trans i18nKey="frontend-shared.cookie-consent.external-content.unblock">
                    <span role="button" className="cookie-consent-edit ms-1"
                          onClick={() => eventDispatcher.dispatch(APP_EVENTS.SHOW_COOKIE_POLICY)}/>
                </Trans>
            ),
            contentBlockedMessage: localizer.msg('frontend-shared.cookie-consent.external-content.blocked')
        };
    }, [localizer, needsCookieConsent, cookieCategories]);
};