import React from 'react';
import { Spinner} from '@ideascale/ui';
import loadingSvg from '@ideascale/ui/dist/assets/loading.svg';
import './PageLoading.scss';

export const PageLoadingOverlay = () => {
    return (
        <div className="loading-backdrop d-flex justify-content-center align-items-center">
            <Spinner src={loadingSvg} size={100}/>
        </div>
    );
};