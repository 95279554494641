import {useCallback} from 'react';
import {Member} from '@ideascale/commons/dist/models/Member';
import {PrivateMessageRequestParams} from '@ideascale/commons/dist/models/PrivateMessageRequestParams';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {AlertEvent, AlertType, buildAlertEventData} from '@ideascale/commons/dist/utils/AlertEvent';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {IdeaInternalShareRequestParams} from 'models/IdeaInternalShareRequestParams';
import {DigestInternalShareRequestParams} from 'models/DigestInternalShareRequestParams';

export const useMessageService = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();

    const fetchRecipients = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            return await communityService.fetchRecipients(pageParameters);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    const onMemberSendMessages = useCallback(async (requestParams: PrivateMessageRequestParams) => {
        if (communityService !== null) {
            const response = await communityService.onMemberSendMessages(requestParams);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, `${localizer.msg('common.message-sent-successfully')}`));
            return response;
        }
    }, [communityService, localizer]);

    const onIdeaSendMessages = useCallback(async (requestParams: IdeaInternalShareRequestParams) => {
        if (communityService !== null) {
            const response = await communityService.onIdeaSendMessages(requestParams);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, `${localizer.msg('common.message-sent-successfully')}`));
            return response;
        }
    }, [communityService, localizer]);

    const onDigestSendMessages = useCallback(async (requestParams: DigestInternalShareRequestParams) => {
        if (communityService !== null) {
            const response = await communityService.onDigestSendMessages(requestParams);
            eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, `${localizer.msg('common.message-sent-successfully')}`));
            return response;
        }
    }, [communityService, localizer]);


    return {
        fetchRecipients,
        onMemberSendMessages,
        onIdeaSendMessages,
        onDigestSendMessages
    };
};