import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {InfiniteData, InfiniteQueryObserverResult, useQueryClient} from 'react-query';
import {
    FetchNextPageOptions,
    FetchPreviousPageOptions,
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters
} from 'react-query/types/core/types';
import {QUERY_KEYS} from 'constants/AppConstants';
import {IdeaSummary} from 'models/IdeaSummary';

export type PagedIdeas = {
    data: IdeaSummary[];
    totalIdeas: number,
    allPages: number | undefined;
    nextPage: number | undefined;
    prevPage: number | undefined;
}

type IdeaList = {
    pagedIdeas: InfiniteData<PagedIdeas | undefined> | undefined;
    totalIdeas: number,
    isLoading: boolean;
    refetch: (options?: RefetchOptions & RefetchQueryFilters<any>) => Promise<QueryObserverResult<any, any>>;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    isFetchingPreviousPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<any, any>>;
    fetchPreviousPage: (options?: FetchPreviousPageOptions) => Promise<InfiniteQueryObserverResult<any, any>>;
}

export type IdeasContextState = {
    ideaListData: IdeaList;
    updateIdeaListData: Dispatch<SetStateAction<IdeaList>>;
    ideaListFilterQueryKey: any;
    setIdeaListFilterQueryKey: Dispatch<SetStateAction<any>>;
    ideaListPagePath: string;
    setIdeaListPagePath: Dispatch<SetStateAction<string>>;
    listPageUrlParams: string | undefined,
    setListPageUrlParams: Dispatch<SetStateAction<string | undefined>>;
    clearIdeaListCachedData: () => void;
}

const defaultStateValues: IdeasContextState = {
    ideaListData: {
        pagedIdeas: {
            pages: [],
            pageParams: []
        },
        totalIdeas: 0,
        isLoading: false,
        refetch: () => new Promise(resolve => resolve),
        hasNextPage: false,
        hasPreviousPage: false,
        isFetchingPreviousPage: false,
        isFetchingNextPage: false,
        fetchNextPage: () => new Promise(resolve => resolve),
        fetchPreviousPage: () => new Promise(resolve => resolve),
    },
    updateIdeaListData: () => null,
    ideaListFilterQueryKey: '',
    setIdeaListFilterQueryKey: () => null,
    ideaListPagePath: '/',
    setIdeaListPagePath: () => null,
    listPageUrlParams: undefined,
    setListPageUrlParams: () => null,
    clearIdeaListCachedData: () => null
};

const ctx = React.createContext<IdeasContextState>(defaultStateValues);

export const useIdeasContext = () => {
    return React.useContext<IdeasContextState>(ctx);
};

type IdeasContextType = {
    children: React.ReactNode;
}

export const IdeasContextProvider = ({children}: IdeasContextType) => {
    const queryClient = useQueryClient();
    const [ideaListData, updateIdeaListData] = useState<IdeaList>(defaultStateValues.ideaListData);
    const [ideaListFilterQueryKey, setIdeaListFilterQueryKey] = useState<any>('');
    const [ideaListPagePath, setIdeaListPagePath] = useState<string>('');
    const [listPageUrlParams, setListPageUrlParams] = useState<string | undefined>(undefined);

    const clearIdeaListCachedData = useCallback(() => {
        queryClient.removeQueries(QUERY_KEYS.IDEA_LIST);
        queryClient.removeQueries(QUERY_KEYS.MODERATE_ACTIONS);
        queryClient.removeQueries(QUERY_KEYS.MORE_ACTIONS);
    }, [queryClient]);

    const contextValue = useMemo(() => {
        return {
            ideaListData,
            updateIdeaListData,
            ideaListFilterQueryKey,
            setIdeaListFilterQueryKey,
            ideaListPagePath,
            setIdeaListPagePath,
            listPageUrlParams,
            setListPageUrlParams,
            clearIdeaListCachedData
        };
    }, [clearIdeaListCachedData, ideaListData, ideaListFilterQueryKey, ideaListPagePath, listPageUrlParams]);

    return (
        <ctx.Provider value={contextValue}>
            {children}
        </ctx.Provider>
    );
};