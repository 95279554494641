import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import {AlertType} from '@ideascale/commons/dist/utils/AlertEvent';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useAppContext} from './AppContext';
import {useLandingPageService} from 'hooks/useService';
import {useLocalizer} from 'hooks/useLocalizer';
import {CommonUtil} from 'utils/CommonUtil';
import {Scroller} from 'utils/Scroller';
import {EditModeUtil} from 'utils/EditModeUtil';
import {LandingPageData} from 'models/landing-page/LandingPageData';
import {LandingPageVersion} from 'models/types/LandingPageVersion';
import {TABINDEX_SET_DELAY} from 'constants/AppConstants';

const defaultHighlightColor = '#384EC1';
const defaultAccent1Color = '#BF2C04';
const defaultAccent2Color = '#39833B';

const setCommunityColors = (highlightColor: string, accent1Color: string, accent2Color: string) => {
    CommonUtil.setStyleProperty('--highlight-color', highlightColor || defaultHighlightColor);
    CommonUtil.setStyleProperty('--accent1-color', accent1Color || defaultAccent1Color);
    CommonUtil.setStyleProperty('--accent2-color', accent2Color || defaultAccent2Color);
};

const SECTION_POSITION_OFFSET = -180;
const REORDER_SAVING_DEBOUNCE_TIME = 1000;

type LandingPageContext = {
    landingPage: LandingPageData;
    setLandingPage: Dispatch<SetStateAction<LandingPageData>>;
    selectedVersionData: LandingPageVersion | undefined;
    setSelectedVersionData: Dispatch<SetStateAction<LandingPageVersion | undefined>>;
    translationMode: boolean;
    setTranslationMode: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    reorder: (rowId: number, position: number, action: 'up' | 'down') => void;
    reorderRowId: number;
    componentEdited: boolean;
    handleComponentEdit: (edited: boolean) => void;
}

const defaultLandingPageContext: LandingPageContext = {
    landingPage: LandingPageData.EMPTY,
    setLandingPage: () => null,
    selectedVersionData: undefined,
    setSelectedVersionData: () => null,
    translationMode: false,
    setTranslationMode: () => null,
    loading: true,
    setLoading: () => null,
    reorder: () => null,
    reorderRowId: 0,
    componentEdited: false,
    handleComponentEdit: () => null,
};

const ctx = React.createContext<LandingPageContext>(defaultLandingPageContext);

export const useLandingPageContext = () => {
    return React.useContext<LandingPageContext>(ctx);
};

type LandingPageContextProviderProps = {
    children: React.ReactNode;
}

export const LandingPageContextProvider = ({children}: LandingPageContextProviderProps) => {
    const localizer = useLocalizer();

    const {communityConfig} = useAppContext();
    const landingPageService = useLandingPageService();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [landingPage, setLandingPage] = useState<LandingPageData>(LandingPageData.EMPTY);
    const [reorderRowId, setReorderRowId] = useState(0);
    const [translationMode, setTranslationMode] = useState(false);
    const [selectedVersionData, setSelectedVersionData] = useState<LandingPageVersion | undefined>();
    const [loading, setLoading] = useState(true);
    const [componentEdited, setComponentEdited] = useState(false);

    const handleComponentEdit = useCallback((edited: boolean) => {
        setComponentEdited(edited);
        CommonUtil.wait(TABINDEX_SET_DELAY).then(() => {
            EditModeUtil.setTabIndexOnlyForEditModeElements();
        });
    }, []);

    const debounceSaveOrder = useRef(
        debounce(async (pageId: number, rowId: number, position: number) => {
            try {
                await landingPageService?.reorderRow(pageId, rowId, position);
            } catch (error: any) {
                if (error?.status === 400) {
                    handleErrorResponse(error, {
                        fallbackMessage: {
                            type: AlertType.error,
                            message: localizer.msg(`landing-page.reorder.errors.${error?.data?.reason.toLowerCase()}`)
                        }
                    });
                } else {
                    handleErrorResponse(error);
                }
                const data = await landingPageService?.startEditing(pageId, selectedVersionData?.version);
                if (data) {
                    setLandingPage(data);
                }
            } finally {
                setReorderRowId(0);
            }
        }, REORDER_SAVING_DEBOUNCE_TIME)
    ).current;

    const onReorder = useCallback(async (rowId: number, index: number, action: 'up' | 'down') => {
        setReorderRowId(rowId);
        const newPosition = action === 'up' ? index - 1 : index + 1;
        setLandingPage(prevState => {
            const newPage = clone(prevState);
            if (newPosition >= 0 && newPosition < prevState.rows.length) {
                const [removedElement] = newPage.rows.splice(index, 1);
                newPage.rows.splice(newPosition, 0, removedElement);
            }
            return newPage;
        });

        CommonUtil.wait(300).then(() => {
            Scroller.scrollTo(`section-${rowId}`, {offset: SECTION_POSITION_OFFSET, flashing: true});
        });

        await debounceSaveOrder(landingPage.id, rowId, newPosition);
        handleComponentEdit(true);
    }, [debounceSaveOrder, handleComponentEdit, landingPage.id]);

    useEffect(() => {
        setCommunityColors(communityConfig.highlightColor, communityConfig.accentColorOne, communityConfig.accentColorTwo);
    }, [communityConfig.accentColorOne, communityConfig.accentColorTwo, communityConfig.highlightColor]);

    useEffect(() => {
        if (componentEdited && !landingPage.contentModified) {
            setLandingPage(prev => ({...prev, contentModified: componentEdited}));
        } else if (landingPage.contentModified && !componentEdited) {
            handleComponentEdit(landingPage.contentModified);
        }
    }, [componentEdited, handleComponentEdit, landingPage.contentModified]);

    return (
        <ctx.Provider value={{
            landingPage,
            setLandingPage,
            selectedVersionData,
            setSelectedVersionData,
            translationMode,
            setTranslationMode,
            loading,
            setLoading,
            reorder: onReorder,
            reorderRowId,
            handleComponentEdit,
            componentEdited
        }}>
            {children}
        </ctx.Provider>
    );
};