import React, {useCallback, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import axios, {CancelTokenSource} from 'axios';
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query/types/core/types';
import {AlertEvent, AlertType, buildAlertEventData} from '@ideascale/commons/dist/utils/AlertEvent';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useAppContext} from './AppContext';
import {useIdeaService} from 'hooks/useIdeaService';
import {useIdeaStageChange} from 'hooks/useIdeaStageChange';
import {useIdeaSubscription} from 'hooks/useIdeaSubscription';
import {useIdeaAuthorFollow} from 'hooks/useIdeaAuthorFollow';
import {useIdeaCommentEnabled} from 'hooks/useIdeaCommentEnabled';
import {useAssignOwners} from 'hooks/useAssignOwners';
import {useIdeaDelete} from 'hooks/useIdeaDelete';
import {useVoteAction} from 'hooks/useVoteAction';
import {useCampaignChange} from 'hooks/useCampaignChange';
import {useKudoGive} from 'hooks/useKudoGive';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {useEditIdeaActions} from 'hooks/useEditIdeaActions';
import {useLocalizer} from 'hooks/useLocalizer';
import {useLinkIdeas} from 'hooks/useLinkIdeas';
import {useIdeaUpdater} from 'hooks/useIdeaUpdater';
import {useIdeaPermissionHolder} from 'hooks/useIdeaPermissionHolder';
import {useIdeaTags} from 'hooks/useIdeaTags';
import {useIdeaPinToLandingPage} from 'hooks/useIdeaPinToLanding';
import {IDEA_EVENTS, QUERY_KEYS, SHOW_LOGIN_PROMPT} from 'constants/AppConstants';
import {IdeaDetail, PermissionHolder} from 'models/IdeaDetail';
import {IdeaDetailsRouteMatchParams} from 'models/types/IdeaDetailsRouteMatchParams';
import {VoteType} from 'models/enums/VoteType';
import {VoteParameters} from 'models/VoteParameters';
import {IdeaSubmissionRequest} from 'models/IdeaSubmissionRequest';
import {ModifiedIdeaDetail} from 'models/ModifiedIdeaDetail';
import {ChangeCampaignParams} from 'models/types/ChangeCampaignParams';
import {KudoActivitySummary} from 'models/KudoActivitySummary';
import {LabelActionParams} from 'models/types/LabelActionParams';
import {AssignOwnersParams} from 'models/types/AssignOwnersParams';
import {VoteRetractType} from 'models/enums/VoteRetractType';
import {IdeaSummary} from 'models/IdeaSummary';
import {PageParameters} from 'models/types/PageParameters';
import {PagedResponseContent} from 'models/PagedResponseContent';
import {LinkableIdea} from 'models/LinkableIdea';
import {QualifierLinkedIdeas} from 'models/QualifierLinkedIdeas';

import {IdeaOwnerAssignmentResponse} from 'models/IdeaOwnerAssignmentResponse';

export type IdeaDetailsContextState = {
    ideaDetails: IdeaDetail;
    isFetchIdeaDetails: boolean;
    onToggleVote: (ideaId: number, voteType: VoteType, voteParameters: VoteParameters) => Promise<boolean>;
    onTogglePinToLandingPage: (ideaId: number, pinned: boolean) => void;
    changeIdeaStage: (ideaId: number, stageId: number) => Promise<IdeaDetail | undefined>;
    deleteIdea: (ideaId: number) => void;
    editIdea: (ideaId: number, submissionRequest: IdeaSubmissionRequest) => Promise<ModifiedIdeaDetail>;
    changeCampaign: (changeCampaignParams: ChangeCampaignParams) => Promise<IdeaSummary | undefined>;
    giveKudo: (ideaId: number) => Promise<KudoActivitySummary>;
    updateLabels: (labelActionParams: LabelActionParams) => Promise<void>;
    reportAbuse: (ideaId: number) => Promise<void>;
    reportDuplicate: (ideaId: number) => Promise<void>;
    assignOwners: (assignOwnersParams: AssignOwnersParams) => Promise<IdeaOwnerAssignmentResponse>;
    updateIdeaSubscription: (ideaId: number, subscribed: boolean, followersCount: number) => void;
    updateIdeaAuthorFollow: (ideaId: number, followed: boolean) => void;
    updateIdeaCommentingEnabled: (ideaId: number, commentEnabled: boolean, permissionHolder: PermissionHolder) => void;
    updateIdeaPermissionHolder: (ideaId: number, permissionHolder: PermissionHolder) => void;
    fetchLinkableIdeas: (ideaId: number, ideaTitle: string, ideaDescription: string, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    fetchLinkableIdeasByKeywords: (ideaId: number, pageParameters: PageParameters) => Promise<PagedResponseContent<LinkableIdea>>;
    saveQualifierLinkedIdeas: (ideaId: number, linkedIdeas: QualifierLinkedIdeas[]) => Promise<void>;
    unlinkIdea: (ideaId: number, linkedIdeaId: number, linkQualifierId: number) => Promise<void>;
    updateTags: (ideaId: number, tags: string[]) => Promise<string[]>;
    updateModeratorTags: (ideaId: number, tags: string[]) => Promise<string[]>;
    refetch: <TPageData>(options?: ((RefetchOptions & RefetchQueryFilters<TPageData>) | undefined)) => Promise<QueryObserverResult<IdeaDetail, any>>;

}

const defaultStateValues: IdeaDetailsContextState = {
    ideaDetails: IdeaDetail.EMPTY,
    isFetchIdeaDetails: true,
    onToggleVote: () => Promise.resolve(true),
    onTogglePinToLandingPage: () => Promise.resolve(true),
    changeIdeaStage: () => new Promise(resolve => resolve),
    deleteIdea: () => null,
    editIdea: () => new Promise(resolve => resolve),
    changeCampaign: () => new Promise(resolve => resolve),
    giveKudo: () => new Promise(resolve => resolve),
    updateLabels: () => new Promise(resolve => resolve),
    reportAbuse: () => new Promise(resolve => resolve),
    reportDuplicate: () => new Promise(resolve => resolve),
    assignOwners: () => new Promise(resolve => resolve),
    updateIdeaSubscription: () => null,
    updateIdeaAuthorFollow: () => null,
    updateIdeaCommentingEnabled: () => null,
    updateIdeaPermissionHolder: () => null,
    fetchLinkableIdeas: () => new Promise(resolve => resolve),
    fetchLinkableIdeasByKeywords: () => new Promise(resolve => resolve),
    saveQualifierLinkedIdeas: () => new Promise(resolve => resolve),
    unlinkIdea: () => new Promise(resolve => resolve),
    updateTags: () => new Promise(resolve => resolve),
    updateModeratorTags: () => new Promise(resolve => resolve),
    refetch: () => new Promise(resolve => resolve),
};

const ctx = React.createContext<IdeaDetailsContextState>(defaultStateValues);

export const useIdeaDetailsContext = () => {
    return React.useContext<IdeaDetailsContextState>(ctx);
};

type IdeaDetailsContextType = {
    children: React.ReactNode;
}

export const IdeaDetailsContextProvider = ({children}: IdeaDetailsContextType) => {
    const {authentication, communityConfig: {ssoEnabled}, setCurrentCampaign, currentCampaign} = useAppContext();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const [ideaDetails, setIdeaDetails] = useState<IdeaDetail>(IdeaDetail.EMPTY);
    const params = useParams<IdeaDetailsRouteMatchParams>();
    const {fetchIdeaDetails} = useIdeaService();
    const {updateIdeaDetails} = useIdeaUpdater();
    const detailsFetchingCancelTokenRef = useRef<CancelTokenSource | null>(null);

    const {
        isLoading = true,
        isFetching,
        isLoadingError,
        refetch
    } = useQuery([QUERY_KEYS.IDEA_DETAILS, +params?.ideaId!],
        ({signal}) => {
            // TODO: will be use signal when axios library is updated
            detailsFetchingCancelTokenRef.current = axios.CancelToken.source();
            signal?.addEventListener('abort', () => {
                detailsFetchingCancelTokenRef.current?.cancel('Concurrent details data fetching cancelled');
            });
            return fetchIdeaDetails(+params?.ideaId!, detailsFetchingCancelTokenRef.current?.token);
        }, {
            retry: 0,
            staleTime: 0,
            enabled: +params?.ideaId! !== ideaDetails.id,
            onSuccess: (data: IdeaDetail) => {
                setIdeaDetails(data);
                if (currentCampaign?.id !== data.campaign?.id) {
                    setCurrentCampaign(data.campaign || null);
                }
            },
            onError: (error: any) => {
                handleErrorResponse(error);
            }
        });

    const {ideaDetailsStageChangeMutation} = useIdeaStageChange();
    const {ideaDetailsIdeaSubscriptionMutation} = useIdeaSubscription();
    const {ideaDetailsIdeaAuthorFollowMutation} = useIdeaAuthorFollow();
    const {ideaDetailsIdeaPinToLandingPageMutation} = useIdeaPinToLandingPage();
    const {ideaDetailsIdeaCommentEnabledMutation} = useIdeaCommentEnabled();
    const {ideaDetailsAssignOwnersMutation} = useAssignOwners();
    const {ideaDetailsDeleteIdeaMutation} = useIdeaDelete();
    const {ideaDetailsVoteMutation} = useVoteAction();
    const {ideaDetailsCampaignChangeMutation} = useCampaignChange();
    const {ideaDetailsGiveKudosMutation} = useKudoGive();
    const {ideaDetailsPermissionHolderMutation} = useIdeaPermissionHolder();
    const {
        ideaDetailsUpdateLabelsMutation,
        ideaDetailsReportingMutation
    } = useIdeaLabels();
    const {ideaDetailsEditMutation: {mutateAsync: editIdeaAsync}} = useEditIdeaActions();
    const {fetchLinkableIdeas, fetchLinkableIdeasByKeywords, saveQualifierLinkedIdeas, unlinkIdea} = useLinkIdeas();
    const {
        ideaTagsMutation: {mutateAsync: saveTagsAsync},
        ideaModeratorTagsMutation: {mutateAsync: saveModeratorTagsAsync}
    } = useIdeaTags();

    const onSaveQualifierLinkedIdeas = useCallback(async (ideaId: number, qualifierIdeas: QualifierLinkedIdeas[]) => {
        const response = await saveQualifierLinkedIdeas(ideaId, qualifierIdeas);
        if (response) {
            updateIdeaDetails(ideaId, 'linkedIdeas', response || [] as QualifierLinkedIdeas[]).then();
        }
    }, [saveQualifierLinkedIdeas, updateIdeaDetails]);

    const changeIdeaStage = useCallback(async (ideaId: number, stageId: number) => {
        return await ideaDetailsStageChangeMutation.mutateAsync({ideaId, stageId});
    }, [ideaDetailsStageChangeMutation]);

    const deleteIdea = useCallback(async (ideaId: number) => {
        await ideaDetailsDeleteIdeaMutation.mutateAsync(ideaId);
    }, [ideaDetailsDeleteIdeaMutation]);

    const editIdea = useCallback(async (ideaId: number, submissionRequest: IdeaSubmissionRequest) => {
        return await editIdeaAsync({ideaId, submissionRequest});
    }, [editIdeaAsync]);

    const updateTags = useCallback(async (ideaId: number, tags: string[]) => {
        return await saveTagsAsync({ideaId, tags});
    }, [saveTagsAsync]);

    const updateModeratorTags = useCallback(async (ideaId: number, tags: string[]) => {
        return await saveModeratorTagsAsync({ideaId, tags});
    }, [saveModeratorTagsAsync]);

    const onToggleVote = useCallback(async (ideaId: number, voteType: VoteType, voteParameters: VoteParameters) => {
        try {
            if (authentication.actor.isAnonymous() && !ssoEnabled) {
                eventDispatcher.dispatch(SHOW_LOGIN_PROMPT, localizer.msg('login-prompt.message.ideate'));
            } else {
                const response = await ideaDetailsVoteMutation.mutateAsync({ideaId, voteType, voteParameters});
                let message = localizer.msg('stage.ideate.alert.vote.success');
                if (response.retractType === VoteRetractType.POSITIVE) {
                    message = localizer.msg('stage.ideate.alert.vote.positive-retract');
                } else if (response.retractType === VoteRetractType.NEGATIVE) {
                    message = localizer.msg('stage.ideate.alert.vote.negative-retract');
                } else if (response.retractType === VoteRetractType.RETRACT) {
                    message = localizer.msg('stage.ideate.alert.vote.retracted');
                }
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.success, message));
                if (response.stageChanged) {
                    refetch().then();
                } else {
                    eventDispatcher.dispatch(IDEA_EVENTS.IDEA_VOTED, ideaId);
                }
            }
        } catch (error: any) {
            handleErrorResponse(error);
        }
        return true;
    }, [authentication.actor, handleErrorResponse, ideaDetailsVoteMutation, localizer, refetch, ssoEnabled]);

    const changeCampaign = useCallback(async (changeCampaignParams: ChangeCampaignParams) => {
        return await ideaDetailsCampaignChangeMutation.mutateAsync(changeCampaignParams);
    }, [ideaDetailsCampaignChangeMutation]);

    const updateLabels = useCallback(async (labelActionParams: LabelActionParams) => {
        await ideaDetailsUpdateLabelsMutation.mutateAsync(labelActionParams);
    }, [ideaDetailsUpdateLabelsMutation]);

    const reportAbuse = useCallback(async (ideaId: number) => {
        await ideaDetailsReportingMutation.mutateAsync({ideaId, isAbuseReporting: true});
    }, [ideaDetailsReportingMutation]);

    const reportDuplicate = useCallback(async (ideaId: number) => {
        await ideaDetailsReportingMutation.mutateAsync({ideaId, isAbuseReporting: false});
    }, [ideaDetailsReportingMutation]);

    const assignOwners = useCallback(async (assignOwnersParams: AssignOwnersParams) => {
        return await ideaDetailsAssignOwnersMutation.mutateAsync(assignOwnersParams);
    }, [ideaDetailsAssignOwnersMutation]);

    const updateIdeaSubscription = useCallback(async (ideaId: number, subscribed: boolean, followersCount: number) => {
        await ideaDetailsIdeaSubscriptionMutation(ideaId, subscribed, followersCount);
    }, [ideaDetailsIdeaSubscriptionMutation]);

    const updateIdeaAuthorFollow = useCallback(async (ideaId: number, followed: boolean) => {
        await ideaDetailsIdeaAuthorFollowMutation(ideaId, followed);
    }, [ideaDetailsIdeaAuthorFollowMutation]);

    const onTogglePinToLandingPage = useCallback(async (ideaId: number, pinned: boolean) => {
        await ideaDetailsIdeaPinToLandingPageMutation(ideaId, pinned);
    }, [ideaDetailsIdeaPinToLandingPageMutation]);

    const updateIdeaCommentingEnabled = useCallback(async (ideaId: number, commentEnabled: boolean, permissionHolder: PermissionHolder) => {
        await ideaDetailsIdeaCommentEnabledMutation(ideaId, commentEnabled, permissionHolder);
    }, [ideaDetailsIdeaCommentEnabledMutation]);

    const updateIdeaPermissionHolder = useCallback(async (ideaId: number, permissionHolder: PermissionHolder) => {
        await ideaDetailsPermissionHolderMutation(ideaId, permissionHolder);
    }, [ideaDetailsPermissionHolderMutation]);

    return (
        <ctx.Provider value={{
            ideaDetails,
            isFetchIdeaDetails: isLoading || isFetching || isLoadingError,
            changeIdeaStage,
            deleteIdea,
            editIdea,
            onToggleVote,
            onTogglePinToLandingPage,
            changeCampaign,
            giveKudo: ideaDetailsGiveKudosMutation.mutateAsync,
            updateLabels,
            updateTags,
            updateModeratorTags,
            reportAbuse,
            reportDuplicate,
            assignOwners,
            updateIdeaSubscription,
            updateIdeaAuthorFollow,
            updateIdeaCommentingEnabled,
            updateIdeaPermissionHolder,
            fetchLinkableIdeas,
            fetchLinkableIdeasByKeywords,
            saveQualifierLinkedIdeas: onSaveQualifierLinkedIdeas,
            unlinkIdea,
            refetch
        }}>
            {children}
        </ctx.Provider>
    );
};
