import React from 'react';
import {createRoot} from 'react-dom/client';
import 'reflect-metadata';
import {BrowserRouter} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {enableMapSet} from 'immer';
import {AppThemeUtil, Theme} from '@ideascale/ui';
import {setScrollbarWidthInCssVar} from '@ideascale/commons/dist/utils/CommonUtil';
import {AppContextProvider} from 'contexts/AppContext';
import {App} from 'App';
import './i18n';
import './assets/scss/styles.scss';

enableMapSet();
setScrollbarWidthInCssVar();

const dark = AppThemeUtil.isDarkThemePreferred(Theme.SYSTEM);
AppThemeUtil.toggleTheme(dark);

const {REACT_APP_ROUTE_BASE_PATH} = process.env;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0
        }
    }
});

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <>
            {/* @ts-ignore */}
            <QueryClientProvider client={queryClient} contextSharing={true}>
                {/* @ts-ignore */}
                <BrowserRouter basename={REACT_APP_ROUTE_BASE_PATH}>
                    <AppContextProvider>
                        <App/>
                    </AppContextProvider>
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false}/>
            </QueryClientProvider>
        </>
    );
}
